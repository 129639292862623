// imports
import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/MainStore";
// components
import { Main } from "./components/home/main/Main";

// style
import "./styles/global.css";
import "./styles/modalCharacter.css";
// pages
import { Gifts } from "./pages/gifts/gifts";
import { RealState } from "./pages/realState/realState";
import { Crypto } from "./pages/crypto/crypto";
import { Financing } from "./pages/financing/financing";
import { Us } from "./pages/about/us/us";
import { Williams } from "./pages/about/williams/williams";
import { Garcias } from "./pages/about/garcias/garcias";
import { NotFound } from "./pages/notfound/NotFound";
import { HowItWork } from "./pages/howItWork/HowItWork";
import { Faqs } from "./pages/faq/Faqs";
import { Contact } from "./pages/contact/Contact";
import { Privacy } from "./pages/privacy/Privacy";
import { Terms } from "./pages/terms/Terms";
import { LanguageButton } from "./components/global/buttons/langueage/LanguageButton";
import { NavbarSupport } from "./components/menu/components/navbar-support/NavbarSupport";
import { PromoPopup } from "./components/global/popups/PromoPopup";
import { ContentLoader } from "./components/global/loader/ContentLoader";
// function
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ContentLoader />
        <LanguageButton />
        <NavbarSupport />
        <PromoPopup />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/gifts" element={<Gifts />} />
          <Route path="/realState" element={<RealState />} />
          <Route path="/crypto" element={<Crypto />} />
          <Route path="/financing" element={<Financing />} />
          <Route path="/help/faqs" element={<Faqs />} />
          <Route path="/help/how-it-works" element={<HowItWork />} />
          <Route path="/about/us" element={<Us />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about/williams" element={<Williams />} />
          <Route path="/about/garcias" element={<Garcias />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Provider>
    </div>
  );
}
// export
export default App;
