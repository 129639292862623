
export const goToServicesInfo = {
    mainScene: {
        scene: "mainScene",
        isOpen: false,
        getInfoTo: "none",
        timing: 4,
    },

    car: {
        scene: "mrWilliams",
        isOpen: true,
        getInfoTo: "car",
        timing: 4,
    },
    rent: {
        scene: "sraWilliams",
        isOpen: true,
        getInfoTo: "rent",
        timing: 4,
    },

    motors: {
        scene: "srtaWilliams",
        isOpen: true,
        getInfoTo: "motors",
        timing: 4,
    },

    cybersecurity: {
        scene: "youngWilliams",
        isOpen: true,
        getInfoTo: "cybersecurity",
        timing: 4,
    },

    accidents: {
        scene: "childWilliams",
        isOpen: true,
        getInfoTo: "accidents",
        timing: 4,
    },

    life: {
        scene: "grandparentsWilliams",
        isOpen: true,
        getInfoTo: "life",
        timing: 4,
    },

    pets: {
        scene: "petsWilliams",
        isOpen: true,
        getInfoTo: "pets",
        timing: 4,
    },

    home: {
        scene: "mrGarcia",
        isOpen: true,
        getInfoTo: "home",
        timing: 4,
    },

    commercial: {
        scene: "sraGarcia",
        isOpen: true,
        getInfoTo: "business",
        timing: 4,
    },
};