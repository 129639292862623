import { configureStore } from '@reduxjs/toolkit'
import goCurrentLanguage from './slices/language'
import goSceneCamera from './slices/menuButtons'
import goContentInfo from './slices/contentInfo'
import goOpenCharacterSlider from './slices/sliderCharacter'
import goOpenCharacterPopup from './slices/characterCardPopup'
import goPopupPromoInfo from './slices/promopopup'
import goPromoSlice from './slices/promos'




export const store = configureStore({
    reducer: {
        cameraPosition: goSceneCamera,
        contentInfo: goContentInfo,
        characterSlider: goOpenCharacterSlider,
        characterCardPopup: goOpenCharacterPopup,
        language: goCurrentLanguage,
        promoPopup: goPopupPromoInfo,
        promo: goPromoSlice
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch