import { createSlice } from "@reduxjs/toolkit"

export interface MainContentUI {
  popupInfo: {
    active: boolean,
    title: string,
    subtitle: string,
    info: string,
    image: string,
    callAction: {
      url: string,
      action: string,
      btnName: string,
    }
    coockiesMessage: string,
    coockiesMessageOff: string
  }
}


const initialState: MainContentUI = {
  popupInfo: {
    active: true,
    title: 'Title Promo',
    subtitle: 'Sup Title Promo',
    info: 'Text info about promo info lorem Text info about promo info lorem Text info about promo info lorem Text info about promo info lorem Text info about promo info lorem Text info about promo info lorem',
    image: '/images/characters/characters.png',
    callAction: {
      url: '#',
      action: '#',
      btnName: 'I like a promo !!!'
    },
    coockiesMessage: 'Not show more',
    coockiesMessageOff: 'Your Preference is save'
  }
}

const promoPopupSlice = createSlice({
  name: "goPopupPromoInfo",
  initialState,
  reducers: {
    setPopupPromoInfo: (state: MainContentUI, action: any) => {
      state.popupInfo = action.payload
    }
  }
})

export const { setPopupPromoInfo } = promoPopupSlice.actions
export default promoPopupSlice.reducer