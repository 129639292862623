// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../store/MainStore";  
// components
import { ContactPage } from "../../components/contactPage/ContactPage";
// translation
import labelsES from "../../data/labelsES";
import labelsEN from "../../data/labelsEN";

// export
export const Contact = () => {
  // get language from explorer
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  // translation
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.contact;

        case "en":
          return labelsEN.contact;
      default:
        return labelsES.contact;
    }
  };
  // data
  const info = {
    title: translate().title,
    image: translate().image,
    info: translate().info,
  };
  // return
  return <ContactPage {...info} />;
};
