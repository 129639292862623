import { createSlice } from "@reduxjs/toolkit"

export interface MainContentUI {
    sliderInfo: {
        isOpen: boolean,
        lang: string,
        getInfoTo: string,
        timing: number
    }
}

const initialState: MainContentUI = {
    sliderInfo: {
        isOpen: false,
        lang: 'ES',
        getInfoTo: 'mainScene',
        timing: 4
    }
}

const contentSlice = createSlice({
    name: "goContentInfo",
    initialState,
    reducers: {
        setContentInfo: (state: MainContentUI, action: any) => {
            state.sliderInfo = action.payload

        }
    }
})

export const { setContentInfo } = contentSlice.actions
export default contentSlice.reducer