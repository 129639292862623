/* eslint-disable jsx-a11y/anchor-is-valid */
// imports
import { useEffect, useState } from "react";


//Redux
import { RootState } from "../../../store/MainStore";
import { useDispatch, useSelector } from "react-redux";

// components
import { CharacterModal } from "../character-modal/CharacterModal";

//redux
import { setCharacterPopup } from "../../../store/slices/characterCardPopup";
import { setCharacterSlider } from "../../../store/slices/sliderCharacter";

//customHook
import { cameraController } from "../../../three/components/Scene/ScriptThree";
// images
import IconClose from "./images/icon-close.png";
// style
import "./styles.css";
// translation
import labelsES from "../../../data/labelsES";
import labelsEN from "../../../data/labelsEN";
import charactersES from "../../../data/charactersES";
import charactersEN from "../../../data/charactersEN";
import { gsap } from "gsap";
// interface
interface ServiceInfoUI {
  serviceInfo: {
    id: string;
    title: string;
    subtitle: string;
    titleModal: string;
    content: string;
    contactUrl: string;
    downloadUrl: string;
    characters: {
      id: string;
      characterName: string;
      characterAnimation: string;
      characterBio: string;
      characterImg: string;
    }[];
  };
}
// export
export const ServiceInfo = ({ serviceInfo }: ServiceInfoUI) => {
  const mainScene = {
    scene: "mainScene",
    isOpen: false,
    lang: "ES",
    getInfoTo: "none",
    timing: 4,
  };

  const [localOpenInfo, setLocalOpenInfo] = useState(true);

  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );


  // get language from explorer
  const lang: string = "es";
  // translation
  const translate = () => {
    switch (lang) {
      case "es":
        return labelsES.serviceInfo;
      case "en":
        return labelsEN.serviceInfo;
      default:
        return labelsES.serviceInfo;
    }
  };

  const phoneNumber: string = "+13213878446";

  // popup
  const openCardCharacterPopup = useSelector(
    (state: RootState) => state.characterCardPopup.characterCardOpen
  );

  // data
  const [character, setCharacter] = useState({
    id: "",
    characterName: "",
    characterBio: "",
    characterImg: "",
  });

  const characterData = (characterId: string) => {
    let characters = [];
    switch (currentLang) {
      case "es":
        characters = charactersES;
        break;
      case "en":
        characters = charactersEN;
        break;
      default:
        characters = charactersES;
        break;
    }
    // get character index
    const characterIndex = characters.findIndex((x) => x.id === characterId);
    // get character
    const character = characters[characterIndex];
    // return character
    return character;
  };
  // functions
  const dispatch = useDispatch();
  const handlerGoToMain = () => {
    dispatch(setCharacterPopup(false));
    dispatch(setCharacterSlider(false));
    cameraController(mainScene.scene);
  };
  const handlerOpenInfo = () => {
    setLocalOpenInfo(true);
  };
  const handlerCloseInfo = () => {
    setLocalOpenInfo(false);
  };
  const handlerOpenCharacterModal = () => {
    dispatch(setCharacterPopup(true));
  };
  const handlerCloseCharacterModal = () => {
    dispatch(setCharacterPopup(false));
  };
  const characterHandler = (characterId: string) => {
    // get character data
    const character = characterData(characterId);
    // set character data
    setCharacter(character);
    // open modal with character info
    handlerOpenCharacterModal();
  };
  useEffect(() => {
    gsap.to(".infoGSAP", {
      scaleX: 0.5,
      scaleY: 0.5,
      duration: 1,
      repeat: -1,
    });
  });
  // return
  return (
    <div className="service-info">
      {localOpenInfo && (
        <section className="service-info-container">
          {/* header */}
          <div className="row">
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-8 col-sm-8 col-sx-8 col-sxs-8">
              <a className="modal-back-home" onClick={handlerGoToMain}>
                {translate().backHome}
              </a>
            </div>
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-4 col-sm-4 col-sx-4 col-sxs-4">
              <button type="button" className="btnClose">
                <img src="/images/close.png" alt="close" onClick={handlerCloseInfo}/>
              </button>
            </div>
          </div>
          {/* content */}
          <div className="row">
            <div className="col-col-12 col-xxxl-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
              <h2 className="title">{serviceInfo.titleModal}</h2>
              <h4 className="sub-title">{serviceInfo.subtitle}</h4>
              <p className="content">{serviceInfo.content}</p>
            </div>
          </div>
          {/* hire / info */}
          <div className="row">
            {/* hire */}
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-12 col-sx-12 col-sxs-12">
              <a className="btn btn-custom btn-main" target="_blank" rel="norel nofollow noopener noreferrer"
                href={'https://wa.me/' + phoneNumber +"?text=" +translate().whatsApp1 + "%20" + characterData(serviceInfo.characters[0].id).characterName + ",%20" + translate().whatsApp2 + "%20" + serviceInfo.titleModal}
              >
                {translate().hire}
              </a>
            </div>
            {/* info */}
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-12 col-sx-12 col-sxs-12">
              {/* <a className="btn btn-sec btn-custom btn-more-info" target="_blank" rel="norel nofollow noopener noreferrer" href={serviceInfo.downloadUrl}> */}
              <a className="btn btn-sec btn-custom btn-more-info">
                {translate().moreInfo}
              </a>
            </div>
          </div>
          {/* characters */}
          <div className="row character-btns">
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-12 col-sx-12 col-sxs-12">
              {serviceInfo.characters.map((characterInfo, index) => {
                return (
                  <a className="btn btn-third btn-custom" onClick={() => characterHandler(characterInfo.id)} key={index}>
                    {translate().whoIs} {characterInfo.characterName}?
                  </a>
                );
              })}
            </div>
          </div>
          {/* poup */}
          {openCardCharacterPopup && (
            <div className="character-modal-container GSAPCharacterAnim">
              <div className="modal-container">
                {/* header */}
                <div className="modal-image">
                  <h3 className="title-character-modal white-text">
                    {translate().about} <br/>
                    {translate().off} {character.characterName}
                  </h3>
                </div>
                <div className="close-icon-character">
                  <img src={IconClose} alt="close" onClick={handlerCloseCharacterModal}/>
                </div>
                {/* character info */}
                <CharacterModal characterInfo={character} />
              </div>
            </div>
          )}
        </section>
      )}
      {/* btn info */}
      {!localOpenInfo && (
        <button type="button" className="btnInfo infoGSAP">
          <img src="/images/info.png" alt="close" onClick={handlerOpenInfo} />
        </button>
      )}
    </div>
  );
};
