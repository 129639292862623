// import
import { useSelector } from "react-redux";
// components
import { Navigation } from "../../menu/Navigation";
// scenario
import { MyConextion3D } from "../../../three/MyConextion3D";

// Redux
import { RootState } from "../../../store/MainStore";
// data
import { ServiceInfo } from "../service-info/ServiceInfo";
// style
import "./styles.css";
// translation
import servicesES from "../../../data/servicesES";
import servicesEN from "../../../data/servicesEN";
import { Cookies } from "../../cookies/Cookies";
import { Version } from "../../global/version/Version";

// export
export const Main = () => {
  const serviceInfo = useSelector(
    (state: RootState) => state.contentInfo.sliderInfo
  );

  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );

  const characterSlider = useSelector(
    (state: RootState) => state.characterSlider.characterSliderOpen
  );

  const choseLang = () => {
    const lang = currentLang;
    switch (lang) {
      case "es":
        return servicesES;
      case "en":
        return servicesEN;
      default:
        return servicesES;
    }
  };

  const getServiceInfo: any = choseLang().find(
    (info) => info.id === serviceInfo.getInfoTo && info
  );

  return (
    <div className="main-home">
      <div className="version">
        <Version />
      </div>
      <Cookies />
      <Navigation />
      {characterSlider && <ServiceInfo serviceInfo={getServiceInfo} />}
      <MyConextion3D />
    </div>
  );
};
