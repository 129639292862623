// imports
import { RootState } from "../../store/MainStore";  
import { useSelector } from "react-redux";
// translation
import labelsES from "../../data/labelsES";
import labelsEN from "../../data/labelsEN";
// data
import { PromoCards } from "../../components/gifts/components/promos/Promos";
// components
import { BackHome } from "../../components/gifts/components/back-home/BackHome";
import { HeaderIcon } from "../../components/global/header-icon/HeaderIcon";
import { PromoForm } from "../../components/forms/promoForm/PromoForm";
// style
import "./styles.css";
// export
export const Gifts = () => {
  // get language from explorer
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  // translation
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.gifts;
      case "en":
        return labelsEN.gifts;
      default:
        return labelsES.gifts;
    }
  };
  // return
  return (
    <>
      <div className="gift-page">  
        <header className="page-header">
          <BackHome />
          <HeaderIcon />
        </header>
        <section className="page-section">
          <div className="page-container">
            <div className="row">
              <div className="col-col-7 col-xxxl-7 col-xxl-7 col-xl-7 col-lg-7 col-ipro-7 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
                <div className="col-container">
                  <div className="col-content">
                    <div className="page-title">{translate().giftsTitle}</div>
                    <div className="page-info">{translate().giftsSubtitle}</div>
                    <PromoCards />
                  </div>
                </div>
              </div>
              <div className="col-col-5 col-xxxl-5 col-xxl-5 col-xl-5 col-lg-5 col-ipro-5 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
                <div className="col-container">
                  <div className="col-content">
                    <PromoForm />
                    <h3>{translate().moreCoins}</h3>
                    <a href="https://kevins-dream.myconextion.com" target="_blank" rel="noopener noreferrer" className="btn btn-custom btn-sec">
                      {translate().moreCoinsBtn}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
