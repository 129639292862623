// Redux
import { RootState } from "../../../store/MainStore";  
import { useSelector } from "react-redux";


// images
import garciasImg from "./images/garcias.png";

// components
import { PageComponent } from "../../../components/genericPage/PageComponent";

// translation
import labelsES from "../../../data/labelsES";
import labelsEN from "../../../data/labelsEN";
import charactersES from "../../../data/charactersES";
import charactersEN from "../../../data/charactersEN";

// style
import "./styles.css";

// export
export const Garcias = () => {

  // set family members
  const familyMembers: string[] = ["sebastian", "andrea", "bella"];


  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );



  // data
  const getCharacterInfo: any = (characterId: string) => {
    // init characters
    let characters = [];
    // set characters
    switch (currentLang) {
      case "es":
        characters = charactersES;
        break;
      case "en":
        characters = charactersEN;
        break;
      default:
        characters = charactersES;
        break;
    }
    // get character index
    const characterIndex = characters.findIndex((x) => x.id === characterId);
    // get character
    const character = characters[characterIndex];
    // return character
    return character;
  };
  // translation
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.about.garcias;
      case "en":
        return labelsEN.about.garcias;
      default:
        return labelsES.about.garcias;
    }
  };
  // data
  const family = familyMembers.map((character) => {
    const infoCharacter = {
      name: getCharacterInfo(character).characterName,
      info: getCharacterInfo(character).characterBio,
      image: getCharacterInfo(character).characterImgBio,
    };
    return infoCharacter;
  });
  const contentInfo = {
    family: 'garcia',
    title: translate().meet,
    subtitle: translate().members,
    altText: translate().surname,
    image: garciasImg,
    members: family,
  };
  // return
  return <PageComponent {...contentInfo} />;
};
