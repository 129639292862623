import { useSound } from "./hooks/useSound";

// style
import "./styles/styles.css";
// export
export const Audio = () => {
  const { playMusic, handlerPlay, handlerStop } = useSound();


  const handlerAudioPlay = () => {
    handlerPlay();
  };

  const handlerAudioStop = () => {
    handlerStop();
  };



  return (
    <div>
      {playMusic ? (
        <button type="button" className="audioBtn" onClick={handlerAudioStop}>
          <img
            className="sound-off"
            src="/images/audio-off.png"
            alt="Sound Off"
          />
        </button>
      ) : (
        <button type="button" className="audioBtn" onClick={handlerAudioPlay}>
          <img
            className="soundOnGSAP sound-on"
            src="/images/audio-play.png"
            alt="Sound On"
          />
        </button>
      )}

    </div>
  );
};
