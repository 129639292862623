const statesData = [
  {
    "id": "6ab5c291-fcf0-44e6-9752-f1c37e31c45b",
    "name": "Alabama",
    "state_code": "AL"
  },
  {
    "id": "9971426c-28e2-4b63-8fa5-9a65ce51a8e8",
    "name": "Alaska",
    "state_code": "AK"
  },
  {
    "id": "87cd38f6-abd3-47f3-804a-03ded51b4163",
    "name": "Arizona",
    "state_code": "AZ"
  },
  {
    "id": "fddea57a-d835-4c72-969e-b689f24966df",
    "name": "Arkansas",
    "state_code": "AR"
  },
  {
    "id": "a60ed5ae-525f-474d-b057-31df456c8da5",
    "name": "California",
    "state_code": "CA"
  },
  {
    "id": "d31502ef-ffae-4d5d-b064-314afb5fa525",
    "name": "Colorado",
    "state_code": "CO"
  },
  {
    "id": "460ecbdd-ffe5-4fe1-a86f-df867bef773f",
    "name": "Connecticut",
    "state_code": "CT"
  },
  {
    "id": "0b82c1b6-cfd8-42de-abd7-d011fa5a923f",
    "name": "Delaware",
    "state_code": "DE"
  },
  {
    "id": "34bcba3d-f84f-4b2b-83cb-4a85e776d020",
    "name": "District of Columbia",
    "state_code": "DC"
  },
  {
    "id": "1aa43315-f8e7-4019-91bf-a7b29f68cf81",
    "name": "Florida",
    "state_code": "FL"
  },
  {
    "id": "ef86f533-c8b5-4d98-b6f3-6256b38997c1",
    "name": "Georgia",
    "state_code": "GA"
  },
  {
    "id": "6a07680a-be36-41c4-bba7-4d3dac6cd39c",
    "name": "Hawaii",
    "state_code": "HI"
  },
  {
    "id": "5e8531e3-59f2-482d-812e-e4e9b6035c1d",
    "name": "Idaho",
    "state_code": "ID"
  },
  {
    "id": "8aecb653-3805-477e-9474-f0aa8a1ce118",
    "name": "Illinois",
    "state_code": "IL"
  },
  {
    "id": "9f7f8dda-3885-44ef-b508-627c29370922",
    "name": "Indiana",
    "state_code": "IN"
  },
  {
    "id": "be6f774b-ce4d-43a9-96bc-16efad2e66e6",
    "name": "Iowa",
    "state_code": "IA"
  },
  {
    "id": "200a4f80-19e0-430e-bc84-d829b2dca9b7",
    "name": "Kansas",
    "state_code": "KS"
  },
  {
    "id": "ca7e3b21-3e88-46ed-89cf-cce7f6145b94",
    "name": "Kentucky",
    "state_code": "KY"
  },
  {
    "id": "214dfbe3-b70b-4927-a66e-540e8bd89862",
    "name": "Louisiana",
    "state_code": "LA"
  },
  {
    "id": "44b69eca-59d8-4ee6-a23d-308bb86f52ea",
    "name": "Maine",
    "state_code": "ME"
  },
  {
    "id": "05d944cf-e4ac-4a04-8a63-4711768124db",
    "name": "Maryland",
    "state_code": "MD"
  },
  {
    "id": "0bafd08d-e93f-4025-9489-9a1c809f1dc5",
    "name": "Massachusetts",
    "state_code": "MA"
  },
  {
    "id": "f7ea4014-772f-478c-a7b1-7992ae62ea49",
    "name": "Michigan",
    "state_code": "MI"
  },
  {
    "id": "eeb090af-8128-4c76-b4b2-dd913c4a6f64",
    "name": "Minnesota",
    "state_code": "MN"
  },
  {
    "id": "1aa5d0f3-cdba-4457-a166-827db331c0a3",
    "name": "Mississippi",
    "state_code": "MS"
  },
  {
    "id": "a9925da2-0889-4f44-9886-0e6d2078f162",
    "name": "Missouri",
    "state_code": "MO"
  },
  {
    "id": "e6b19ee1-4d47-4d38-a14e-a108dd1baccf",
    "name": "Montana",
    "state_code": "MT"
  },
  {
    "id": "1851ef0b-95f6-49c4-9edb-5ca7a6fe306a",
    "name": "Nebraska",
    "state_code": "NE"
  },
  {
    "id": "4f03f1e8-2a6e-40bc-adc3-35b2d42544e4",
    "name": "Nevada",
    "state_code": "NV"
  },
  {
    "id": "97e7328d-e254-45cf-b82a-4e034e45cd3d",
    "name": "New Hampshire",
    "state_code": "NH"
  },
  {
    "id": "e1465d7d-91a5-4377-a68d-873838a93ece",
    "name": "New Jersey",
    "state_code": "NJ"
  },
  {
    "id": "23842380-d89a-4b50-ada3-3635795358bc",
    "name": "New Mexico",
    "state_code": "NM"
  },
  {
    "id": "ae5af537-132f-4ae4-8d23-98560984044d",
    "name": "New York",
    "state_code": "NY"
  },
  {
    "id": "7db1ada1-0031-4c39-b70c-a2a30866e291",
    "name": "North Carolina",
    "state_code": "NC"
  },
  {
    "id": "d18fe0ca-6024-44ad-bddf-090f5c92f22b",
    "name": "North Dakota",
    "state_code": "ND"
  },
  {
    "id": "7f4a6b14-9afc-4cbe-87d9-47aac2d94f83",
    "name": "Ohio",
    "state_code": "OH"
  },
  {
    "id": "2579c875-9c4e-4c9a-8043-72305f69b6f4",
    "name": "Oklahoma",
    "state_code": "OK"
  },
  {
    "id": "bf51bea8-de2a-42a3-bb5b-8e0ada9d2249",
    "name": "Oregon",
    "state_code": "OR"
  },
  {
    "id": "327ee44e-3c93-4aec-818c-b7319e59bff9",
    "name": "Pennsylvania",
    "state_code": "PA"
  },
  {
    "id": "af7b2bc6-d29d-4c0c-950d-59fd70b506b2",
    "name": "Rhode Island",
    "state_code": "RI"
  },
  {
    "id": "edfc1f32-e1a3-4abc-a115-f85ca57b26bb",
    "name": "South Carolina",
    "state_code": "SC"
  },
  {
    "id": "0c45df7c-904f-432e-ae62-820b4090306e",
    "name": "South Dakota",
    "state_code": "SD"
  },
  {
    "id": "b4cdae78-6cdf-4f4c-8afb-dbe0edfdb87e",
    "name": "Tennessee",
    "state_code": "TN"
  },
  {
    "id": "12fad261-21be-421a-881f-e226711c15da",
    "name": "Texas",
    "state_code": "TX"
  },
  {
    "id": "ab61b841-fc9c-40b9-bc30-3f7170081d0b",
    "name": "Utah",
    "state_code": "UT"
  },
  {
    "id": "0006c665-2975-4dfb-a40a-c2bcb833cdb3",
    "name": "Vermont",
    "state_code": "VT"
  },
  {
    "id": "dfe48b44-22e6-43c8-a00f-d00d532201cf",
    "name": "Virginia",
    "state_code": "VA"
  },
  {
    "id": "12e2244c-4b83-4efd-b79c-a496fbeeaf3a",
    "name": "Washington",
    "state_code": "WA"
  },
  {
    "id": "d2a9645e-14c5-43d8-9c96-ca5f2d4dd74b",
    "name": "West Virginia",
    "state_code": "WV"
  },
  {
    "id": "967bb263-1905-4dc8-a445-be45c0ca90be",
    "name": "Wisconsin",
    "state_code": "WI"
  },
  {
    "id": "21c2bbd5-ab12-4297-84d4-25704190343f",
    "name": "Wyoming",
    "state_code": "WY"
  }
];
export default statesData;