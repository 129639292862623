// redux
import { RootState } from "../../../store/MainStore";
import { useSelector } from "react-redux";

import LoaderLogo from "../../../components/global/logos/images/min-logo.png";
import CharactersLogoEs from "../../../components/global/characters/characters-es.png";
import CharactersLogoEn from "../../../components/global/characters/characters-en.png";
import { loaderMessage } from "../../../data/loaderMessage";
import "./styles/styles.css";
import { useEffect, useState } from "react";
export const ContentLoader = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const lang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  const CharactersLogo = () => {
    if (lang === "es") {
      return CharactersLogoEs;
    }
    if (lang === "en") {
      return CharactersLogoEn;
    }
  };
  const translateMsg = () => {
    if (lang === "es") {
      return loaderMessage.es;
    }
    if (lang === "en") {
      return loaderMessage.en;
    }
  };
  useEffect(() => {
    const screenX = window.innerWidth;
    const timingLoader = screenX < 991 ? 12000 : 4000;
    setTimeout(function () {
      setIsLoaded(true);
    }, timingLoader);
  }, []);
  return (
    <>
      {!isLoaded && (
        <div className="three-loader">
          <div className="three-loader-container">
            <div className="three-loader-content">
              <div className="three-loader-logo-container">
              <p className="three-loader-market-place-title">Market Place</p>
                <img
                  src={LoaderLogo}
                  className="three-loader-logo"
                  alt="Logo"
                />
              </div>
              <div className="three-loader-characters-container">
                <img
                  src={CharactersLogo()}
                  className="three-loader-image"
                  alt="Conextion Family"
                />
              </div>
              <p className="three-loader-text">{translateMsg()}</p>
              <div className="loadingGSAP">
                <div className="lds-ripple-container">
                  <div className="lds-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
