// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../store/MainStore"; 
// images
import williamsImg from './images/williams.png';
import { PageComponent } from "../../../components/genericPage/PageComponent";

// translation
import labelsES from "../../../data/labelsEN";
import labelsEN from "../../../data/labelsEN";
import charactersES from "../../../data/charactersES";
import charactersEN from "../../../data/charactersEN";
// style
import './styles.css';


// export
export const Williams = () => {
  
  // get language from explorer
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  // set family members
  const familyMembers: any[] = ['frank', 'abigail', 'kevin', 'sarah', 'gabriel', 'sofia', 'chris', 'max'];
  // data
  const getCharacterInfo: any = (characterId: string) => {
    // init characters
    let characters = [];
    // set characters
    switch (currentLang) {
      case "es":
        characters = charactersES;
        break;
      case "en":
        characters = charactersEN;
        break;
      default:
        characters = charactersES;
        break;
    }
    // get character index
    const characterIndex = characters.findIndex(x => x.id === characterId);
    // get character
    const character = characters[characterIndex];
    // return character
    return character;
  }
  // translation
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.about.williams;
      case "en":
        return labelsEN.about.williams;
      default:
        return labelsES.about.williams;
    }
  };

  const family = familyMembers.map((character) => {
    const infoCharacter = {
      name: getCharacterInfo(character).characterName,
      info: getCharacterInfo(character).characterBio,
      image: getCharacterInfo(character).characterImgBio,
    };

    return infoCharacter;
  });

  const contentInfo = {
    family: 'williams',
    title: translate().meet,
    subtitle: translate().members,
    altText: translate().surname,
    image: williamsImg,
    members: family,
  };

  // return
  return <PageComponent {...contentInfo} />;
}

