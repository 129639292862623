// components
import { MenuNavbar } from "./components/navbar/MenuNavbar";
import { GiftNavbar } from "./components/gift-navbar/GiftNavbar";
import { NavbarService } from "./components/navbar-service/NavbarService";
import { useEffect, useState } from "react";
import { NavbarServicesMobile } from "./components/navbar-service/navbarServicesMobile/NavbarServicesMobile";
import { Audio } from "../global/buttons/audio/Audio";

// export
export const Navigation = () => {
  const [currentScreenX, setCurrentScreenX] = useState(window.innerWidth);

  const resize = () => {
    setCurrentScreenX(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [currentScreenX]);

  return (
    <>
      
      <MenuNavbar />
      <GiftNavbar />
      {currentScreenX >= 800 && <NavbarService />}
      {currentScreenX <= 799 && <NavbarServicesMobile />}
      <Audio />
    </>
  );
};
