const servicesEN = [
  {
    id: "mainScene",
    title: "My Conextion",
    subtitle: "Secure everything you love the most",
    titleModal: "My Conextion",
    content: "Find the insurance you need in just one place",
    downloadUrl: "",
    characters: [],
  },
  {
    id: "life",
    title: "Life",
    subtitle: "Secure your loved ones!",
    titleModal: "Life Insurance",
    content: "Life insurance is a contract between an insurer and a policy owner. A life insurance policy guarantees the insurer pays a sum of money to named beneficiaries when the insured dies in exchange for the premiums paid by the policyholder during their lifetime.",
    downloadUrl: "",
    characters: [
      {
        id: "frank",
        characterName: "Frank",
      },
      {
        id: "abigail",
        characterName: "Abigail",
      },
    ],
  },
  {
    id: "car",
    title: "Car",
    subtitle: "If you use any motorized vehicle in the course of your business activities you need commercial auto insurance",
    titleModal: "Car Insurance",
    content: "Business Autos provides protection for automobiles used in the course and scope of business.",
    downloadUrl: "",
    characters: [
      {
        id: "kevin",
        characterName: "Kevin",
      },
    ],
  },
  {
    id: "home",
    title: "House",
    subtitle: "Secure your house!",
    titleModal: "House Insurance",
    content: "This covers the actual structure of your home and the components that make it up, such as the roof, siding, windows, countertops, and flooring.",
    downloadUrl: "",
    characters: [
      {
        id: "sebastian",
        characterName: "Sebastian",
      },
    ],
  },
  {
    id: "business",
    title: "Commercial",
    subtitle: "Secure your business",
    titleModal: "Commercial Insurance",
    content: "Commercial coverage additionally known as business insurance coverage, covers severa sudden conditions that may be high-priced with out an coverage policy—the coverage insurance generally consists of worker or purchaser injury, assets damage, theft, and any capacity proceedings towards the company.",
    downloadUrl: "",
    characters: [
      {
        id: "sarah",
        characterName: "Sarah",
      },
    ],
  },
  {
    id: "rent",
    title: "Rent",
    subtitle: "Secure your belongings!",
    titleModal: "Rent Insurance",
    content: "Renters coverage can assist cowl the lack of or harm for your possessions, extra residing costs when you have to go away your house because of a blanketed event, and can defend you from private legal responsibility claims, too.  Can assist defend you from the doubtlessly devastating fees of dropping the stuff you own, from the house or rental which you don’t.",
    downloadUrl: "",
    characters: [
      {
        id: "andrea",
        characterName: "Andrea",
      },
    ],
  },
  {
    id: "accidents",
    title: "Personal Accidents",
    subtitle: "Peace!",
    titleModal: "Personal Accident Insurance",
    content: "Accident coverage for companies, groups and organizations.",
    downloadUrl: "",
    characters: [
      {
        id: "chris",
        characterName: "Chris",
      },
    ],
  },
  {
    id: "cybersecurity",
    title: "Cybersecurity",
    subtitle: "Because you don't know when...",
    titleModal: "Cybersecurity",
    content: "Cyber legal responsibility coverage is an coverage coverage that gives corporations with a aggregate of insurance alternatives to assist shield the organization from statistics breaches and different cyber safety issues.",
    downloadUrl: "",
    characters: [
      {
        id: "gabriel",
        characterName: "Gabriel",
      },
    ],
  },
  {
    id: "motors",
    title: "Motors",
    subtitle: "Secure your wheels!",
    titleModal: "Motors",
    content: "Motorcycle coverage is an car coverage that mainly protects motorcycles. In many ways, bike coverage may be very much like normal car coverage. The foremost distinction is the kinds of insurance you may get.",
    downloadUrl: "",
    characters: [
      {
        id: "sofia",
        characterName: "Sofia",
      },
    ],
  },
  {
    id: "pets",
    title: "Pets",
    subtitle: "They also need Protection!",
    titleModal: "Pet Insurance",
    content: "Pets are part of your own circle of relatives, so of direction you need to do what you could to assist preserve them nicely and healthy.  Protecting your pet is one way to do that.",
    downloadUrl: "",
    characters: [
      {
        id: "max",
        characterName: "Max",
      },
      {
        id: "bella",
        characterName: "Bella",
      },
    ],
  },
];
export default servicesEN;
