const labelsEN = {
  navbarMenu: {
    start: "Start",
    services: "Services",
    life: "Life",
    car: "Car",
    home: "Home",
    business: "Business",
    rent: "Rent",
    accidents: "Accidentes Personales",
    cybersecurity: "Cyber security",
    motors: "Motoras",
    pets: "Pets",
    gifts: "Gifts",
    realState: "Real Estate",
    financing: "Financing",
    crypto: "Criptocurrencies",
    about: "About Us",
    aboutUs: "My Conextion",
    aboutWilliams: "Williams",
    aboutGarcia: "Garcia",
    help: "Help",
    howItWorks: "How Work It",
    faqs: "FAQ'S",
    contact: "Contact",
    game: "Win Playing",
  },
  navbarService: {
    title: "Find the insurance you need",
    titleMobile1: "Find",
    titleMobile2: "the insurance you need",
    life: "Life",
    car: "Cars",
    home: "Home",
    business: "Business",
    rent: "Rent",
    accidents: "Accidents",
    cybersecurity: "Ciber Security",
    motors: "Motors",
    pets: "Pets",
    insurances: "Insurances",
  },
  navbarSupport: {
    schedule: "Schedule an appoinment",
    talk: "Talk to us",
    email: "Send us an Email",
    call: "Call us",
    videocall: "Make a video call",
  },
  serviceInfo: {
    hire: "Hire Insurance",
    moreInfo: "More Info",
    whoIs: "Who is ",
    about: "About ",
    off: "of ",
    backHome: "Back to Home",
    whatsApp1: "Hello",
    whatsApp2: "I want more information about ",
  },
  form: {
    formPrivacy1: "By continue you are accepting both",
    formPrivacy2: "and",
    terms: "Terms and Conditions",
    privacy: "Privacy Policy",
  },
  crypto: {
    cryptoTitle: "Cryptocurrencies",
    cryptoSubtitle: "Discover the movements and values in the cryptocurrency market",
    loader:{
      title:'Loading Information',
      info: "We are obtaining the information of the main cryptocurrencies..."
    },
  },
  realState: {
    realStateTitle: "Real Estate",
    realStateSchedule: "Schedule an appointment with our advisors now",
    realStateSelect: "Select an Option",
    realStateSelectMsg: "Select first what action you want to perform",
    realStateSellingTitle: "Selling",
    realStateSellingIntro: "I want to sell a property",
    realStateBuyingTitle: "Buying",
    realStateBuyingIntro: "I want to buy a property",
    realStateSellingSubtitle: "Fill in a few questions and we will help you sell your property at the best price",
    realStateBuyingSubtitle: "Fill in a few questions and we'll connect you to the property you're looking for",
    realStateImageRealtor: "/images/real-state/realtor.webp",
    realStateImageBg: "/images/real-state/realtor-bg.webp",
    realStateRealtorMsg1: "Buying or selling a property can be a stressful process if you don't have the right real estate agent.",
    realStateRealtorMsg2: "With experience and support, you can rely on us to get you the best possible result.",
    loader:{
      title:'Loading Information',
      info: "We are getting the information of the properties..."
    },
    form: {
      email: {
        label: "Email",
        placeholder: "mail@mydomain.com",
        error: "You must enter an email",
        help: "Place an email to redeem your benefit",
      },
      cellphone: {
        label: "Mobile",
        placeholder: "1234567890",
        error: "You must enter a valid mobile number",
        help: "Enter a cell phone number so we can contact you if necessary",
      },
      firstName: {
        label: "Name",
        placeholder: "John",
        error: "You must enter your name",
        help: "Add your name so we can contact you",
      },
      lastName: {
        label: "Last Name",
        placeholder: "Doe",
        error: "You must enter your last name",
        help: "Add your last name so we can contact you",
      },
      sellingTimePeriod: {
        label: "How soon do you plan to sell?",
        labelASAP: "As soon as possible",
        label12Months: "Between 1 and 2 months",
        label23Months: "Between 2 and 3 months",
        label3PlusMonths: "More than 3 months",
        placeholder: "Select an Option",
        error: "You must select an option",
        help: "Knowing this information we can help you with the perfect sales proposal",
      },
      sellingReason: {
        label: "What is the reason you sell?",
        labelUpsizing: "Enlarge Property",
        labelDownsizing: "Downsize Property",
        labelLocation: "Location",
        labelOther: "Other",
        placeholder: "Select a Reason",
        error: "You must select the reason for the sale",
        help: "This will allow us to create an appropriate sales strategy for your property",
      },
      propertyLocation: "Property Location",
      propertyLocationState: {
        label: "Status",
        placeholder: "Select a Estate",
        error: "You must select a state",
        help: "Search the list for the state where your property is located",
      },
      propertyLocationCity: {
        label: "City",
        placeholder: "Select a City",
        selectStateFirst: "Select a State first",
        error: "You must select a city",
        help: "Search in the list for the city where your property is located. If nothing appears in the list, you must select the state first.",
      },
      propertyLocationAddress: {
        label: "Address",
        placeholder: "1234 Street",
        error: "You must enter the address of the property",
        help: "Enter the address where your property is located",
      },
      propertyLocationZIP: {
        label: "ZIP code",
        placeholder: "000000",
        error: "You must enter the ZIP code of your property",
        help: "Indicate the ZIP code of where your property is located",
      },
      homeOwnerPackage: {
        label: "Do you want to receive a package to list properties?",
        labelYes: "Yes",
        labelNo: "No",
        homeOwnerPackage: "This user wants to receive the owners package",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "The package to list properties will allow you to know the tips to sell your property at the best price and as soon as possible",
      },
      typeOfProperty: {
        label: "Property Type",
        labelPrimary: "Primary",
        labelVacational: "Vacational",
        labelInvestment: "Investment",
        placeholder: "Select a type",
        error: "You must select a type",
        help: "This will allow us to know the type of property you are looking for",
      },
      typeOfBuyer: {
        label: "Buyer Type",
        labelLocal: "Local",
        labelInternational: "International",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "This way we can prepare the documentation for the purchase of the property",
      },
      firstTime: {
        label: "Is this your first time buying in Florida?",
        labelAffirmation: "This is my first time buying in Florida",
        labelYes: "Yes",
        labelNo: "No",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "This will help us give you more concise information for first time buyers",
      },
      valueExpectation: {
        label: "How much is your budget for the purchase?",
        labelBudget: "Budget",
        label200Less: "$200K or less",
        label200300: "Between $200K and $300K",
        label300350: "Between $300K and $350K",
        label350400: "Between $350K and $400K",
        label400450: "Between $400K and $450K",
        label450More: "Over $450K",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "So we can find the ideal property based on your budget",
      },
      homeStyle: {
        label: "What type of property are you looking for?",
        labelType: "Property Type",
        labelSingleHome: "Single Home",
        labelTownhouse: "Townhouse",
        labelCondo: "Condo",
        labelApartment: "Apartment",
        labelMultifamily: "Multifamily",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "With this we will find the perfect property for you",
      },
      income: {
        label: "How do you receive your income?",
        labelReception: "Origin of Income",
        labelW2: "W2",
        label1099: "N1099o",
        labelOther: "Other",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "This information will allow us to prepare the appropriate documentation",
      },
      creditRange: {
        label: "Estimated credit range",
        labelRange: "Credit Range",
        label559Less: "$559K or less",
        label560599: "Between $560K and $559K",
        label600639: "Between $600K and $639K",
        label640679: "Between $640K and $679K",
        label680More: "$680K or more",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "With this information we can connect you with the financial institution that best suits you",
      },
      downPayment: {
        label: "How much do you have for the down payment?",
        labelInitialPayment: "Initial Payment",
        label3500Less: "Less than $3.5K",
        label35004000: "Between $3.5K and $4K",
        label400010000: "Between $4K and $10K",
        label1000015000: "Between $10K and $15K",
        label1500020000: "Between $15K and $20K",
        label2000025000: "Between $20K and $25K",
        label25000More: "$25K or more",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "This will allow us to negotiate a good deal with the property owners as soon as possible",
      },
      taxesPreparation: {
        label: "Do you have two years of taxes prepared?",
        labelPrepared: "Two years of taxes prepared",
        labelMsg: "Taxes prepared for two years",
        labelYes: "Yes",
        labelNo: "No",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "This information is essential for the process",
      },
      foundHome: {
        label: "Have you already found a property you want to purchase?",
        labelFound: "Already have a property to purchase",
        labelFoundMsg: "Property to buy",
        labelYes: "Yes",
        labelNo: "No",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "This way we can help you with the negotiation",
      },
      realStateAdvisory: {
        label: "Are you currently receiving advice from a real estate agent?",
        labelAgent: "Have a real estate agent",
        labelAgentMsg: "Real Estate Agent",
        labelYes: "Yes",
        labelNo: "No",
        placeholder: "Select an option",
        error: "You must select an option",
        help: "Just curious",
      },
      send: "Send",
      infoReceivedTitle: "Great",
      infoReceived: "One of the real estate agents will be with you very soon to start fine-tuning details.",
    },
    msgs: {
      realStateTitle: "MyConextion Real Estate",
      msgRealtorTitleSale: "Property for Sale",
      msgRealtorTitlePurchase: "Purchase Property",
      msgRealtor1Sale: "A visitor to My Conextion is interested in selling a property.",
      msgRealtor1Purchase: "A visitor to My Conextion is interested in buying a property.",
      msgRealtor2: "This is his/her data:",
      msgRealtorProperty: "Property Information",
      msgClientTitle: "MyConextion Real Estate", 
      msgClient: "Thank you for leaving your information, one of our advisors will be in contact with you very soon.",
    }
  },
  financing: {
    financingTitle: "Financing",
    financingSubtitle: "Connect with the lending and investment opportunities we have for you.",
    loader:{
      title:'Loading Information',
      info: "We are obtaining information from financial institutions..."
    },
  },
  gifts: {
    giftsTitle: "Gifts",
    giftsSubtitle: "Get to know the list of gifts we have for you",
    loader:{
      title:'Sending Message',
      info: "We are sending your message..."
    },
    msgTitleClient: "Claim your gift",
    msgClient: "Here are the instructions to claim your gift from MyConextion",
    msgCodeClient: "You can redeem your gift with the code",
    msgPromoClient: "This is your gift",
    msgContactClient: "These are the contact details of our ally:",
    msgTitleAlly: "My Connection Coupon Request",
    msgAlly: "A My Connection customer has requested one of your coupons",
    msgCodeAlly: "Gift code is",
    msgPromoAlly: "This is your gift",
    msgContactAlly: "These are the customer's contact details:",
    msgTitleOwner: "Coupon Request",
    msgOwner: "A customer has requested a coupon from the ally ",
    msgCodeOwner: "The client received the code",
    msgPromoOwner: "This is your gift",
    msgContactOwnerClient: "These are the contact details of the client:",
    msgContactOwnerAlly: "These are the contact details of the ally:",
    contact: {
      company: "Ally",
      website: "Website",
      email: "Email",
      phone: "Phone",
      contact: "Contact",
      name: "Name",
      lastname: "Lastname",
    },
    form: {
      email: {
        label: "Email address",
        placeholder: "email@mydomain.com",
        error: "You must enter an email",
        help: "Add an email address to be able to redeem your gift",
      },
      cellphone: {
        label: "Cellphone",
        placeholder: "1234567890",
        error: "You must enter a valid Cellphone number",
        help: "Add a cellphone number to be able to contact you if necessary",
      },
      names: {
        label: "Name",
        placeholder: "Jhon",
        error: "You must enter your name",
        help: "Add your name so you can claim your gift",
      },
      lastNames: {
        label: "Lastname",
        placeholder: "Doe",
        error: "You must enter your last name",
        help: "Add your last name so you can claim your gift",
      },
      terms: "You must accept in order to continue",
      note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      getGift: "Reclamar Obsequio",
      youMustSelectPromo: "Looks like you forgot to select your gift",
      checkYourEmailTitle: "Great",
      checkYourEmail: "Please check your email for your gift details.",
    },
    moreCoins: "Do you want to get more gifts?",
    moreCoinsBtn: "Go to Mini Game"
  },
  about: {
    us: {
      title: "About us",
      content: "We are the first 3D Interactive insurance platform with 360 immersion for the modern customer; creating a user experience, while securing your belongings.",
      images: "/images/characters/characters.png"
    },
    williams: {
      surname: "Williams",
      meet: "Meeting<br/>the Williams",
      members: "The Williams family consist of Kevin (the father), Sarah (the mother), Gabriel, Sofia and Chris (the children from eldest to youngest), and Max (the dog).",
       story1: "Frank and Abigail met during the Vietnam War, where Abigail was a nurse tending to Frank's hip injuries from shrapnel from an explosion in the cockpit of his helicopter.",
       story2: "There they fell in love, and then they moved back to Baltimore where they got married in '75, they've been together ever since and love spending time with their family and recounting their adventures together.",
       about: "The Williams family is very close, and hardworking. They like to be outside and enjoy game nights and movie nights on the weekends.",
    },
    garcias: {
      surname: "Garcia",
      meet: "Meeting<br/>the Garcias",
      members: "The Garcia family is made up of Sebastian (the husband), Andrea (the wife), and Bella (the cat)",
      story: "Sebastian and Andrea met in a Spinning class at the gym that Peter attends. Andrea is the class teacher, and as soon as they met eyes it was love at first sight.",
      about: "The Garcias are a very personable newlywed couple who like to make good friends with everyone and are always seen smiling.",
    },
  },
  howItWork: {
    title: "How it works",
    info: [
      "We are collecting data, doing a survey and we want to reach at least the 5,000 or more who want to GET on our list so that when we launch the policy they will be the first people to save on their auto insurance.",
      "When we have a sufficient number of users, we will approach the Insurance Companies to negotiate the terms and conditions of the policy. This process may take several months, but it will be on its way and we will achieve the established objectives...",
      "When we obtain the authorization and commercialization of the policies, we will update our platform with the requested requirements, and we will immediately notify all our registered users, so that they are the first to benefit from the solutions obtained in each insurance and monthly payment"
    ],
    slogan: "Would you be interested in JOINING and saving on your monthly insurance payment?",
    callToAction: "click here",
    btnTitle: "Click HERE",
    image: "/images/characters/williams-kevin-hi.png"
  },
  faqs: {
    title: "Frequently asked questions",
    image: "/images/faqs/faqs.png",
    info: [
      {
        title: "Life Insurance",
        subtitle: "What is Life Insurance for?",
        info: "A Plan for the Unexpected",
      },
      {
        title: "Accident Policies",
        subtitle: "Is an Accident policy necessary?",
        info: "Accident insurance helps you get over a sudden injury by offering a monetary payment with flexible uses. It is an insurance policy for unexpected bodily injury.",
      },
      {
        title: "Commercial Insurance",
        subtitle: "What is Commercial Insurance?",
        info: "Business insurance provides protection for businesses against financial loss caused by disasters, crime, accidents, and liability. Coverage may include loss of revenue due to a customer's business interruption, employee injuries, or the acts of wrongful acts committed by the insured or by third parties on the premises. This can be written in an umbrella policy or as a single contract with additional coverage added as needed,"
      },
      {
        title: "Cybersecurity",
        subtitle: "How does this insurance help me?",
        info: "Cybercrime is growing faster than any other type of cyber attack. Cyber ​​insurance can help protect you against the devastating consequences of cyber attacks, as well as help you fix them if they do occur. It's an insurance product that helps your company protect itself from the potentially disastrous effects of cybercrime. They are designed to help a company mitigate specific risks, so it can focus on driving its business.",
      },
      {
        title: "Car Insurance",
        subtitle: "My car insurance",
        info: "Car insurance is a type of insurance that pays for losses caused by your car, including: an accident, car fire, and property damage. Car insurance policies may also cover theft or vandalism. Most states require auto insurance coverage to fully protect you from the financial consequences of a car accident.",
      },
      {
        title: "Motors",
        subtitle: "Protect your motorcycle, you and your loved ones with motorcycle insurance",
        info: "A motorcyclist is at a much higher risk of injury due to the speed, height and strong nature of the two-wheeler. Motorcycle riders and passengers need adequate protection against damage in the event of an accident. Motorcycle insurance provides financial protection in the event of an accident, loss, theft, or damage to your motorcycle. Motorcycle insurance includes liability coverage in the event you are responsible for someone else's injury or property damage. This is necessary to help to protect passengers from financial loss due to accidents or legal expenses if someone is suing them.",
      },
      {
        title: "Lease",
        subtitle: "Greater security for tenants",
        info: "Renters insurance is an important part of living in a rented space. It can help protect you and your belongings in case of fire, theft and more. Renters insurance will cover damage to your belongings and pay the costs repair if someone is injured on your property.",
      },
      {
        title: "Pets",
        subtitle: "My Pet!",
        info: "Pet insurance can help reduce the financial burden associated with expensive veterinary bills caused by illnesses or injuries sustained by your pet. This coverage is similar to human health insurance policies and works in a similar way, providing funds to cover medical bills up to the policy limits. It also gives you peace of mind knowing that if your pet needs an emergency medical procedure, you'll be prepared to cover these costs.",
      }
    ]
  },
  contact: {
    title: "Contact us",
    info: "Write us and we will respond to your request as soon as posible",
    image: "images/contact/contact.png",
    subject: "Thank you for contact us",
    contactSoon: "Your message is very important to us. We will be in touch with you soon.",
    subjectOwner: "Someone has written",
    contactSoonOwner: "A user has sent us a message, please contact us as soon as possible.",
    contactSoonDataOwner: "This is the data:",
    contactSoonMsgOwner: "This is the message:",
    contactThankYouTitle: "Message Received",
    contactThankYouMsg: "Thank you very much for your message, we will be in touch very soon",
    loader:{
      title:'Sending Message',
      info: "We are sending your message..."
    },
    form: {
      email: {
        label: "Email",
        placeholder: "email@mydomain.com",
        error: "You must enter an email address",
        help: "Add an email address to send your message",
      },
      cellphone: {
        label: "Cellphone",
        placeholder: "1234567890",
        error: "You must enter a valid cellphone number",
        help: "Add a cellphone number to be able to contact you if necessary",
      },
      names: {
        label: "Name(s)",
        placeholder: "Juan Camilo",
        error: "You must enter your name",
        help: "Add your name so we can help you with your message",
      },
      lastNames: {
        label: "Last name(s)",
        placeholder: "Perez Lopez",
        error: "You must enter your last name",
        help: "Add your last name so we can help you with your message",
      },
      msg: {
        label: "Your Message",
        placeholder: "Message",
        error: "You must add a message",
        help: "Write us your query and we will be in contact with you soon",
      },
      note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      send: "Send",
    }
  },
  gpdr: {
    title: "We use cookies",
    msg: "We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from.",
    accept: "Accept",
    decline: "Decline",
  }
}
export default labelsEN;