import { createSlice } from "@reduxjs/toolkit"

export interface MenuLanguageUI {
    currentLanguage: string
}

const initialState = {
    currentLanguage: 'es'
}

const languageSlice = createSlice({
    name: "goCurrentLanguage",
    initialState,
    reducers: {
        setCurrentLanguage: (state: MenuLanguageUI, action: any) => {
            state.currentLanguage = action.payload
        }
    }
})

export const { setCurrentLanguage } = languageSlice.actions
export default languageSlice.reducer


