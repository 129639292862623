
// Redux
import { RootState } from "../../../store/MainStore";  
// translation
import labelsES from "../../../data/labelsES";
import labelsEN from "../../../data/labelsEN";
// style
import "./styles.css";
// components
import { GenericPageContentBasic } from "../../../components/genericPageContentBasic/GenericPageContentBasic";
import { SocialIcons } from "../../../components/global/socialIcons/general/SocialIcons";
import { useSelector } from "react-redux";
// export
export const Us = () => {
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  // translation
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.about.us;
      case "en":
        return labelsEN.about.us;
      default:
        return labelsES.about.us;
    }
  };
  const info = {
    title: translate().title,
    info: translate().content,
    image: translate().images,
  };
  // return
  return (
    <>
      {/* socials */}
      <div className="socials-mobile-services-container about-socials">
        <div className="img-mobile-socials">
          <SocialIcons />
          <p>@myconextion</p>
        </div>
      </div>
      {/* about us */}
      <div className="about-us">
        < GenericPageContentBasic {...info} />
      </div>
    </>
  )
};
