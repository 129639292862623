// imports
import { RootState } from "../../store/MainStore";  
import { useState } from "react";
import { useSelector } from "react-redux";
// translation
import labelsES from "../../data/labelsES";
import labelsEN from "../../data/labelsEN";
// data
// components
import { BackHome } from "../../components/global/buttons/backhome/BackHome";
import { HeaderIcon } from "../../components/global/header-icon/HeaderIcon";
import { RealStateSellingForm } from "../../components/forms/realStateSellingForm/realStateSellingForm";
import { RealStateBuyingForm } from "../../components/forms/realStateBuyingForm/realStateBuyingForm";
// style
import "./styles.css";
// export
export const RealState = () => {
  // get language from explorer
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  const [selectedForm, setSelectedForm] = useState('');

  const handleSelectRealStateType = (formName: any) => {
    setSelectedForm(formName);
  };

  // translation
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.realState;
      case "en":
        return labelsEN.realState;
      default:
        return labelsES.realState;
    }
  };
  // return
  return (
    <>
      <div className="gift-page">  
        <header className="page-header">
          <BackHome />
          <HeaderIcon />
        </header>
        <section className="page-section">
          <div className="page-container">
            {/* content */}
            {selectedForm === '' ? 
              <div className="row">
                <div className="unselected-real-state-container">
                  <div className="col-container">
                    <div className="col-content">

                      <div className="row">
                        {/* select options */}
                        <div className="col-col-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-12 col-sx-12 col-sxs-12">
                          <div className="page-title">{translate().realStateTitle}</div>
                          <div className="page-info">{translate().realStateSelect}</div>
                          <div className="row real-state-option-row">
                            {/* selling card */}
                            <div
                              className="col-col-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12"
                              key="selling"
                              onClick={() => handleSelectRealStateType('selling')}
                            >
                              <div className="real-state-option-card">
                                <div className="card-real-state-option-img">
                                  <img
                                    className="real-state-option-img"
                                    src="/images/real-state/icon-property-sale.svg"
                                    alt="Selling"
                                  />
                                </div>
                                <div className="card-real-state-option-info">
                                  <h4 className="card-title">{translate().realStateSellingTitle}</h4>
                                  <p className="card-text">
                                    <span>{translate().realStateSellingIntro}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* buying card */}
                            <div
                              className="col-col-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12"
                              key="buying"
                              onClick={() => handleSelectRealStateType('buying')}
                            >
                              <div className="real-state-option-card">
                                <div className="card-real-state-option-img">
                                  <img
                                    className="real-state-option-img"
                                    src="/images/real-state/icon-property-buy.svg"
                                    alt="Buying"
                                  />
                                </div>
                                <div className="card-real-state-option-info">
                                  <h4 className="card-title">{translate().realStateBuyingTitle}</h4>
                                  <p className="card-text">
                                    <span>{translate().realStateBuyingIntro}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-col-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-12 col-sx-12 col-sxs-12">
                          <div className="row">
                            <div className="col-col-8 col-xxl-8 col-xl-8 col-lg-8 col-ipro-8 col-r-8 col-s-8 col-sm-12 col-sx-12 col-sxs-12">
                              <div className="realtor-container">
                                <div className="realtor-msg">
                                  <p>{translate().realStateRealtorMsg1}</p>
                                  <p>{translate().realStateRealtorMsg2}</p>
                                  <div className="realtor-name-container">
                                    <h2 className="realtor-name">Elizabeth Rodríguez</h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-col-4 col-xxl-4 col-xl-4 col-lg-4 col-ipro-4 col-r-4 col-s-4 col-sm-12 col-sx-12 col-sxs-12">
                              <div className="realtor-container">
                                <img
                                  src={translate().realStateImageBg}
                                  alt="Realtor"
                                  className="realtor-bg"
                                />
                                <img
                                  src={translate().realStateImageRealtor}
                                  alt="Realtor"
                                  className="realtor-image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              : 
              <div className="row">
                <div className="col-col-4 col-xxxl-4 col-xxl-4 col-xl-4 col-lg-4 col-ipro-4 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
                  <div className="col-container">
                    <div className="col-content">
                      <div className="page-title">{translate().realStateTitle}</div>
                      {selectedForm === '' ? 
                        <div className="page-info">{translate().realStateSelect}</div>
                        : 
                        <div className="page-info">
                          {selectedForm === 'selling' ? <span>{translate().realStateSellingSubtitle}</span> : <span>{translate().realStateSellingSubtitle}</span>}
                        </div>
                      }
                      <div className="row real-state-option-row">
                        {/* selling card */}
                        <div
                          className="col-col-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12"
                          key="selling"
                          onClick={() => handleSelectRealStateType('selling')}
                        >
                          <div className={selectedForm === 'selling' ? "real-state-option-card real-state-option-selected" : "real-state-option-card"}>
                            <div className="card-real-state-option-img">
                              <img
                                className="real-state-option-img"
                                src="/images/real-state/icon-property-sale.svg"
                                alt="Selling"
                              />
                            </div>
                            <div className="card-real-state-option-info">
                              <h4 className="card-title">{translate().realStateSellingTitle}</h4>
                              <p className="card-text">
                                <span>{translate().realStateSellingIntro}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* buying card */}
                        <div
                          className="col-col-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12"
                          key="buying"
                          onClick={() => handleSelectRealStateType('buying')}
                        >
                          <div className={selectedForm === 'buying' ? "real-state-option-card real-state-option-selected" : "real-state-option-card"}>
                            <div className="card-real-state-option-img">
                              <img
                                className="real-state-option-img"
                                src="/images/real-state/icon-property-buy.svg"
                                alt="Buying"
                              />
                            </div>
                            <div className="card-real-state-option-info">
                              <h4 className="card-title">{translate().realStateBuyingTitle}</h4>
                              <p className="card-text">
                                <span>{translate().realStateBuyingIntro}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-col-8 col-xxxl-8 col-xxl-8 col-xl-8 col-lg-8 col-ipro-8 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12 col-form-container">
                  <div className="col-container">
                    <div className="col-content">
                      {selectedForm === 'selling' ? 
                        <div>
                          <RealStateSellingForm /> 
                        </div>
                        : 
                        <div>
                          {selectedForm === 'buying' ? 
                            <RealStateBuyingForm />
                            : 
                            <div className="form-container promo-form">
                              <h2>{translate().realStateSelectMsg}</h2>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </div>
    </>
  );
};
