import { BackHome } from "../global/buttons/backhome/BackHome";
import { HeaderIcon } from "../global/header-icon/HeaderIcon";

import "./styles/styles.css";

interface PageFaqUI {
  title: string;
  image: string;
  info: {
    title: string;
    subtitle: string;
    info: string;
  }[];
}

export const PageFaqs = (info: PageFaqUI) => {
  return (
    <>
      <div className={"page-content faq"}>
        <header className="page-header">
          <BackHome />
          <HeaderIcon />
        </header>
        <section className="page-section">
          <div className="page-container">
            <div className="row">
              <div className="col-col-4 col-xxxl-4 col-xxl-4 col-xl-4 col-lg-4 col-ipro-4 col-r-4 col-s-4 col-sm-4 col-sx-12 col-sxs-12">
                <div className="col-container">
                  <div className="col-content img-column faq-img">
                    <img src={info.image} alt={info.title} className="pages-faq-image"/>
                  </div>
                </div>
              </div>
              <div className="col-col-8 col-xxxl-8 col-xxl-8 col-xl-8 col-lg-8 col-ipro-8 col-r-8 col-s-8 col-sm-8 col-sx-12 col-sxs-12">
                <div className="col-container">
                  <div className="col-content">
                    <div className="page-title">{info.title}</div> 
                    <div className="page-list-container">
                      {info.info.map((item) => (
                        <div>
                          <h2 className="page-list-item-title">{item.title}</h2>
                          <h3 className="page-list-item-subtitle">{item.subtitle}</h3>
                          <p className="page-list-item-info">{item.info}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
