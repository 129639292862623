// redux
import { RootState } from "../../../store/MainStore";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { promoInfo } from "../../../data/promoInfo";
// style
import "./styles.css";
import { Link } from "react-router-dom";
// export
export const PromoPopup = () => {
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );

  const translate = () => {
    switch (currentLang) {
      case "es":
        return promoInfo.es;
      case "en":
        return promoInfo.en;
      default:
        return promoInfo.es;
    }
  };

  // data
  const { active } = useSelector(
    (state: RootState) => state.promoPopup.popupInfo
  );
  const [activePromoPop, setActivePromoPop] = useState(active);
  const [coockieShowMessage, setCookieShowMessage] = useState(true);

  const notShowPopupMore = () => {
    localStorage.setItem("PromoPopMyConextion", "notMore");
    setCookieShowMessage(false);
  };

  useEffect(() => {
    if (localStorage.getItem("PromoPopMyConextion") === "notMore")
      setActivePromoPop(false);
  }, []);

  // return
  return (
    <>
      {activePromoPop && (
        <div className="pop-promo">
          <div className="pop-container">
            <button
              type="button"
              className="cookiesButton"
              onClick={notShowPopupMore}
            >
              {coockieShowMessage
                ? translate().coockiesMessage
                : translate().coockiesMessageOff}
            </button>
            <div className="pop-closeBtn">
              <img
                src="/images/close.png"
                alt="close"
                className="pop-close-icon"
                onClick={() => setActivePromoPop(false)}
              />
            </div>
            <div className="pop-content">
              <div className="pop-info">
                <div className="pop-title">{translate().title}</div>
                <div className="pop-subtitle">{translate().subtitle}</div>
                <div className="pop-text-info"> {translate().info}</div>
                <div className="pop-call-to-action">
                  <Link onClick={notShowPopupMore} to={translate().callAction.url}>
                    <button type="button" className="btn btn-custom btn-main" onClick={() => setActivePromoPop(false)}>
                      
                      {translate().callAction.btnName}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="pop-image-container">
              <img
                src={translate().image}
                alt={translate().title}
                className="pop-image logo"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
