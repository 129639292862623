const charactersEN = [
  {
    id: "frank",
    characterName: "Frank",
    characterAnimation: "",
    characterBio: "Frank was born in Idaho Falls and is a 67-year-old grandfather, retired helicopter pilot, Vietnam War veteran, and father of Kevin. Frank wants to leave a good legacy for his son and his new family.",
    characterImg: "/images/characters/williams-frank-hi.png",
    characterImgBio: "/images/characters/williams-frank-bio.png",
  },
  {
    id: "abigail",
    characterName: "Abigail",
    characterAnimation: "",
    characterBio: "Born in New York and of Italian descent, Abigail is a charming 64-year-old grandmother who loves cooking the recipes her mother taught her for the whole family. She wants to ensure her grandchildren's future.",
    characterImg: "/images/characters/williams-abigail-hi.png",
    characterImgBio: "/images/characters/williams-abigail-bio.png",
  },
  {
    id: "kevin",
    characterName: "Kevin",
    characterAnimation: "",
    characterBio: "Kevin was born in Baltimore and grew up in New York. He became a businessman who owned a dealership and fell in love with Sarah on a vacation to Colombia. Kevin at 42 is fascinated by cars, spending time with his wife and his sons.",
    characterImg: "/images/characters/williams-kevin-hi.png",
    characterImgBio: "/images/characters/williams-kevin-bio.png",
  },
  {
    id: "sarah",
    characterName: "Sarah",
    characterAnimation: "",
    characterBio: "Sarah was born in Cali Colombia, she met Kevin on one of her vacations and they fell in love instantly. She migrated to the United States, now runs a real estate agency, shares her time with her family and the community of women entrepreneurs in New York.",
    characterImg: "/images/characters/williams-sarah-hi.png",
    characterImgBio: "/images/characters/williams-sarah-bio.png",
  },
  {
    id: "gabriel",
    characterName: "Gabriel",
    characterAnimation: "",
    characterBio: "Gabriel is a 20-year-old born gamer, dividing his time between studying software development and streaming his Call of Duty battles. Gabriel has a large community of fans, promotes gamer gear and NFTs through his website .",
    characterImg: "/images/characters/williams-gabriel-hi.png",
    characterImgBio: "/images/characters/williams-gabriel-bio.png",
  },
  {
    id: "sofia",
    characterName: "Sofia",
    characterAnimation: "",
    characterBio: "Sofia is 25 years old, and is currently studying plastic arts at the School of Visual Arts in New York. She considers herself a non-binary person, seeking to take care of the planet and conserve the environment at all costs.",
    characterImg: "/images/characters/williams-sofia-hi.png",
    characterImgBio: "/images/characters/williams-sofia-bio.png",
  },
  {
    id: "chris",
    characterName: "Chris",
    characterAnimation: "",
    characterBio: "Chris is a rambunctious 10-year-old and captain of his school's basketball team. Chris bikes to school, practice, games and anywhere with his friends and his dog Max." ,
    characterImg: "/images/characters/williams-chris-hi.png",
    characterImgBio: "/images/characters/williams-chris-bio.png",
  },
  {
    id: "max",
    characterName: "Max",
    characterAnimation: "",
    characterBio: "This furry guy has been with the family for 3 years and is the darling of everyone in the family. He always wants the ball to be thrown and starts looking forward to looking for it.",
    characterImg: "/images/characters/williams-max-hi.png",
    characterImgBio: "/images/characters/williams-max-bio.png",
  },
  {
    id: "sebastian",
    characterName: "Sebastian",
    characterAnimation: "",
    characterBio: "Sebastian was born in New Jersey, he is 29 years old and of Latino parents who immigrated to the United States in the 80's, Sebastian is a taxi driver and passionate about exercise",
    characterImg: "/images/characters/garcias-sebastian-hi.png",
    characterImgBio: "/images/characters/garcias-sebastian-bio.png",
  },
  {
    id: "andrea",
    characterName: "Andrea",
    characterAnimation: "",
    characterBio: "Andrea, 25, was born in Phoenix and is of Mexican descent. Andrea is in charge of the household finances and pays the rent on the house to Sarah Williams",
    characterImg: "/images/characters/garcias-andrea-hi.png",
    characterImgBio: "/images/characters/garcias-andrea-bio.png",
  },
  {
    id: "bella",
    characterName: "Bella",
    characterAnimation: "",
    characterBio: "This furry guy has been with the family for 3 years and is the darling of everyone in the family. He always wants the ball to be thrown and starts looking forward to looking for it.",
    characterImg: "/images/characters/garcias-bella-hi.png",
    characterImgBio: "/images/characters/garcias-bella-bio.png",
  },
]
export default charactersEN;