
// style
import "./styles/styles.css";
// components
import { BackHome } from "../global/buttons/backhome/BackHome";
import { HeaderIcon } from "../global/header-icon/HeaderIcon";
import { SimpleForm } from "../forms/simpleForm/SimpleForm";
// interface
interface PageContentUI {
  title: string;
  info: string;
  image: string;
}
// export
export const ContactPage = (info: PageContentUI) => {
  // return
  return (
    <>
      <div className={"page-content contact contact-bg"}>
        <header className="page-header">
          <BackHome />
          <HeaderIcon />
        </header>
        <section className="page-section">
          <div className="page-container">
            <div className="row">
              <div className="col-col-5 col-xxxl-5 col-xxl-5 col-xl-5 col-lg-5 col-ipro-5 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
                <div className="col-container">
                  <div className="col-content img-column contact-img">
                    <img src={info.image} alt={info.title}/>
                  </div>
                </div>
              </div>
              <div className="col-col-7 col-xxxl-7 col-xxl-7 col-xl-7 col-lg-7 col-ipro-7 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
                <div className="col-container">
                  <div className="col-content">
                    <div className="page-title">{info.title}</div>
                    <div className="page-info">{info.info}</div>
                    <SimpleForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
