const servicesES = [
  {
    id: "mainScene",
    title: "My Conextion ",
    subtitle: "Asegura lo que mas quieres",
    titleModal: "My Conextion",
    content: "Encuentra el seguro que necesitas en un solo lugar",
    downloadUrl: "",
    characters: [],
  },
  {
    id: "life",
    title: "Vida ",
    subtitle: "¡Asegura a los Tuyos!",
    titleModal: "Seguro de Vida",
    content: "El seguro de vida es un contrato entre una aseguradora y el propietario de una póliza. Una póliza de seguro de vida garantiza que la aseguradora paga una suma de dinero a los beneficiarios designados cuando el asegurado fallece a cambio de las primas pagadas por el titular de la póliza durante su vida.",
    downloadUrl: "",
    characters: [
      {
        id: "frank",
        characterName: "Frank",
      },
      {
        id: "abigail",
        characterName: "Abigail",
      },
    ],
  },
  {
    id: "car",
    title: "Autos ",
    subtitle: "Si usas cualquier vehículo motorizado en el curso de sus actividades comerciales necesita un seguro de automóvil comercial.",
    titleModal: "Seguro de Autos",
    content: "Automóviles comerciales brinda protección para los automóviles utilizados en el curso y el alcance del negocio.",
    downloadUrl: "",
    characters: [
      {
        id: "kevin",
        characterName: "Kevin",
      },
    ],
  },
  {
    id: "home",
    title: "Casa ",
    subtitle: "Asegura tu propiedad!",
    titleModal: "Seguro para tu Casa",
    content: "Esto cubre la estructura real de su hogar y los componentes que la componen, como el techo, el revestimiento, las ventanas, las encimeras y el piso",
    downloadUrl: "",
    characters: [
      {
        id: "sebastian",
        characterName: "Sebastian",
      },
    ],
  },
  {
    id: "business",
    title: "Comercial ",
    subtitle: "Asegura tu Trabajo!",
    titleModal: "Seguro Comercial",
    content: "La cobertura comercial, también conocida como cobertura de empresa comercial, cubre varias condiciones repentinas que pueden ser costosas sin una póliza de cobertura: el seguro de cobertura generalmente incluye lesiones del trabajador o del comprador, daños a los activos, robo y cualquier procedimiento de responsabilidad contra la empresa.",
    downloadUrl: "",
    characters: [
      {
        id: "sarah",
        characterName: "Sarah",
      },
    ],
  },
  {
    id: "rent",
    title: "Arrendamiento ",
    subtitle: "Asegura tus pertenencias!",
    titleModal: "Seguro de Arrendamiento",
    content: "La cobertura para inquilinos puede ayudar a cubrir la pérdida o el daño de sus pertenencias, los costos de vida adicionales cuando tiene que abandonar su hogar debido a un evento cubierto, y también puede protegerlo de reclamos de responsabilidad legal privada. Puede ayudarlo a protegerse de los costos potencialmente devastadores de dejar las cosas que posee, de la casa o del alquiler que no posee.",
    downloadUrl: "",
    characters: [
      {
        id: "andrea",
        characterName: "Andrea",
      },
    ],
  },
  {
    id: "accidents",
    title: "Accidentes Personales ",
    subtitle: "Tranquilidad!",
    titleModal: "Seguro para Accidentes Personales",
    content: "Cobertura de accidentes para empresas, grupos y organizaciones.",
    downloadUrl: "",
    characters: [
      {
        id: "chris",
        characterName: "Chris",
      },
    ],
  },
  {
    id: "cybersecurity",
    title: "Ciberseguridad ",
    subtitle: "Porque no sabes cuando….",
    titleModal: "Ciberseguridad",
    content: "La cobertura de responsabilidad legal cibernética es una cobertura de cobertura que brinda a las empresas un conjunto de alternativas de seguro para ayudar a proteger a la organización de infracciones de estadísticas y otros problemas de seguridad cibernética.",
    downloadUrl: "",
    characters: [
      {
        id: "gabriel",
        characterName: "Gabriel",
      },
    ],
  },
  {
    id: "motors",
    title: "Motoras",
    subtitle: "Aseguras tus Ruedas! ",
    titleModal: "Motoras",
    content: "La cobertura de motocicleta es una cobertura de automóvil que protege principalmente motocicletas. En muchos sentidos, la cobertura de bicicletas puede parecerse mucho a la cobertura normal de automóviles. La distinción más importante es el tipo de seguro que puede obtener.",
    downloadUrl: "",
    characters: [
      {
        id: "sofia",
        characterName: "Sofia",
      },
    ],
  },
  {
    id: "pets",
    title: "Mascotas",
    subtitle: "¡Ellos también necesitan Asegurarse!",
    titleModal: "Seguro para Mascotas",
    content: "Las mascotas son parte de su propia familia, por lo que debe hacer todo lo posible para ayudar a preservarlas bien y saludables. Proteger a su mascota es una forma de hacerlo.",
    downloadUrl: "",
    characters: [
      {
        id: "max",
        characterName: "Max",
      },
      {
        id: "bella",
        characterName: "Bella",
      },
    ],
  },
];
export default servicesES;
