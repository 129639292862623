// Redux
import { RootState } from "../../../store/MainStore";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
// Formik and Yup
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
// sweet alert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// components
import { Email } from "../../loaders/email/Email";
// translation
import labelsES from "../../../data/labelsES";
import labelsEN from "../../../data/labelsEN";
// data
import statesData from "../../../data/states";
import citiesData from "../../../data/cities";
// style
import "./styles/styles.css";
import axios from "axios";
// interfaces
interface RealStateSellingUI {
  email: string;
  cellphone: string;
  firstName: string;
  lastName: string;
  sellingTimePeriod: string;
  sellingReason: string;
  propertyLocationState: string;
  propertyLocationCity: string;
  propertyLocationAddress: string;
  propertyLocationZIP: string;
  homeOwnerPackage: boolean;
}
// export
export const RealStateSellingForm = () => {
  // translation
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES;
      case "en":
        return labelsEN;
      default:
        return labelsES;
    }
  };
  // data
  const [openEmail, setOpenEmail] = useState<boolean>(false);
  const [filteredCities, setFilteredCities] = useState([]);
  const handleStateChange = (event: any, setFieldValue: any) => {
    const selectedStateId = event.target.value;
    // filter cities
    const filteredCitiesData: any = citiesData.filter(
      (city) => city.state_id === selectedStateId
    );
    setFilteredCities(filteredCitiesData);
    // update selected state
    setFieldValue('propertyLocationState', selectedStateId);
    // clear city
    setFieldValue('propertyLocationCity', '');
  };
  // alert
  const MySwal = withReactContent(Swal);
  // form submit
  const handlerSubmit = (formInfo: RealStateSellingUI) => {
    // show loader
    setOpenEmail(true);
    // init sellingTimePeriodTxt
    let sellingTimePeriodTxt: string = '';
    // check sellingTimePeriod
    switch (formInfo.sellingTimePeriod) {
      case 'ASAP':
        sellingTimePeriodTxt = translate().realState.form.sellingTimePeriod.labelASAP;
        break;
        case '12Months':
        sellingTimePeriodTxt = translate().realState.form.sellingTimePeriod.label12Months;
        break;
        case '23Months':
        sellingTimePeriodTxt = translate().realState.form.sellingTimePeriod.label23Months;
        break;
        case '3PlusMonths':
        sellingTimePeriodTxt = translate().realState.form.sellingTimePeriod.label3PlusMonths;
        break;
    }
    // init sellingReasonTxt
    let sellingReasonTxt: string = '';
    // check sellingReason
    switch (formInfo.sellingReason) {
      case 'upsizing':
        sellingReasonTxt = translate().realState.form.sellingReason.labelUpsizing;
        break;
        case 'downsizing':
        sellingReasonTxt = translate().realState.form.sellingReason.labelDownsizing;
        break;
        case 'location':
        sellingReasonTxt = translate().realState.form.sellingReason.labelLocation;
        break;
        case 'other':
        sellingReasonTxt = translate().realState.form.sellingReason.labelOther;
        break;
    }
    // get state
    const stateIndex = statesData.findIndex((x) => x.id === formInfo.propertyLocationState);
    const state = statesData[stateIndex];
    // get city
    const cityIndex = citiesData.findIndex((x) => x.id === formInfo.propertyLocationCity);
    const city = citiesData[cityIndex];
    // set realtor message
    let msgRealtor = "";
    msgRealtor = msgRealtor + "<p>" + translate().realState.msgs.msgRealtor1Sale + "</p>";
    msgRealtor = msgRealtor + "<p>" + translate().realState.msgs.msgRealtor2 + "</p>";
    msgRealtor = msgRealtor + "<p><strong>" + translate().realState.form.email.label + "</strong>: " + formInfo.email + "</p>";
    msgRealtor = msgRealtor + "<p><strong>" + translate().realState.form.cellphone.label + "</strong>: " + formInfo.cellphone + "</p>";
    msgRealtor = msgRealtor + "<p><strong>" + translate().realState.form.firstName.label + "</strong>: " + formInfo.firstName + "</p>";
    msgRealtor = msgRealtor + "<p><strong>" + translate().realState.form.lastName.label + "</strong>: " + formInfo.lastName + "</p>";
    msgRealtor = msgRealtor + "<hr>";
    msgRealtor = msgRealtor + "<h4>" + translate().realState.msgs.msgRealtorProperty + "</h4>";
    msgRealtor = msgRealtor + "<p><strong>" + translate().realState.form.sellingTimePeriod.label + "</strong>: " + sellingTimePeriodTxt + "</p>";
    msgRealtor = msgRealtor + "<p><strong>" + translate().realState.form.sellingReason.label + "</strong>: " + sellingReasonTxt + "</p>";
    msgRealtor = msgRealtor + "<p><strong>" + translate().realState.form.propertyLocationState.label + "</strong>: " + state.name + "</p>";
    msgRealtor = msgRealtor + "<p><strong>" + translate().realState.form.propertyLocationCity.label + "</strong>: " + city.name + "</p>";
    // check homeOwnerPackage
    if (formInfo.homeOwnerPackage) {
      msgRealtor = msgRealtor + "<p>" + translate().realState.form.homeOwnerPackage.homeOwnerPackage + "</p>";
    }
    // set client email data
    const emailRealtorData: any = {
      emailId: "64db962a05d3cde930809a15",
      userId: "6304616a7709322e0cbf5c47",
      emailDestination: 'info@myconextion.com',
      subject: translate().realState.msgs.realStateTitle,
      data: {
        title: translate().realState.msgs.msgRealtorTitleSale,
        msg: msgRealtor,
      },
    };
    // send realtor email
    axios
    .post("https://email.myconextion.com/email/send", emailRealtorData)
    .then(function (response) {
      // handle success
      if (response.status === 201) {
        // set carlos email data
        const emailCarlosData: any = {
          emailId: "64db962a05d3cde930809a15",
          userId: "6304616a7709322e0cbf5c47",
          emailDestination: 'carlos.rojas@think-in.co',
          subject: translate().realState.msgs.realStateTitle,
          data: {
            title: translate().realState.msgs.msgRealtorTitleSale,
            msg: msgRealtor,
          },
        };
        // send carlos email
        axios
        .post("https://email.myconextion.com/email/send", emailCarlosData)
        .then(function (response) {
          // handle success
          if (response.status === 201) {
            // set client message
            let msgClient = "";
            msgClient = msgClient + "<p>" + translate().realState.msgs.msgClient + "</p>";
            // set client email data
            const emailClientData: any = {
              emailId: "64db94b505d3cde930809a12",
              userId: "6304616a7709322e0cbf5c47",
              emailDestination: formInfo.email,
              subject: translate().realState.msgs.realStateTitle,
              data: {
                title: translate().realState.msgs.msgClientTitle,
                msg: msgClient,
              },
            };
            // send client email
            axios
            .post("https://email.myconextion.com/email/send", emailClientData)
            .then(function (response) {
              // handle success
              if (response.status === 201) {
                MySwal.fire(
                  translate().realState.realStateTitle,
                  translate().realState.realStateSchedule,
                  "success"
                ).then(() => {
                  // hide loader
                  setOpenEmail(false);
                  // open calendly
                  window.open('https://calendly.com/myconextion', '_blank');
                  // close swal
                  return;
                });
              }
            });
          }
        });
      }
    });
  };
  // return
  return (
    <>
      {openEmail && (
        <Email
          msg={translate().realState.loader.info}
          title={translate().realState.loader.title}
        />
      )}
      <Formik
        initialValues={{
          email: "",
          cellphone: "",
          firstName: "",
          lastName: "",
          sellingTimePeriod: "",
          sellingReason: "",
          propertyLocationState: "",
          propertyLocationCity: "",
          propertyLocationAddress: "",
          propertyLocationZIP: "",
          homeOwnerPackage: false
        }}
        onSubmit={(values, { resetForm }) => {
          handlerSubmit(values);
          resetForm();
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(translate().realState.form.email.error)
            .required(translate().realState.form.email.error),
          cellphone: Yup.number()
            .min(8, translate().realState.form.cellphone.error)
            .required(translate().realState.form.cellphone.error),
          firstName: Yup.string()
            .min(3, translate().realState.form.firstName.error)
            .required(translate().realState.form.firstName.error)
            .matches(
              /^[aA-zZ\u00C0-\u017F]+$/,
              translate().realState.form.firstName.error
            ),
          lastName: Yup.string()
            .min(3, translate().realState.form.lastName.error)
            .required(translate().realState.form.lastName.error)
            .matches(
              /^[aA-zZ\u00C0-\u017F]+$/,
              translate().realState.form.lastName.error
            ),
          sellingTimePeriod: Yup.string().required(translate().realState.form.sellingTimePeriod.error),
          sellingReason: Yup.string().required(translate().realState.form.sellingReason.error),
          propertyLocationState: Yup.string().required(translate().realState.form.propertyLocationState.error),
          propertyLocationCity: Yup.string().required(translate().realState.form.propertyLocationCity.error),
          propertyLocationAddress: Yup.string().required(translate().realState.form.propertyLocationAddress.error),
          propertyLocationZIP: Yup.number().required(translate().realState.form.propertyLocationZIP.error),
        })}
      >
        {({ setFieldValue }) => (
          <Form className="form-container promo-form" noValidate>
            {/* user data */}
            <div className="row">
              {/* email */}
              <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
                <div className="form-item">
                  <label className="form-input-label secondary-text">
                    {translate().realState.form.email.label}
                  </label>
                  <Field
                    name="email"
                    type="email"
                    placeholder={translate().realState.form.email.placeholder}
                    className="form-input"
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="form-text text-danger"
                  />
                </div>
              </div>
              {/* cellphone */}
              <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
                <div className="form-item">
                  <label className="form-input-label secondary-text">
                    {translate().realState.form.cellphone.label}
                  </label>
                  <Field
                    name="cellphone"
                    type="tel"
                    placeholder={translate().realState.form.cellphone.placeholder}
                    className="form-input"
                  />
                  <ErrorMessage
                    name="cellphone"
                    component="span"
                    className="form-text text-danger"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {/* firstName */}
              <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
                <div className="form-item">
                  <label className="form-input-label secondary-text">
                    {translate().realState.form.firstName.label}
                  </label>
                  <Field
                    name="firstName"
                    type="text"
                    placeholder={translate().realState.form.firstName.placeholder}
                    className="form-input"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="span"
                    className="form-text text-danger"
                  />
                </div>
              </div>
              {/* lastName */}
              <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
                <div className="form-item">
                  <label className="form-input-label secondary-text">
                    {translate().realState.form.lastName.label}
                  </label>
                  <Field
                    name="lastName"
                    type="text"
                    placeholder={translate().realState.form.lastName.placeholder}
                    className="form-input"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="span"
                    className="form-text text-danger"
                  />
                </div>
              </div>
            </div>
            {/* selling property data */}
            <div className="row">
              {/* sellingTimePeriod */}
              <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
                <div className="form-item">
                  <label className="form-input-label secondary-text">
                    {translate().realState.form.sellingTimePeriod.label}
                  </label>
                  <Field
                    as="select"
                    name="sellingTimePeriod"
                    className="form-input"
                  >
                    <option value="">{translate().realState.form.sellingTimePeriod.placeholder}</option>
                    <option value="ASAP">{translate().realState.form.sellingTimePeriod.labelASAP}</option>
                    <option value="12Months">{translate().realState.form.sellingTimePeriod.label12Months}</option>
                    <option value="23Months">{translate().realState.form.sellingTimePeriod.label23Months}</option>
                    <option value="3PlusMonths">{translate().realState.form.sellingTimePeriod.label3PlusMonths}</option>
                  </Field>
                  <ErrorMessage
                    name="sellingTimePeriod"
                    component="span"
                    className="form-text text-danger"
                  />
                </div>
              </div>
              {/* sellingReason */}
              <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
                <div className="form-item">
                  <label className="form-input-label secondary-text">
                    {translate().realState.form.sellingReason.label}
                  </label>
                  <Field
                    as="select"
                    name="sellingReason"
                    className="form-input"
                  >
                    <option value="">{translate().realState.form.sellingReason.placeholder}</option>
                    <option value="upsizing">{translate().realState.form.sellingReason.labelUpsizing}</option>
                    <option value="downsizing">{translate().realState.form.sellingReason.labelDownsizing}</option>
                    <option value="location">{translate().realState.form.sellingReason.labelLocation}</option>
                    <option value="other">{translate().realState.form.sellingReason.labelOther}</option>
                  </Field>
                  <ErrorMessage
                    name="sellingReason"
                    component="span"
                    className="form-text text-danger"
                  />
                </div>
              </div>            
            </div>
            {/* property location data */}
            <div className="row">
              {/* header */}
              <div className="col-col-12 col-xxxl-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
                <h4>{translate().realState.form.propertyLocation}</h4>
              </div>
            </div>
            <div className="row">
              {/* propertyLocationState */}
              <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
                <div className="form-item">
                  <label className="form-input-label secondary-text">
                    {translate().realState.form.propertyLocationState.label}
                  </label>
                  <Field
                    as="select"
                    name="propertyLocationState"
                    className="form-input"
                    onChange={(event: any) => handleStateChange(event, setFieldValue)}
                  >
                    <option value="">{translate().realState.form.propertyLocationState.placeholder}</option>
                    {statesData.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="propertyLocationState"
                    component="span"
                    className="form-text text-danger"
                  />
                </div>
              </div>
              {/* propertyLocationCity */}
              <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
                <div className="form-item">
                  <label className="form-input-label secondary-text">
                    {translate().realState.form.propertyLocationCity.label}
                  </label>
                  <Field
                    as="select"
                    name="propertyLocationCity"
                    className="form-input"
                  >
                    {filteredCities.length > 0 ? (
                      <>
                        <option value="">
                          {translate().realState.form.propertyLocationCity.placeholder}
                        </option>
                        {filteredCities.map((city: any) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option value="">
                        {translate().realState.form.propertyLocationCity.selectStateFirst}
                      </option>
                    )}
                  </Field>
                  <ErrorMessage
                    name="propertyLocationCity"
                    component="span"
                    className="form-text text-danger"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {/* propertyLocationAddress */}
              <div className="col-col-8 col-xxxl-8 col-xxl-8 col-xl-8 col-lg-8 col-ipro-8 col-r-8 col-s-8 col-sm-8 col-sx-12 col-sxs-12">
                <div className="form-item">
                  <label className="form-input-label secondary-text">
                    {translate().realState.form.propertyLocationAddress.label}
                  </label>
                  <Field
                    name="propertyLocationAddress"
                    type="text"
                    placeholder={translate().realState.form.propertyLocationAddress.placeholder}
                    className="form-input"
                  />
                  <ErrorMessage
                    name="propertyLocationAddress"
                    component="span"
                    className="form-text text-danger"
                  />
                </div>
              </div>
              {/* propertyLocationZIP */}
              <div className="col-col-4 col-xxxl-4 col-xxl-4 col-xl-4 col-lg-4 col-ipro-4 col-r-4 col-s-4 col-sm-4 col-sx-12 col-sxs-12">
                <div className="form-item">
                  <label className="form-input-label secondary-text">
                    {translate().realState.form.propertyLocationZIP.label}
                  </label>
                  <Field
                    name="propertyLocationZIP"
                    type="text"
                    placeholder={translate().realState.form.propertyLocationZIP.placeholder}
                    className="form-input"
                  />
                  <ErrorMessage
                    name="propertyLocationZIP"
                    component="span"
                    className="form-text text-danger"
                  />
                </div>
              </div>
            </div>
            {/* send button */}
            <div className="row">
              <div className="col-col-12 col-xxxl-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
                <div className="form-item-button">
                  <button type="submit" className="btn btn-custom btn-main">
                    {translate().realState.form.send}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

