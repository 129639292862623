import './styles.css';
import MinLogo  from "../../global/logos/images/min-logo.png";
import { Link } from 'react-router-dom';
export const HeaderIcon = () => {
  return (
    <div className='header-logo'>
        <Link to={'/'}>
          <img src={MinLogo} alt="My Conextion" />
        </Link>
    </div>
  )
}
