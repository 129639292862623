const dataPromoES = [
    {
        img: '/images/promos/promo-only-st-cloud-landscaping.jpg',
        text: '20% de descuento en Jardín Nuevo o Reforma de Jardín',
        code: 'CON3XTMEGARDEN',
        company: 'Only St Cloud Landscaping',
        website: '',
        email: 'srfamily2020@outlook.com  / dsoto_77@hotmail.com',
        phone: '407 556 5040  & 407 556 4488',
        contact: 'Daniel Soto',
        url: ''
    },
    {
        img: '/images/promos/promo-only-st-cloud-landscaping.jpg',
        text: '10% de descuento en poda de arbustos y palmeras',
        code: 'CON3XTMEPRUNNING',
        company: 'Only St Cloud Landscaping',
        website: '',
        email: 'srfamily2020@outlook.com  / dsoto_77@hotmail.com',
        phone: '407 556 5040  & 407 556 4488',
        contact: 'Daniel Soto',
        url: ''
    },
    {
        img: '/images/promos/promo-only-st-cloud-landscaping.jpg',
        text: '10% de descuento en limpieza<br>10% de descuento en hidrolavadora',
        code: 'CON3XTMEWASHER',
        company: 'Only St Cloud Landscaping',
        website: '',
        email: 'srfamily2020@outlook.com  / dsoto_77@hotmail.com',
        phone: '407 556 5040  & 407 556 4488',
        contact: 'Daniel Soto',
        url: ''
    },
    {
        img: '/images/promos/promo-perfect-blinds.jpg',
        text: '15% de descuento en cualquiera de nuestros servicios',
        code: 'CON3XTMEPERFECT',
        company: 'Perfect Blinds',
        website: 'www.perfectblindsandmorefl.com',
        email: 'perfectblindsandmore@gmail.com',
        phone: '321 310 6463',
        contact: 'Randy Perez',
        url: 'https://g.co/kgs/9fDBih'
    },
    // {
    //     img: '/images/promos/promo-starbucks.jpg',
    //     text: '10% de descuento café mocka light',
    //     code: '',
    //     company: 'Starbucks',
    //     website: '',
    //     email: '',
    //     phone: '',
    //     contact: '',
    //     url: ''
    // },
    // {
    //     img: '/images/promos/promo-amazon.jpg',
    //     text: '10% de descuento primera compra',
    //     code: '',
    //     company: 'Amazon',
    //     website: '',
    //     email: '',
    //     phone: '',
    //     contact: '',
    //     url: ''
    // },
    // {
    //     img: '/images/promos/promo-spotify.jpg',
    //     text: '3 meses gratis',
    //     code: '',
    //     company: 'Spotify',
    //     website: '',
    //     email: '',
    //     phone: '',
    //     contact: '',
    //     url: ''
    // },
    // {
    //     img: '/images/promos/promo-splash.jpg',
    //     text: 'Primera lavada gratis',
    //     code: '',
    //     company: 'Mr Splash',
    //     website: '',
    //     email: '',
    //     phone: '',
    //     contact: '',
    //     url: ''
    // },
]
export default dataPromoES;