import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { gsap } from 'gsap'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { goToScene } from "./data/data";
//import * as dat from 'dat.gui'

//Global variables
let currentRef = null;
const clock = new THREE.Clock();
//let mixerCity;
let mixerMrWilliams;
let mixerSraWilliams;
let mixerSrtaWilliams;
let mixerChildWilliams;
let mixerYoungWilliams;
let mixerGrandpaWilliams;
let mixerGrandmaWilliams;
let mixerMrGarcia;
let mixerSraGarcia;
let mixerMax;
let mixerLuna;
let mixerCars01;
let mixerTruck01;
let mixerTruck02;
let mixerVan01;
let mixerTax01;


// MyConextion City Elements
const myConextionElements = {
  allCity: new THREE.Group(),
}

export const isLoaded = ((status = 'loading') => status)
// Loader Manager
const loadingManager = new THREE.LoadingManager(() => {
  scene.background = new THREE.Color(0x00000);
  scene.add(myConextionElements.allCity)
  animIni()
}, (
  itemURL,
  itemsTotalLoad,
  itemsLoaded
) => {
  if (itemsLoaded === itemsTotalLoad) return console.log('Welcome My Conextion is Ready!!!')

},
  () => {
    console.error('Error Loading Manager!!!!!!!!!')
  }

)

//Dat UI
//const gui = new dat.GUI()
//dat.GUI.toggleHide();

// SKYBOX ENV MAPS
const mapEnvSky = []
const skyMapRef = '/envmap/env/'
const textureFt = new THREE.TextureLoader().load(`${skyMapRef}px.png`)
const textureBk = new THREE.TextureLoader().load(`${skyMapRef}nx.png`)
const textureUp = new THREE.TextureLoader().load(`${skyMapRef}py.png`)
const textureDn = new THREE.TextureLoader().load(`${skyMapRef}ny.png`)
const textureRt = new THREE.TextureLoader().load(`${skyMapRef}pz.png`)
const textureLf = new THREE.TextureLoader().load(`${skyMapRef}nz.png`)
mapEnvSky.push(new THREE.MeshBasicMaterial({ map: textureFt }))
mapEnvSky.push(new THREE.MeshBasicMaterial({ map: textureBk }))
mapEnvSky.push(new THREE.MeshBasicMaterial({ map: textureUp }))
mapEnvSky.push(new THREE.MeshBasicMaterial({ map: textureDn }))
mapEnvSky.push(new THREE.MeshBasicMaterial({ map: textureRt }))
mapEnvSky.push(new THREE.MeshBasicMaterial({ map: textureLf }))
mapEnvSky.forEach(map => map.side = THREE.BackSide)
const skyBoxGeo = new THREE.BoxGeometry(500, 500, 500)
const skyBox = new THREE.Mesh(skyBoxGeo, mapEnvSky)
myConextionElements.allCity.add(skyBox)

//Scene, camera, renderer
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(25, 100 / 100, 0.1, 1300);
scene.fog = new THREE.Fog(0xDFE9F3, 60, 270)
scene.fog.isFofExp2 = true
scene.add(camera);
camera.position.set(11, 40, -48);
// const helper = new THREE.CameraHelper(camera);
// scene.add(helper);

//const renderer = new THREE.WebGLRenderer({ antialias: true });
const renderer = new THREE.WebGLRenderer();
renderer.setClearColor(0xffffff)
renderer.shadowMap.enabled = true;
renderer.outputEncoding = THREE.sRGBEncoding
renderer.physicallCorrectLights = false
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.toneMappingExposure = 1.25
renderer.setPixelRatio(2)
renderer.setSize(100, 100);
const effectComposer = new EffectComposer(renderer);
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)


//OrbitControls
const orbitControls = new OrbitControls(camera, renderer.domElement);
const activeOrbitControls = false
orbitControls.enableDamping = activeOrbitControls;
orbitControls.enableZoom = activeOrbitControls;
orbitControls.screenSpacePanning = activeOrbitControls
orbitControls.enablePan = activeOrbitControls
orbitControls.autoRotate = false
orbitControls.enabled = activeOrbitControls
orbitControls.target.set(11, 40, -50)

// Light
const hemiLight = new THREE.HemisphereLight(0x7ca7d2, 0xfac41e, 0.3);
hemiLight.color.setHSL(0.6, 1, 0.6);
hemiLight.groundColor.setHSL(206, 100, 50);
hemiLight.position.set(0, 425, 0);
scene.add(hemiLight);

const dirLight = new THREE.DirectionalLight(0xffffff, 0.7);
dirLight.color.setHSL(0.1, 1, 0.95);
dirLight.position.set(0.2, 10, 7.7);
dirLight.target.position.set(0, 0, 0);
dirLight.position.multiplyScalar(10);
dirLight.castShadow = true;
// const helper = new THREE.DirectionalLightHelper(dirLight, 7);
// scene.add(helper);
scene.add(dirLight);
dirLight.shadow.mapSize.width = 2048;
dirLight.shadow.mapSize.height = 2048;

const d = 50;
dirLight.shadow.camera.left = - d;
dirLight.shadow.camera.right = d;
dirLight.shadow.camera.top = d;
dirLight.shadow.camera.bottom = - d;
dirLight.shadow.bias = -0.0008;

//Import City Elements
//IMPORT STATIC CITY MODEL 3D
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.load('/assets/city/CITY.glb', (cityModeGLTF) => {
  const modelCity = cityModeGLTF.scene
  modelCity.traverse(child3D => {
    if (child3D.isMesh) {
      child3D.castShadow = true
      child3D.receiveShadow = true
    }
  })
  myConextionElements.allCity.add(modelCity)
})

// IMPORT ANIM CITY
const fbxLoader = new FBXLoader(loadingManager);


// CARS ANIMATIONS INI ----------------------!!!! //

//BUS_01
const carsAnimationGroup = new THREE.Group()
fbxLoader.load('/assets/city/cars/CLIP_BUS_ANIM_01.fbx', (cars) => {
  mixerCars01 = new THREE.AnimationMixer(cars);
  const action = mixerCars01.clipAction(cars.animations[0]);
  action.play();
  carsAnimationGroup.scale.set(0.009, 0.009, 0.009)
  carsAnimationGroup.add(cars)
  carsAnimationGroup.position.x = 1.4
  carsAnimationGroup.position.y = 0.4
  myConextionElements.allCity.add(carsAnimationGroup)
})


//TRUCK_01
const truckGroup01 = new THREE.Group()
fbxLoader.load('/assets/city/cars/CLIP_TRUCK_ANIM_01.fbx', (truck) => {
  mixerTruck01 = new THREE.AnimationMixer(truck);
  const action = mixerTruck01.clipAction(truck.animations[0]);
  action.play();
  truckGroup01.scale.set(0.009, 0.009, 0.009)
  truckGroup01.add(truck)
  truckGroup01.position.x = 1.4
  truckGroup01.position.y = 0.4
  myConextionElements.allCity.add(truckGroup01)
})



//TRUCK_02
const truckGroup02 = new THREE.Group()
fbxLoader.load('/assets/city/cars/CLIP_TRUCK_ANIM_02.fbx', (truck) => {
  mixerTruck02 = new THREE.AnimationMixer(truck);
  const action = mixerTruck02.clipAction(truck.animations[0]);
  action.play();
  truckGroup02.scale.set(0.009, 0.009, 0.009)
  truckGroup02.add(truck)
  truckGroup02.position.x = 1.2
  truckGroup02.position.y = 0.4
  myConextionElements.allCity.add(truckGroup02)
})



//VAN_01
const vanGroup01 = new THREE.Group()
fbxLoader.load('/assets/city/cars/VAN_ANIM_A.fbx', (van) => {
  mixerVan01 = new THREE.AnimationMixer(van);
  const action = mixerVan01.clipAction(van.animations[0]);
  action.play();
  vanGroup01.scale.set(0.009, 0.009, 0.009)
  vanGroup01.add(van)
  vanGroup01.position.x = 1.2
  vanGroup01.position.y = 0.4
  myConextionElements.allCity.add(vanGroup01)
})


//TAX_01
const taxGroup01 = new THREE.Group()
fbxLoader.load('/assets/city/cars/TAX_ANIM_A.fbx', (tax) => {
  mixerTax01 = new THREE.AnimationMixer(tax);
  const action = mixerTax01.clipAction(tax.animations[0]);
  action.play();
  taxGroup01.scale.set(0.009, 0.009, 0.009)
  taxGroup01.add(tax)
  taxGroup01.position.x = 1.2
  taxGroup01.position.y = 0.4
  myConextionElements.allCity.add(taxGroup01)
})



// CARS ANIMATIONS END ----------------------!!!! //

//IMPORT KEVIN 3D
const mrWilliams = new THREE.Group()
fbxLoader.load('/assets/models/kevin-anim.fbx', (mrWilliamsModel) => {
  mrWilliamsModel.traverse(william => {
    william.castShadow = true
    william.receiveShadow = true
  })

  mixerMrWilliams = new THREE.AnimationMixer(mrWilliamsModel);
  const action = mixerMrWilliams.clipAction(mrWilliamsModel.animations[0]);
  action.play();
  mrWilliams.scale.set(0.021, 0.021, 0.021)
  mrWilliams.add(mrWilliamsModel)
  mrWilliams.position.x = 22.94
  mrWilliams.position.y = 3.6
  mrWilliams.position.z = 32.2
  mrWilliams.rotation.y = Math.PI * 3.65 / 3
  myConextionElements.allCity.add(mrWilliams)
})

//IMPORT SARA 3D !!!!!!!!!!!!!
const sraGarcia = new THREE.Group()
fbxLoader.load('/assets/models/sara-anim.fbx', (sraGarciaModel) => {
  sraGarciaModel.traverse(william => {
    william.castShadow = true
    william.receiveShadow = true
  })

  mixerSraGarcia = new THREE.AnimationMixer(sraGarciaModel);
  const action = mixerSraGarcia.clipAction(sraGarciaModel.animations[0]);
  action.play();
  sraGarcia.scale.set(0.021, 0.021, 0.021)
  sraGarcia.add(sraGarciaModel)
  sraGarcia.position.x = 21.4
  sraGarcia.position.y = 3.65
  sraGarcia.position.z = 48
  sraGarcia.rotation.y = Math.PI * 3 / 2
  myConextionElements.allCity.add(sraGarcia)
})


//IMPORT CHRIS 3D
const childWilliams = new THREE.Group()
fbxLoader.load('/assets/models/chris-anim2.fbx', (childWilliamsModel) => {
  childWilliamsModel.traverse(william => {
    william.castShadow = true
    william.receiveShadow = true
  })

  mixerChildWilliams = new THREE.AnimationMixer(childWilliamsModel);
  const action1 = mixerChildWilliams.clipAction(childWilliamsModel.animations[0]);
  const action2 = mixerChildWilliams.clipAction(childWilliamsModel.animations[2]);
  action1.play();
  action2.play();
  childWilliams.scale.set(0.021, 0.021, 0.021)
  childWilliams.add(childWilliamsModel)
  childWilliams.position.x = -6
  childWilliams.position.y = 3.655
  childWilliams.position.z = 30.5
  childWilliams.rotation.y = Math.PI * -5 / 4
  myConextionElements.allCity.add(childWilliams)
})

//IMPORT GABRIEL 3D
const youngWilliams = new THREE.Group()
fbxLoader.load('/assets/models/gabriel-anim.fbx', (youngWilliamsModel) => {
  youngWilliamsModel.traverse(william => {
    william.castShadow = true
    william.receiveShadow = true
  })

  mixerYoungWilliams = new THREE.AnimationMixer(youngWilliamsModel);
  const action = mixerYoungWilliams.clipAction(youngWilliamsModel.animations[0]);
  action.play();
  youngWilliams.scale.set(0.021, 0.021, 0.021)
  youngWilliams.add(youngWilliamsModel)
  youngWilliams.position.x = 19
  youngWilliams.position.y = 3.58
  youngWilliams.position.z = -20.5
  youngWilliams.rotation.y = -45
  myConextionElements.allCity.add(youngWilliams)
})



//IMPORT SOFIA 3D
const srtaWilliams = new THREE.Group()
fbxLoader.load('/assets/models/sofia-anim.fbx', (srtaWilliamsModel) => {
  srtaWilliamsModel.traverse(william => {
    william.castShadow = true
    william.receiveShadow = true
  })

  mixerSrtaWilliams = new THREE.AnimationMixer(srtaWilliamsModel);
  const action = mixerSrtaWilliams.clipAction(srtaWilliamsModel.animations[0]);
  action.play();
  srtaWilliams.scale.set(0.021, 0.021, 0.021)
  srtaWilliams.add(srtaWilliamsModel)
  srtaWilliams.position.x = 16.1
  srtaWilliams.position.y = 3.61
  srtaWilliams.position.z = -41.7
  srtaWilliams.rotation.y = -45
  myConextionElements.allCity.add(srtaWilliams)
})


//IMPORT FRANK 3D
const grandpaWilliams = new THREE.Group()
fbxLoader.load('/assets/models/frank-anim-v1.fbx', (grandpaWilliamsModel) => {
  grandpaWilliamsModel.traverse(william => {
    william.castShadow = true
    william.receiveShadow = true
  })

  mixerGrandpaWilliams = new THREE.AnimationMixer(grandpaWilliamsModel);
  const action = mixerGrandpaWilliams.clipAction(grandpaWilliamsModel.animations[0]);
  action.play();
  grandpaWilliams.scale.set(0.021, 0.021, 0.021)
  grandpaWilliams.add(grandpaWilliamsModel)
  grandpaWilliams.position.x = 2.25
  grandpaWilliams.position.y = 3.675
  grandpaWilliams.position.z = 9.97
  grandpaWilliams.rotation.y = 45
  myConextionElements.allCity.add(grandpaWilliams)
})

//IMPORT ABIGAIL 3D
const grandmaWilliams = new THREE.Group()
fbxLoader.load('/assets/models/abigail-anim.fbx', (grandmaWilliamsModel) => {
  grandmaWilliamsModel.traverse(william => {
    william.castShadow = true
    william.receiveShadow = true
  })

  mixerGrandmaWilliams = new THREE.AnimationMixer(grandmaWilliamsModel);
  const action = mixerGrandmaWilliams.clipAction(grandmaWilliamsModel.animations[0]);
  action.play();
  grandmaWilliams.scale.set(0.021, 0.021, 0.021)
  grandmaWilliams.add(grandmaWilliamsModel)
  grandmaWilliams.position.x = 2.1
  grandmaWilliams.position.y = 3.675
  grandmaWilliams.position.z = 10.34
  grandmaWilliams.rotation.y = Math.PI * -3 / 2
  myConextionElements.allCity.add(grandmaWilliams)
})


//IMPORT SEBASTIAN 3D
const mrGarcia = new THREE.Group()
fbxLoader.load('/assets/models/sebastian-anim.fbx', (mrGarciaModel) => {
  mrGarciaModel.traverse(william => {
    william.castShadow = true
    william.receiveShadow = true
  })

  mixerMrGarcia = new THREE.AnimationMixer(mrGarciaModel);
  const action = mixerMrGarcia.clipAction(mrGarciaModel.animations[0]);
  action.play();
  mrGarcia.scale.set(0.021, 0.021, 0.021)
  mrGarcia.add(mrGarciaModel)
  mrGarcia.position.x = 4.26
  mrGarcia.position.y = 3.58
  mrGarcia.position.z = -20.4
  mrGarcia.rotation.y = Math.PI * 0.25
  myConextionElements.allCity.add(mrGarcia)
})

//IMPORT ANDREA 3D
const sraWilliams = new THREE.Group()
fbxLoader.load('/assets/models/andrea-anim.fbx', (sraWilliamsModel) => {
  sraWilliamsModel.traverse(william => {
    william.castShadow = true
    william.receiveShadow = true
  })

  mixerSraWilliams = new THREE.AnimationMixer(sraWilliamsModel);
  const action = mixerSraWilliams.clipAction(sraWilliamsModel.animations[0]);
  action.play();
  sraWilliams.scale.set(0.021, 0.021, 0.021)
  sraWilliams.add(sraWilliamsModel)
  sraWilliams.position.x = 5.43
  sraWilliams.position.y = 3.57
  sraWilliams.position.z = -3.81
  sraWilliams.rotation.y = 45
  myConextionElements.allCity.add(sraWilliams)
})



//IMPORT PET MAX 3D
const petMaxGroup = new THREE.Group()
fbxLoader.load('/assets/models/max-anim.fbx', (petMaxModel) => {
  petMaxModel.traverse(petMax => {
    petMax.castShadow = true
    petMax.receiveShadow = true
  })

  mixerMax = new THREE.AnimationMixer(petMaxModel);
  const action = mixerMax.clipAction(petMaxModel.animations[0]);
  action.play();
  petMaxGroup.add(petMaxModel)
  petMaxGroup.scale.set(0.0021, 0.0021, 0.0021)
  petMaxGroup.position.x = 5.3
  petMaxGroup.position.y = 3.575
  petMaxGroup.position.z = 35.75
  petMaxGroup.rotation.y = Math.PI / -2
  myConextionElements.allCity.add(petMaxGroup)
})


//IMPORT PET LUNA 3D
const petLunaGroup = new THREE.Group()
fbxLoader.load('/assets/models/luna-anim.fbx', (petLunaModel) => {
  petLunaModel.traverse(petLuna => {
    petLuna.castShadow = true
    petLuna.receiveShadow = true
  })

  mixerLuna = new THREE.AnimationMixer(petLunaModel);
  const action = mixerLuna.clipAction(petLunaModel.animations[0]);
  action.play();
  petLunaGroup.add(petLunaModel)
  petLunaGroup.scale.set(0.05, 0.05, 0.05)
  petLunaGroup.position.x = 2.3
  petLunaGroup.position.y = 3.82
  petLunaGroup.position.z = 34.3
  petLunaGroup.rotation.y = Math.PI
  myConextionElements.allCity.add(petLunaGroup)
})




//POSITION BOXES INI
// const dummiesSize = 1
// const targetBox = new THREE.Mesh(
//   new THREE.BoxBufferGeometry(dummiesSize, dummiesSize, dummiesSize),
//   new THREE.MeshBasicMaterial()
// );
// targetBox.material.color.set(0xff0000)
// const positionBox = new THREE.Mesh(
//   new THREE.BoxBufferGeometry(dummiesSize, dummiesSize, dummiesSize),
//   new THREE.MeshBasicMaterial()
// );
// targetBox.position.set(2.1, 6, 32)
// positionBox.position.set(5.7, 6, 40)
// positionBox.material.color.set(0xfff600)
// scene.add(targetBox, positionBox);
//POSITION BOXES END


//gui
// const folderCamera = gui.addFolder("Camera")
//const folderDummies = gui.addFolder("targets")
// const folderLights = gui.addFolder("lights")
// folderCamera.add(orbitControls.target, 'x').min(-180).max(180).step(0.01).name("targetX")
// folderCamera.add(orbitControls.target, 'y').min(0.35).max(120).step(0.01).name("targetY")
// folderCamera.add(orbitControls.target, 'z').min(-180).max(180).step(0.01).name("targetZ")
// folderCamera.add(camera.position, 'x').min(-1000).max(1000).step(0.01).name("cameraX")
// folderCamera.add(camera.position, 'y').min(0.35).max(120).step(0.01).name("cameraY")
// folderCamera.add(camera.position, 'z').min(-1000).max(1000).step(0.01).name("cameraZ")
// folderDummies.add(positionBox.position, 'x').min(-1200).max(1200).step(0.01).name("BoxPosX")
// folderDummies.add(positionBox.position, 'y').min(-1200).max(1200).step(0.01).name("BoxPosY")
// folderDummies.add(positionBox.position, 'z').min(-1200).max(1200).step(0.01).name("BoxPosZ")
// folderDummies.add(targetBox.position, 'x').min(-1200).max(1200).step(0.01).name("TBoxPosX")
// folderDummies.add(targetBox.position, 'y').min(-1200).max(1200).step(0.01).name("TBoxPosY")
// folderDummies.add(targetBox.position, 'z').min(-1200).max(1200).step(0.01).name("TBoxPosZ")
// folderLights.add(dirLight.position, 'x').min(-1200).max(1200).step(0.01).name("lightPosX")
// folderLights.add(dirLight.position, 'y').min(-1200).max(1200).step(0.01).name("lightPosY")
// folderLights.add(dirLight.position, 'z').min(-1200).max(1200).step(0.01).name("lightPosz")
// folderLights.add(dirLight.rotation, 'x').min(-1200).max(1200).step(0.01).name("lightTarX")
// folderLights.add(dirLight.rotation, 'y').min(-1200).max(1200).step(0.01).name("lightTarY")
// folderLights.add(dirLight.rotation, 'z').min(-1200).max(1200).step(0.01).name("lightTarz")



// Fix Canvas Resolution 


//Resize canvas
const resize = () => {
  renderer.setSize(currentRef.clientWidth, currentRef.clientHeight);
  camera.aspect = currentRef.clientWidth / currentRef.clientHeight;
  camera.updateProjectionMatrix();

};
window.addEventListener("resize", resize);

//Animate the scene
const animate = () => {
  orbitControls.update();
  const delta = clock.getDelta();
  //if (mixerCity) mixerCity.update(delta);
  if (mixerMrWilliams) mixerMrWilliams.update(delta);
  if (mixerCars01) mixerCars01.update(delta);
  if (mixerTruck01) mixerTruck01.update(delta);
  if (mixerTruck02) mixerTruck02.update(delta);
  if (mixerVan01) mixerVan01.update(delta);
  if (mixerTax01) mixerTax01.update(delta);
  if (mixerSraWilliams) mixerSraWilliams.update(delta);
  if (mixerSrtaWilliams) mixerSrtaWilliams.update(delta);
  if (mixerChildWilliams) mixerChildWilliams.update(delta);
  if (mixerYoungWilliams) mixerYoungWilliams.update(delta);
  if (mixerGrandpaWilliams) mixerGrandpaWilliams.update(delta);
  if (mixerGrandmaWilliams) mixerGrandmaWilliams.update(delta);
  if (mixerMrGarcia) mixerMrGarcia.update(delta);
  if (mixerSraGarcia) mixerSraGarcia.update(delta);
  if (mixerMax) mixerMax.update(delta);
  if (mixerLuna) mixerLuna.update(delta);
  effectComposer.render();
  //renderer.render(scene, camera);
  requestAnimationFrame(animate);

};
animate();

//Init and mount the scene
export const initScene = (mountRef) => {
  currentRef = mountRef.current;
  resize();
  currentRef.appendChild(renderer.domElement);
};


let currentScene = []
const ChangeScene = (getScene) => {
  currentScene = []
  currentScene.push(getScene)
}

//Dismount and clena up the buffer from the scene
export const cleanUpScene = () => {
  currentRef.removeChild(renderer.domElement);
};

export const cameraController = (goTo = 'mainScene') => {
  const currentRenderSize = currentRef.clientWidth
  const currentPosCamera = camera.position
  const sceneRef = currentScene[0]

  const fixResolution = () => {

    switch (goTo) {
      case 'mrWilliams':
        const setFixCameraAuto = () => {
          if (currentRenderSize < 650 && currentRenderSize > 500) {
            return {
              targetX: 2.2,
              targetY: 0,

            }
          }

          if (currentRenderSize < 501 && currentRenderSize > 0) {
            return {
              targetX: 2,
              targetY: 0,

            }
          }

          else {
            return {
              targetX: 0,
              targetY: 0,

            }
          }
        }

        return setFixCameraAuto()

      case 'grandparentsWilliams':
        const setFixCameraLife = () => {
          if (currentRenderSize < 650 && currentRenderSize > 500) {
            return {
              targetX: -1.2,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 501 && currentRenderSize > 0) {
            return {
              targetX: -1.4,
              targetY: 0.2,

            }
          }

          else {
            return {
              targetX: 0,
              targetY: 0,

            }
          }
        }
        return setFixCameraLife()



      case 'mrGarcia':
        const setFixCameraHome = () => {
          if (currentRenderSize < 850 && currentRenderSize > 750) {
            return {
              targetX: -0.28,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 751 && currentRenderSize > 550) {
            return {
              targetX: -1,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 449 && currentRenderSize > 250) {
            return {
              targetX: -1.5,
              targetY: 0.2,

            }
          }

          else {
            return {
              targetX: 0,
              targetY: 0,

            }
          }
        }
        return setFixCameraHome()



      case 'sraGarcia':
        const setFixCameraBussiness = () => {
          if (currentRenderSize < 900 && currentRenderSize > 685) {
            return {
              targetX: 1.35,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 686 && currentRenderSize > 400) {
            return {
              targetX: 2.35,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 401 && currentRenderSize > 200) {
            return {
              targetX: 3.35,
              targetY: 0.2,

            }
          }

          else {
            return {
              targetX: 0,
              targetY: 0,

            }
          }
        }
        return setFixCameraBussiness()


      case 'sraWilliams':
        const setFixCameraRent = () => {
          if (currentRenderSize < 900 && currentRenderSize > 685) {
            return {
              targetX: -1.2,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 686 && currentRenderSize > 400) {
            return {
              targetX: -2.2,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 401 && currentRenderSize > 200) {
            return {
              targetX: -3.2,
              targetY: 0.2,

            }
          }

          else {
            return {
              targetX: 0,
              targetY: 0,

            }
          }
        }
        return setFixCameraRent()

      case 'childWilliams':
        const setFixCameraAccidents = () => {
          if (currentRenderSize < 900 && currentRenderSize > 685) {
            return {
              targetX: 4.5,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 686 && currentRenderSize > 400) {
            return {
              targetX: 5.5,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 401 && currentRenderSize > 200) {
            return {
              targetX: 5,
              targetY: 0.2,

            }
          }

          else {
            return {
              targetX: 0,
              targetY: 0,

            }
          }
        }
        return setFixCameraAccidents()


      case 'youngWilliams':
        const setFixCameraSecurity = () => {
          if (currentRenderSize < 900 && currentRenderSize > 685) {
            return {
              targetX: -1,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 686 && currentRenderSize > 400) {
            return {
              targetX: -1.2,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 401 && currentRenderSize > 200) {
            return {
              targetX: -1.35,
              targetY: 0.2,

            }
          }

          else {
            return {
              targetX: 0,
              targetY: 0,

            }
          }
        }
        return setFixCameraSecurity()


      case 'srtaWilliams':
        const setFixCameraMotoras = () => {
          if (currentRenderSize < 900 && currentRenderSize > 685) {
            return {
              targetX: -1,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 686 && currentRenderSize > 450) {
            return {
              targetX: -1.5,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 451 && currentRenderSize > 200) {
            return {
              targetX: -2.3,
              targetY: 0.2,

            }
          }

          else {
            return {
              targetX: 0,
              targetY: 0,

            }
          }
        }
        return setFixCameraMotoras()

      case 'petsWilliams':
        const setFixCameraPets = () => {
          if (currentRenderSize < 1500 && currentRenderSize > 1200) {
            return {
              targetX: 1.3,
              targetY: 0.2,

            }
          }

          if (currentRenderSize < 1201 && currentRenderSize > 1000) {
            return {
              targetX: 1.35,
              targetY: 0.2,

            }
          }


          if (currentRenderSize < 1001 && currentRenderSize > 700) {
            return {
              targetX: 2.5,
              targetY: 0.2,

            }
          }

          else {
            return {
              targetX: 0,
              targetY: 0,

            }
          }
        }
        return setFixCameraPets()


      default:
        return {
          targetX: 0,
          targetY: 0,
          targetZ: 0,

        }
    }
  }
  if (goTo === 'mainScene' && sceneRef !== 'mainScene') {
    gsap.to(camera.position, {
      duration: 5,
      x: 15,
      y: 20,
      z: currentPosCamera.z,
    })
    gsap.to(orbitControls.target, {
      duration: 5,
      x: 15,
      y: 20,
      z: currentPosCamera.z - 10,
    })
    gsap.to(camera.position, {
      duration: 3,
      x: goToScene[goTo].camera.x,
      y: goToScene[goTo].camera.y,
      z: goToScene[goTo].camera.z,
      delay: 4.5

    })
    gsap.to(orbitControls.target, {
      duration: 3,
      x: goToScene[goTo].target.x,
      y: goToScene[goTo].target.y,
      z: goToScene[goTo].target.z,
      delay: 4.5,
      onComplete: () => ChangeScene(goTo)
    })

  }

  if (goTo !== 'mainScene' && sceneRef === 'mainScene') {
    gsap.to(camera.position, {
      duration: 4,
      x: goToScene[goTo].path.camera.x,
      y: goToScene[goTo].path.camera.y,
      z: goToScene[goTo].path.camera.z,
    })

    gsap.to(orbitControls.target, {
      duration: 4,
      x: goToScene[goTo].path.target.x,
      y: goToScene[goTo].path.target.y,
      z: goToScene[goTo].path.target.z
    })

    gsap.to(orbitControls.target, {
      duration: 4,
      delay: 3.8,
      x: goToScene[goTo].target.x + fixResolution().targetX,
      y: goToScene[goTo].target.y,
      z: goToScene[goTo].target.z,
    })
    gsap.to(camera.position, {
      duration: 4,
      delay: 3.8,
      x: goToScene[goTo].camera.x,
      y: goToScene[goTo].camera.y,
      z: goToScene[goTo].camera.z,
      onComplete: () => ChangeScene(goTo)

    })

  }


  if (goTo !== 'mainScene' && sceneRef !== 'mainScene') {
    const currentPosCamera = camera.position
    gsap.to(camera.position, {
      duration: 3,
      x: 10,
      y: 11,
      z: currentPosCamera.z,
    })
    gsap.to(orbitControls.target, {
      duration: 3,
      x: 10,
      y: 11,
      z: currentPosCamera.z - 10,
    })

    gsap.to(camera.position, {
      duration: 3,
      delay: 2.7,
      x: goToScene[goTo].path.camera.x,
      y: goToScene[goTo].path.camera.y,
      z: goToScene[goTo].path.camera.z
    })

    gsap.to(orbitControls.target, {
      duration: 3,
      delay: 2.7,
      x: goToScene[goTo].path.target.x,
      y: goToScene[goTo].path.target.y,
      z: goToScene[goTo].path.target.z
    })

    gsap.to(orbitControls.target, {
      duration: 3,
      delay: 5.8,
      x: goToScene[goTo].target.x + fixResolution().targetX,
      y: goToScene[goTo].target.y,
      z: goToScene[goTo].target.z,
    })
    gsap.to(camera.position, {
      duration: 3,
      delay: 5.8,
      x: goToScene[goTo].camera.x,
      y: goToScene[goTo].camera.y,
      z: goToScene[goTo].camera.z,
      onComplete: () => ChangeScene(goTo)

    })
  }
}

// Move Cube
const animIni = (goTo = 'mainScene') => {
  gsap.to(orbitControls.target, {
    delay: 1,
    duration: 4,
    y: 27,
  })
  gsap.to(camera.position, {
    delay: 1,
    duration: 4,
    y: 27,
    z: -30
  })


  gsap.to(orbitControls.target, {
    delay: 3,
    duration: 7,
    x: goToScene[goTo].target.x,
    y: goToScene[goTo].target.y,
    z: goToScene[goTo].target.z,
  })
  gsap.to(camera.position, {
    delay: 3,
    duration: 7,
    x: goToScene[goTo].camera.x,
    y: goToScene[goTo].camera.y,
    z: goToScene[goTo].camera.z,
    onComplete: () => ChangeScene(goTo)
  })
}

