// Redux
import { RootState } from "../../../store/MainStore";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
// Formik and Yup
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Politics } from "../../politics/Politics";
// sweet alert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// components
import { Email } from "../../loaders/email/Email";
import { setPromosInfo } from "../../../store/slices/promos";
// translation
import labelsES from "../../../data/labelsES";
import labelsEN from "../../../data/labelsEN";
// style
import "./styles/styles.css";
import axios from "axios";
// interfaces
interface ClientInfoUI {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}
// export
export const PromoForm = () => {
  const dispatch = useDispatch();
  // translation
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  const promoSelected = useSelector(
    (state: RootState) => state.promo.promoInfo
  );
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES;
      case "en":
        return labelsEN;
      default:
        return labelsES;
    }
  };
  // data
  const [openEmail, setOpenEmail] = useState<boolean>(false);
  // alert
  const MySwal = withReactContent(Swal);
  // data
  // form submit
  const handlerSubmit = (clientInfo: ClientInfoUI) => {
    // check for promo selection
    if (promoSelected.code.length < 1) {
      MySwal.fire(
        "Ooops",
        translate().gifts.form.youMustSelectPromo,
        "warning"
      ).then(() => {
        return;
      });
    } else {
      // show loader
      setOpenEmail(true);
      // set client message
      let msgClient = "";
      msgClient = msgClient + "<p>" + translate().gifts.msgClient + "</p>";
      msgClient = msgClient + "<p>" + translate().gifts.msgCodeClient + " " + promoSelected.code + "</p>";
      msgClient = msgClient + "<p>" + translate().gifts.msgPromoClient + "</p>";
      msgClient = msgClient + "<p>" + promoSelected.text + "</p>";
      msgClient = msgClient + "<p>" + translate().gifts.msgContactClient + "</p>";
      msgClient = msgClient + "<ul>";
      msgClient = msgClient + "<li><strong>" + translate().gifts.contact.company + "</strong>: " + promoSelected.company + "</li>";
      msgClient = msgClient + "<li><strong>" + translate().gifts.contact.website + "</strong>: " + promoSelected.website + "</li>";
      msgClient = msgClient + "<li><strong>" + translate().gifts.contact.email + "</strong>: " + promoSelected.email + "</li>";
      msgClient = msgClient + "<li><strong>" + translate().gifts.contact.phone + "</strong>: " + promoSelected.phone + "</li>";
      msgClient = msgClient + "<li><strong>" + translate().gifts.contact.contact + "</strong>: " + promoSelected.contact + "</li>";
      msgClient = msgClient + "</ul>";
      // set client email data
      const emailClientData: any = {
        emailId: "64db94b505d3cde930809a12",
        userId: "6304616a7709322e0cbf5c47",
        emailDestination: clientInfo.email,
        subject: translate().gifts.msgTitleClient,
        data: {
          title: clientInfo.firstName + " " + translate().gifts.msgTitleClient,
          msg: msgClient,
        },
      };
      // send email
      axios
      .post("https://email.myconextion.com/email/send", emailClientData)
      .then(function (response) {
        // handle success
        if (response.status === 201) {
          // set ally message
          let msgAlly = "";
          msgAlly = msgAlly + "<p>" + translate().gifts.msgAlly + "</p>";
          msgAlly = msgAlly + "<p>" + translate().gifts.msgCodeAlly + " " + promoSelected.code + "</p>";
          msgAlly = msgAlly + "<p>" + translate().gifts.msgPromoAlly + "</p>";
          msgAlly = msgAlly + "<p>" + promoSelected.text + "</p>";
          msgAlly = msgAlly + "<p>" + translate().gifts.msgContactAlly + "</p>";
          msgAlly = msgAlly + "<ul>";
          msgAlly = msgAlly + "<li><strong>" + translate().gifts.contact.email + "</strong>: " + clientInfo.email + "</li>";
          msgAlly = msgAlly + "<li><strong>" + translate().gifts.contact.phone + "</strong>: " + clientInfo.phone + "</li>";
          msgAlly = msgAlly + "<li><strong>" + translate().gifts.contact.name + "</strong>: " + clientInfo.firstName + "</li>";
          msgAlly = msgAlly + "<li><strong>" + translate().gifts.contact.lastname + "</strong>: " + clientInfo.lastName + "</li>";
          msgAlly = msgAlly + "</ul>";
          // set ally email data
          const emailAllyData: any = {
            emailId: "64db94b505d3cde930809a12",
            userId: "6304616a7709322e0cbf5c47",
            emailDestination: promoSelected.email,
            subject: translate().gifts.msgTitleAlly,
            data: {
              title: translate().gifts.msgTitleAlly,
              msg: msgAlly,
            },
          };
          // send email
          axios
          .post("https://email.myconextion.com/email/send", emailAllyData)
          .then(function (response) {
            // handle success
            if (response.status === 201) {
              // set owner message
              let msgOwner = "";
              msgOwner = msgOwner + "<p>" + translate().gifts.msgCodeAlly + " " + promoSelected.code + "</p>";
              msgOwner = msgOwner + "<p>" + translate().gifts.msgPromoAlly + "</p>";
              msgOwner = msgOwner + "<p>" + promoSelected.text + "</p>";
              msgOwner = msgOwner + "<p>" + translate().gifts.msgContactOwnerClient + "</p>";
              msgOwner = msgOwner + "<ul>";
              msgOwner = msgOwner + "<li><strong>" + translate().gifts.contact.email + "</strong>: " + clientInfo.email + "</li>";
              msgOwner = msgOwner + "<li><strong>" + translate().gifts.contact.phone + "</strong>: " + clientInfo.phone + "</li>";
              msgOwner = msgOwner + "<li><strong>" + translate().gifts.contact.name + "</strong>: " + clientInfo.firstName + "</li>";
              msgOwner = msgOwner + "<li><strong>" + translate().gifts.contact.lastname + "</strong>: " + clientInfo.lastName + "</li>";
              msgOwner = msgOwner + "</ul>";
              msgOwner = msgOwner + "<p>" + translate().gifts.msgContactOwnerAlly + "</p>";
              msgOwner = msgOwner + "<ul>";
              msgOwner = msgOwner + "<li><strong>" + translate().gifts.contact.company + "</strong>: " + promoSelected.company + "</li>";
              msgOwner = msgOwner + "<li><strong>" + translate().gifts.contact.website + "</strong>: " + promoSelected.website + "</li>";
              msgOwner = msgOwner + "<li><strong>" + translate().gifts.contact.email + "</strong>: " + promoSelected.email + "</li>";
              msgOwner = msgOwner + "<li><strong>" + translate().gifts.contact.phone + "</strong>: " + promoSelected.phone + "</li>";
              msgOwner = msgOwner + "<li><strong>" + translate().gifts.contact.contact + "</strong>: " + promoSelected.contact + "</li>";
              msgOwner = msgOwner + "</ul>";
              // set owner email data
              const emailOwnerData: any = {
                emailId: "64db962a05d3cde930809a15",
                userId: "6304616a7709322e0cbf5c47",
                emailDestination: "info@myconextion.com",
                subject: translate().gifts.msgTitleOwner,
                data: {
                  title: "My Conextion",
                  msg: msgOwner,
                },
              };
              // send email
              axios
              .post("https://email.myconextion.com/email/send", emailOwnerData)
              .then(function (response) {
                // handle success
                if (response.status === 201) {
                  // hide loader
                  setOpenEmail(false);
                  dispatch(
                    setPromosInfo({
                      promoInfo: {
                        text: "",
                        code: "",
                        company: "",
                        website: "",
                        email: "",
                        phone: "",
                        contact: "",
                        url: "",
                      },
                    })
                  );
                  MySwal.fire(
                    translate().gifts.form.checkYourEmailTitle,
                    translate().gifts.form.checkYourEmail,
                    "success"
                  ).then(() => {
                    return;
                  });
                }
              });
            }
          });
        }
      });
    }
  };
  // return
  return (
    <>
      {openEmail && (
        <Email
          msg={translate().gifts.loader.info}
          title={translate().gifts.loader.title}
        />
      )}
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          terms: false,
        }}
        onSubmit={(values, { resetForm }) => {
          handlerSubmit(values);
          resetForm();
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(translate().gifts.form.email.error)
            .required(translate().gifts.form.email.error),
          phone: Yup.number()
            .min(8, translate().gifts.form.cellphone.error)
            .required(translate().gifts.form.cellphone.error),
          firstName: Yup.string()
            .min(3, translate().gifts.form.names.error)
            .required(translate().gifts.form.names.help)
            .matches(
              /^[aA-zZ\u00C0-\u017F]+$/,
              translate().gifts.form.names.error
            ),
          lastName: Yup.string()
            .min(3, translate().gifts.form.lastNames.error)
            .required(translate().gifts.form.lastNames.help)
            .matches(
              /^[aA-zZ\u00C0-\u017F]+$/,
              translate().gifts.form.lastNames.error
            ),
          terms: Yup.boolean().oneOf([true], translate().gifts.form.terms),
        })}
      >
        <Form className="form-container promo-form" noValidate>
          <div className="row">
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
              <div className="form-item">
                <label className="form-input-label secondary-text">
                  {translate().gifts.form.email.label}
                </label>
                <Field
                  name="email"
                  type="email"
                  placeholder={translate().gifts.form.email.placeholder}
                  className="form-input"
                />
                <ErrorMessage
                  name="email"
                  component="span"
                  className="form-text text-danger"
                />
              </div>
            </div>
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
              <div className="form-item">
                <label className="form-input-label secondary-text">
                  {translate().gifts.form.cellphone.label}
                </label>
                <Field
                  name="phone"
                  type="tel"
                  placeholder={translate().gifts.form.cellphone.placeholder}
                  className="form-input"
                />
                <ErrorMessage
                  name="phone"
                  component="span"
                  className="form-text text-danger"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
              <div className="form-item">
                <label className="form-input-label secondary-text">
                  {translate().gifts.form.names.label}
                </label>
                <Field
                  name="firstName"
                  type="text"
                  placeholder={translate().gifts.form.names.placeholder}
                  className="form-input"
                />
                <ErrorMessage
                  name="firstName"
                  component="span"
                  className="form-text text-danger"
                />
              </div>
            </div>
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
              <div className="form-item">
                <label className="form-input-label secondary-text">
                  {translate().gifts.form.lastNames.label}
                </label>
                <Field
                  name="lastName"
                  type="text"
                  placeholder={translate().gifts.form.lastNames.placeholder}
                  className="form-input"
                />
                <ErrorMessage
                  name="lastName"
                  component="span"
                  className="form-text text-danger"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-col-12 col-xxxl-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
              <div className="form-item-block form-footer">
                <Field id="terms" name="terms" type="checkbox" />
                <Politics />
              </div>
              <ErrorMessage
                name="terms"
                component="p"
                className="form-text text-danger terms-error"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-col-12 col-xxxl-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
              <div className="form-item-button">
                <button type="submit" className="btn btn-custom btn-main">
                  {translate().gifts.form.getGift}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};
