const dataPromoEN = [
    {
        img: '/images/promos/promo-only-st-cloud-landscaping.jpg',
        text: '20% off New Garden or Garden Renovation',
        code: 'CON3XTMEGARDEN',
        company: 'Only St Cloud Landscaping',
        website: '',
        email: 'srfamily2020@outlook.com  / dsoto_77@hotmail.com',
        phone: '407 556 5040  & 407 556 4488',
        contact: 'Daniel Soto',
        url: ''
    },
    {
        img: '/images/promos/promo-only-st-cloud-landscaping.jpg',
        text: '10% off prunning buches and palm tree',
        code: 'CON3XTMEPRUNNING',
        company: 'Only St Cloud Landscaping',
        website: '',
        email: 'srfamily2020@outlook.com  / dsoto_77@hotmail.com',
        phone: '407 556 5040  & 407 556 4488',
        contact: 'Daniel Soto',
        url: ''
    },
    {
        img: '/images/promos/promo-only-st-cloud-landscaping.jpg',
        text: '10% off on pressure washer',
        code: 'CON3XTMEWASHER',
        company: 'Only St Cloud Landscaping',
        website: '',
        email: 'srfamily2020@outlook.com  / dsoto_77@hotmail.com',
        phone: '407 556 5040  & 407 556 4488',
        contact: 'Daniel Soto',
        url: ''
    },
    {
        img: '/images/promos/promo-perfect-blinds.jpg',
        text: '15% off on any of our services',
        code: 'CON3XTMEPERFECT',
        company: 'Perfect Blinds',
        website: 'www.perfectblindsandmorefl.com',
        email: 'perfectblindsandmore@gmail.com',
        phone: '321 310 6463',
        contact: 'Randy Perez',
        url: 'https://g.co/kgs/9fDBih'
    },
    // {
    //     img: '/images/promos/promo-starbucks.jpg',
    //     text: '10% off mocka light coffee',
    //     code: '',
    //     company: 'Starbucks',
    //     website: '',
    //     email: '',
    //     phone: '',
    //     contact: '',
    //     url: ''
    // },
    // {
    //     img: '/images/promos/promo-amazon.jpg',
    //     text: '10% discount first purchase',
    //     code: '',
    //     company: 'Amazon',
    //     website: '',
    //     email: '',
    //     phone: '',
    //     contact: '',
    //     url: ''
    // },
    // {
    //     img: '/images/promos/promo-spotify.jpg',
    //     text: '3 months free',
    //     code: '',
    //     company: 'Spotify',
    //     website: '',
    //     email: '',
    //     phone: '',
    //     contact: '',
    //     url: ''
    // },
    // {
    //     img: '/images/promos/promo-splash.jpg',
    //     text: 'Free first wash',
    //     code: '',
    //     company: 'Mr Splash',
    //     website: '',
    //     email: '',
    //     phone: '',
    //     contact: '',
    //     url: ''
    // },
]
export default dataPromoEN;