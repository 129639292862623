import { createSlice } from "@reduxjs/toolkit"

export interface OpenCharacterSliderUI {
    characterSliderOpen: boolean,
}

const initialState: OpenCharacterSliderUI = {
    characterSliderOpen: false
}

const characterSlider = createSlice({
    name: "goOpenCharacterSlider",
    initialState,
    reducers: {
        setCharacterSlider: (state: OpenCharacterSliderUI, action: any) => {
            state.characterSliderOpen = action.payload
        }
    }
})

export const { setCharacterSlider } = characterSlider.actions
export default characterSlider.reducer