import { createSlice } from "@reduxjs/toolkit"


export interface promosUI {
  promoInfo: {
    text: string,
    code: string,
    company: string,
    website: string,
    email: string,
    phone: string,
    contact: string,
    url: string,
  }

}

const initialState = {
  promoInfo: {
    text: '',
    code: '',
    company: '',
    website: '',
    email: '',
    phone: '',
    contact: '',
    url: '',
  }
}

const promosSlice = createSlice({
  name: "goPromoSlice",
  initialState,
  reducers: {
    setPromosInfo: (state: promosUI, action: any) => {
      state.promoInfo = action.payload

    }
  }
})

export const { setPromosInfo } = promosSlice.actions
export default promosSlice.reducer

