import { BackHome } from "../global/buttons/backhome/BackHome";
import { HeaderIcon } from "../global/header-icon/HeaderIcon";

import "./styles/styles.css";

interface PageContentUI {
  title: string;
  info: string;
  image: string;

}

export const GenericPageContentBasic = (info: PageContentUI) => {

  return (
    <>
      <header className="page-header">
        <BackHome />
        <HeaderIcon />
      </header>
      <section className="page-section">
        <div className="page-container">
          <div className="row-about">
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
              <div className="col-container">
                <div className="col-content img-column">
                  <img src={info.image} alt={info.title}/>
                </div>
              </div>
            </div>
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
              <div className="col-container">
                <div className="col-content">
                  <div className="page-title">{info.title}</div>  
                  <div className="page-info">{info.info}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
