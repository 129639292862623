export const promoInfo = {
    es: {
        title: 'Regístrate en nuestro Market Place',
        subtitle: 'No esperes más y obtén grandes beneficios',
        info: 'Conéctate con todo lo que necesitas para tu vida, tu futuro, tu familia, tu negocio y mucho más',
        image: '/images/characters/characters-es.png',
        callAction: {
            url: '/gifts',
            action: '',
            btnName: 'Conéctate Ahora'
        },
        coockiesMessage: `No mostrar de nuevo`,
        coockiesMessageOff: 'Guardado'
    },
    en: {
        title: 'Sign Up into our Market Place',
        subtitle: "Don't wait any longer and get great benefits",
        info: 'Connect with everything you need for your life, your future, your family, your business and much more',
        image: '/images/characters/characters.png',
        callAction: {
            url: '/gifts',
            action: '',
            btnName: 'Connect Now'
        },
        coockiesMessage: `Don't show this again`,
        coockiesMessageOff: 'Saved'

    },
}