import { createSlice } from "@reduxjs/toolkit"


export interface MenuButtonsUI {
    goToFrame: string
}

const initialState: MenuButtonsUI = {
    goToFrame: 'GoToMain'
}

const cameraSlice = createSlice({
    name: "goSceneCamera",
    initialState,
    reducers: {
        setGoToFrame: (state: MenuButtonsUI, action: any) => {
            state.goToFrame = action.payload
        }
    }
})


export const { setGoToFrame } = cameraSlice.actions
export default cameraSlice.reducer

