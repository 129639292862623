// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../store/MainStore";

import { PageFaqs } from "../../components/pageFaqs/PageFaqs";
import labelsES from "../../data/labelsES";
import labelsEN from "../../data/labelsEN";

export const Faqs = () => {
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );

  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.faqs;
      case "en":
        return labelsEN.faqs;
      default:
        return labelsES.faqs;
    }
  };
  const info = {
    title: translate().title,
    image: translate().image,
    info: translate().info,
  };

  return <PageFaqs {...info} />;
};
