export const goToScene = {
    // Main Section
    mainScene: {
        path: {
            target: { x: 15, y: 20, z: 70 },
            camera: { x: 15, y: 20, z: 91 }

        },

        target: {
            x: 15, y: 5.8, z: 60
        },
        camera: {
            x: 15, y: 6.4, z: 91
        },
    },

    // Auto Section
    mrWilliams: {
        path: {
            target: { x: 23.8, y: 9, z: 25.75 },
            camera: { x: 12, y: 9, z: 25.75 }
        },

        target: {
            x: 18, y: 4.5, z: 40.62
        },
        camera: {
            x: 23.8, y: 4, z: 30
        }
    },

    // Life Section

    grandparentsWilliams: {
        path: {
            target: { x: 15, y: 9, z: 10 },
            camera: { x: 15, y: 9, z: 13 }
        },
        target: {
            x: 4, y: 4.5, z: 10
        },
        camera: {
            x: 7.8, y: 6.6, z: 13

        }
    },


    // Home Section
    mrGarcia: {
        path: {
            target: { x: 15, y: 9, z: -18 },
            camera: { x: 15, y: 9, z: -14.5 }
        },

        target: {
            x: 6.4, y: 5, z: -20
        },
        camera: {
            x: 8.6, y: 5, z: -14.5

        }
    },

    // Bussiness Section
    sraGarcia: {
        path: {
            target: { x: 15, y: 9, z: 39 },
            camera: { x: 15, y: 9, z: 41 }
        },

        target: {
            x: 22, y: 4.2, z: 53
        },
        camera: {
            x: 14.87, y: 6.4, z: 41.53

        }
    },

    // Section Rent
    sraWilliams: {
        path: {
            target: { x: 15, y: 11, z: -4 },
            camera: { x: 15, y: 11, z: -2 }
        },

        target: {
            x: 8.91, y: 4.71, z: -3.81
        },
        camera: {
            x: 12.3, y: 4.36, z: -2.
        }
    },

    // Accidents Section
    childWilliams: {
        path: {
            target: { x: 9, y: 11, z: 21 },
            camera: { x: 15, y: 11, z: 22 }
        },


        target: {
            x: -10.3, y: 3.5, z: 30
        },
        camera: {
            x: 3, y: 7.5, z: 22.6

        },
    },

    //Cyber Security
    youngWilliams: {
        path: {
            target: { x: 17, y: 11, z: -19 },
            camera: { x: 15, y: 11, z: -15 }
        },
        target: {
            x: 20.3, y: 4.85, z: -20
        },
        camera: {
            x: 16.5, y: 5.6, z: -15.33
        },
    },


    // Motoras Section
    srtaWilliams: {
        path: {
            target: { x: 15, y: 11, z: -41 },
            camera: { x: 15, y: 11, z: -36}
        },
        target: {
            x: 18, y: 4, z: -41
        },
        camera: {
            x: 11, y: 6.36, z: -36
        }
    },

    //Pets Section
    petsWilliams: {
        path: {
            target: { x: 4, y: 15, z: 36 },
            camera: { x: 15, y: 15, z: 32 }
        },
        target: {
            x: 4, y: 3.4, z: 36.7
        },
        camera: {
            x: 0, y: 4.85, z: 32.9

        }
    },

}
