import { useEffect, useRef } from "react";
import { cleanUpScene, initScene, isLoaded } from "./ScriptThree";

const Scene = () => {
  const mountRef = useRef(null);


  useEffect(() => {
    initScene(mountRef);
    isLoaded()
    return () => {
      cleanUpScene();
    };
  }, []);

  return ( 
     <div style={{ width: '100%', height: '100vh' }} ref={mountRef}></div> 
  );
};

export default Scene;
