// style
import "./styles/styles.css";
// translation
// components
import { BackHome } from "../gifts/components/back-home/BackHome";
import { HeaderIcon } from "../../components/global/header-icon/HeaderIcon";
// interface
interface PageGenericContentUI {
  family: string;
  title: string;
  subtitle: string;
  image: string;
  altText: string;
  members: {
    name: string;
    info: string;
    image: string;
  }[];
}
// export
export const PageComponent = (contentInfo: PageGenericContentUI) => {
  // return
  return (
    <>
      <div className={"page-content " + contentInfo.family}>
        <header className="page-header">
          <BackHome />
          <HeaderIcon />
        </header>
        <section className="page-section">
          <div className="page-container">
            <div className="row">
              <div className="col-col-4 col-xxxl-4 col-xxl-4 col-xl-3 col-lg-3 col-ipro-3 col-r-3 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
                <div className="col-container">
                  <div className="col-content">
                    <h1 className="page-title"><span dangerouslySetInnerHTML={{__html: contentInfo.title}}></span></h1>
                    <p className="page-subtitle">{contentInfo.subtitle}</p>
                  </div>
                  <div className="col-content img-column family-img">
                    <img src={contentInfo.image} alt={contentInfo.title} className="page-picture"/>
                  </div>
                </div>
              </div>
              <div className="col-col-8 col-xxxl-8 col-xxl-8 col-xl-9 col-lg-9 col-ipro-9 col-r-9 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
                <div className="col-container">
                  <div className="col-content">
                    <div
                      className={`card-character-container ${
                        contentInfo.members.length > 3 && "fixCharacterListPosition"
                      }`}
                    >
                      {contentInfo.members.map((character, index) => (
                        <div className="character-card" key={index}>
                          <div className="row">
                            <div className="col-col-1 col-xxxl-1 col-xxl-1 col-xl-1 col-lg-2 col-ipro-2 col-r-3 col-s-2 col-sm-3 col-sx-12 col-sxs-12">
                              <img src={character.image} alt={character.name} className="character-picture"/>
                            </div>
                            <div className="col-col-11 col-xxxl-11 col-xxl-11 col-xl-11 col-lg-10 col-ipro-10 col-r-9 col-s-10 col-sm-9 col-sx-12 col-sxs-12">
                              <div className="character-data-container">
                                <div className="character-card-name">{character.name}</div>
                                <div className="character-card-bio">{character.info}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* <div className="page-row">
              <div className="page-content-one">
                <h1 className="page-title">{contentInfo.title}</h1>
                <p className="page-subtitle">{contentInfo.subtitle}</p>
                <div className="page-image">
                  <img src={contentInfo.image} alt={contentInfo.title} className="page-picture"/>
                </div>
              </div>
              <div className="page-content-two">
                <div
                  className={`card-character-container ${
                    contentInfo.members.length > 3 && "fixCharacterListPosition"
                  }`}
                >
                  {contentInfo.members.map((character, index) => (
                    <div className="character-card" key={index}>
                      <div className="character-card-image">
                        <img src={character.image} alt={character.name} className="character-picture"/>
                      </div>
                      <div className="character-card-info">
                        <div className="character-card-name">{character.name}</div>
                        <div className="character-card-bio">{character.info}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}


          </div>
        </section>
      </div>
    </>
  );
};
