/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cameraController } from "../../../../three/components/Scene/ScriptThree";
import { gsap } from "gsap";
// images
import { goToServicesInfo } from "../../../../data/goToServicesInfo";
// redux
import { setContentInfo } from "../../../../store/slices/contentInfo";
import { RootState } from "../../../../store/MainStore";

import { setCharacterSlider } from "../../../../store/slices/sliderCharacter";
import { setCharacterPopup } from "../../../../store/slices/characterCardPopup";

// translation
import labelsES from "../../../../data/labelsES";
import labelsEN from "../../../../data/labelsEN";

import "./styles.css";
import { useEffect, useState } from "react";
import { SocialIcons } from "../../../global/socialIcons/general/SocialIcons";
import { Version } from "../../../global/version/Version";
// interfaces
interface SendInfoUI {
  scene: string;
  isOpen: boolean;
  getInfoTo: string;
  timing: number;
}

interface OpenDropDownMenuUI {
  reference: string;
  isOpen: boolean;
}

//style
const divStyle: any = {
  zIndex: 2,
};

// export
export const MenuNavbar = () => {
  const [menuMain, setMenuMobile] = useState(false);
  const [dropdownService, setDropdownServices] = useState(false);
  const [dropdownAbout, setDropdownAbout] = useState(false);
  const [dropdownHelp, setDropdownHelp] = useState(false);

  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );

  // translation
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.navbarMenu;
      case "en":
        return labelsEN.navbarMenu;
      default:
        return labelsES.navbarMenu;
    }
  };

  const tl = gsap.timeline();

  useEffect(() => {
    gsap.set(".btnMainMobileGSAP", {
      alpha: 0,
      autoAlpha: 0,
      y: -500,
    });
  }, []);

  // dispatch
  const timeOutSlider = () => {
    const time = setTimeout(() => {
      dispatch(setCharacterSlider(true));
    }, 8000);
    return time;
  };

  const dispatch = useDispatch();
  const cameraHandler = (goToAction: SendInfoUI) => {
    menuState(false);
    clearInterval(timeOutSlider());
    dispatch(setCharacterSlider(false));
    const sliderInfo = {
      getInfoTo: goToAction.getInfoTo,
      isOpen: goToAction.isOpen,
      timing: goToAction.timing,
    };
    dispatch(setCharacterPopup(false));
    dispatch(setContentInfo(sliderInfo));
    cameraController(goToAction.scene);
    timeOutSlider();
  };

  const openMenuService = () => {
    tl.to(".btnMainMobileGSAP", {
      autoAlpha: 1,
      ease: "power1.out",
      duration: 0.35,
      y: 0,
    });
  };

  const closeMenuService = () => {
    tl.to(".btnMainMobileGSAP", {
      autoAlpha: 0,
      duration: 0.35,
      ease: "power1.in",
      y: -300,
    });
  };

  const menuState = (menuState: boolean) => {
    if (!menuState) {
      closeMenuService();
      setMenuMobile(false);
    } else {
      openMenuService();
      setMenuMobile(true);
    }
  };

  const handlerDropMenu = ({ isOpen, reference }: OpenDropDownMenuUI) => {
    switch (reference) {
      case ".dropServicesGSAP":
        if (isOpen) {
          gsap.to(reference, {
            height: "auto",
          });
          setDropdownServices(isOpen);
        } else {
          gsap.to(reference, {
            height: 0,
          });
          setDropdownServices(isOpen);
        }
        break;

      case ".dropAboutGSAP":
        if (isOpen) {
          gsap.to(reference, {
            height: "auto",
          });
          setDropdownAbout(isOpen);
        } else {
          gsap.to(reference, {
            height: 0,
          });
          setDropdownAbout(isOpen);
        }

        break;

      case ".dropHelpGSAP":
        if (isOpen) {
          gsap.to(reference, {
            height: "auto",
          });
          setDropdownHelp(isOpen);
        } else {
          gsap.to(reference, {
            height: 0,
          });
          setDropdownHelp(isOpen);
        }

        break;

      default: {
        gsap.to(reference, {
          height: 0,
        });
      }
    }
  };

  // return
  return (
    <>
      <div
        className="btnMainMobileGSAP menu-main-container"
        style={menuMain ? divStyle : {}}
      >
        <div className="menu-mobile-content-container">
          <div className="menu-mobile-content">
            {/* menu */}
            <div className="menu-mobile-options">
              <div className="menu-mobile-row">
                {/* SERVICES MENU */}
                <div
                  className="drop-menu-mobile"
                  onClick={() =>
                    handlerDropMenu({
                      isOpen: !dropdownService,
                      reference: ".dropServicesGSAP",
                    })
                  }
                >
                  <div className="title-main-menu secondary-text">
                    {translate().services}
                  </div>
                  <div className="menu-arrow">
                    {dropdownService ? (
                      <img
                        src="/images/arrow-list-down.png"
                        alt="Menu My Conextion"
                      />
                    ) : (
                      <img
                        src="/images/arrow-list.png"
                        alt="Menu My Conextion"
                      />
                    )}
                  </div>
                </div>
                <div className="open-main-container services-main-drop dropServicesGSAP">
                  <div className="menu-mobile-item">
                    <a
                      className="mobile-link btn-mobile-menu secondary-text"
                      onClick={() => cameraHandler(goToServicesInfo.life)}
                    >
                      {translate().life}
                    </a>
                    <a
                      className="mobile-link btn-mobile-menu secondary-text"
                      id="nav-car"
                      onClick={() => cameraHandler(goToServicesInfo.car)}
                    >
                      {translate().car}
                    </a>
                    <a
                      className="mobile-link btn-mobile-menu secondary-text"
                      id="nav-home"
                      onClick={() => cameraHandler(goToServicesInfo.home)}
                    >
                      {translate().home}
                    </a>
                  </div>
                  <div className="menu-mobile-item">
                    <a
                      className="mobile-link btn-mobile-menu secondary-text"
                      id="nav-business"
                      onClick={() => cameraHandler(goToServicesInfo.commercial)}
                    >
                      {translate().business}
                    </a>
                    <a
                      className="mobile-link btn-mobile-menu secondary-text"
                      id="nav-rent"
                      onClick={() => cameraHandler(goToServicesInfo.rent)}
                    >
                      {translate().rent}
                    </a>
                    <a
                      className="mobile-link btn-mobile-menu secondary-text"
                      id="nav-accidents"
                      onClick={() => cameraHandler(goToServicesInfo.accidents)}
                    >
                      {translate().accidents}
                    </a>
                  </div>
                  <div className="menu-mobile-item">
                    <a
                      className="mobile-link btn-mobile-menu secondary-text"
                      id="nav-cibersecurity"
                      onClick={() =>
                        cameraHandler(goToServicesInfo.cybersecurity)
                      }
                    >
                      {translate().cybersecurity}
                    </a>
                    <a
                      className="mobile-link btn-mobile-menu secondary-text"
                      id="nav-motors"
                      onClick={() => cameraHandler(goToServicesInfo.motors)}
                    >
                      {translate().motors}
                    </a>
                    <a
                      className="mobile-link btn-mobile-menu secondary-text"
                      id="nav-pets"
                      onClick={() => cameraHandler(goToServicesInfo.pets)}
                    >
                      {translate().pets}
                    </a>
                  </div>
                </div>
                {/* REAL ESTATE MENU */}
                <Link to="/realState">
                  <div className="title-main-menu secondary-text fix-menu-text-bold">
                    {translate().realState}
                  </div>
                </Link>
                 {/* FINANCING MENU */}
                <a
                  href="https://www.rategenius.com/sponsor/landing?leadChannel=RATEGENIUS_MY_CONEXTION_FAST_TRACK_API"
                  target="_blank"
                  className="title-main-menu secondary-text fix-menu-text-bold"
                  rel="noreferrer"
                >
                  {translate().financing}
                </a>
                {/* CRIPTOCURRENCY MENU */}
                <Link to="/crypto">
                  <div className="title-main-menu secondary-text fix-menu-text-bold">
                    {translate().crypto}
                  </div>
                </Link>
                {/* ABOUT MENU */}
                <div
                  className="drop-menu-mobile"
                  onClick={() =>
                    handlerDropMenu({
                      isOpen: !dropdownAbout,
                      reference: ".dropAboutGSAP",
                    })
                  }
                >
                  <div className="title-main-menu secondary-text">
                    {translate().about}
                  </div>
                  <div className="menu-arrow">
                    {dropdownAbout ? (
                      <img
                        src="/images/arrow-list-down.png"
                        alt="Menu My Conextion"
                      />
                    ) : (
                      <img
                        src="/images/arrow-list.png"
                        alt="Menu My Conextion"
                      />
                    )}
                  </div>
                </div>
                <div className="open-main-container services-about-drop dropAboutGSAP">
                  <div className="menu-mobile-item">
                    <Link
                      to="/about/us"
                      className="btn-mobile-menu mobile-link secondary-text"
                    >
                      {translate().aboutUs}
                    </Link>
                    <Link
                      to="/about/williams"
                      className="btn-mobile-menu mobile-link secondary-text"
                    >
                      {translate().aboutWilliams}
                    </Link>
                    <Link
                      to="/about/garcias"
                      className="btn-mobile-menu mobile-link secondary-text"
                    >
                      {translate().aboutGarcia}
                    </Link>
                  </div>
                </div>
                {/* BLOG MENU */}
                <a
                  href="https://blog.myconextion.com"
                  target="_blank"
                  className="title-main-menu secondary-text fix-menu-text-bold"
                  rel="noreferrer"
                >
                  Blog
                </a>
                {/* HELP MENU */}
                <div
                  className="drop-menu-mobile"
                  onClick={() =>
                    handlerDropMenu({
                      isOpen: !dropdownHelp,
                      reference: ".dropHelpGSAP",
                    })
                  }
                >
                  <div className="title-main-menu secondary-text">
                    {translate().help}
                  </div>
                  <div className="menu-arrow">
                    {dropdownHelp ? (
                      <img
                        src="/images/arrow-list-down.png"
                        alt="Menu My Conextion"
                      />
                    ) : (
                      <img
                        src="/images/arrow-list.png"
                        alt="Menu My Conextion"
                      />
                    )}
                  </div>
                </div>
                <div className="open-main-container services-help-drop dropHelpGSAP">
                  <div className="menu-mobile-item">
                    <Link
                      to="/help/how-it-works"
                      className="btn-mobile-menu mobile-link secondary-text"
                    >
                      {translate().howItWorks}
                    </Link>
                    <Link
                      to="/help/faqs"
                      className="btn-mobile-menu mobile-link secondary-text"
                    >
                      {translate().faqs}
                    </Link>
                  </div>
                </div>
                {/* GIFTS MENU */}
                <Link to="/gifts">
                  <div className="title-main-menu secondary-text fix-menu-text-bold">
                    {translate().gifts}
                  </div>
                </Link>
                {/* CONTACT MENU */}
                <Link to="/contact">
                  <div className="title-main-menu about-link secondary-text fix-menu-text-bold">
                    {translate().contact}
                  </div>
                </Link>
                {/* game */}
                <a
                  href="https://kevins-dream.myconextion.com"
                  target="_blank"
                  className="title-main-menu secondary-text fix-menu-text-bold"
                  rel="noreferrer"
                >
                  {translate().game}
                </a>
              </div>
            </div>
            {/* socials */}
            <div className="socials-mobile-services-container">
              <div className="img-mobile-socials">
                <SocialIcons />
                <p>@myconextion</p>
              </div>
            </div>
            {/* developed by */}
            <div className="developed-by">
              <p>
                © Copyright 2022. All Rights Reserved. <br />
                Designed with love by{" "}
                <a
                  className="think-link"
                  href="https://think-in.co/"
                  target="_blank"
                  rel="norel nofollow noopener noreferrer"
                >
                  Think In
                </a>
              </p>
            </div>
            {/* version */}
            <div className="version-menu">
              <Version />
            </div>
          </div>
        </div>
      </div>

      <div className="btn-main-container" style={menuMain ? divStyle : {}}>
        {!menuMain ? (
          <div
            className="btn-menu-mobile-service"
            onClick={() => menuState(true)}
          >
            <img src="/images/icon-menu.png" alt="My Conextion Menu" />
          </div>
        ) : (
          <div
            className="btn-menu-mobile-service btn-close"
            onClick={() => menuState(false)}
          >
            <img src="/images/icon-menu-close.png" alt="My Conextion Menu" />
          </div>
        )}
      </div>
    </>
  );
};
