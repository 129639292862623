//import { BackToGift } from "../global/buttons/backTo/BackToGift";
import { BackTo } from "../global/buttons/backTo/BackTo";
import { HeaderIcon } from "../global/header-icon/HeaderIcon";

import "./styles/styles.css";
export const PrivacyPage = () => {
  return (
    <>
      <div className="page-privacy">
        <BackTo />
        <HeaderIcon />

        <div className="page-privacy-container">
          <div className="page-privacy-image">
            <img
              src="/images/privacy/privacy.jpg"
              alt="My ConextionPrivacy"
              className="page-privacy-img"
            />
          </div>

          <div className="page-privacy-content">
            <div className="page-privacy-item">
              <h2>Privacy Policy</h2>
            </div>
            <div className="page-privacy-item">
              <p className="page-privacy-text">
                At MyConextion, accessible from https://myconextion.com/, one of
                our main priorities is the privacy of our visitors. This Privacy
                Policy document contains types of information that is collected
                and recorded by MyConextion and how we use it.
              </p>
              <p className="page-privacy-text">
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>
              <p className="page-privacy-text">
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in MyConextion. This
                policy is not applicable to any information collected offline or
                via channels other than this website. Our Privacy Policy was
                created with the help of the Free Privacy{" "}
                <a href="https://www.privacypolicygenerator.info/">
                  {" "}
                  Policy Generator.
                </a>
              </p>
            </div>
            <div className="page-privacy-item">
              <div className="page-privacy-title">CONSENT</div>
              <p className="page-privacy-text">
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
            </div>
            <div className="page-privacy-item">
              <div className="page-privacy-title">INFORMATION WE COLLECT</div>
              <p className="page-privacy-text">
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information.
              </p>
              <p className="page-privacy-text">
                If you contact us directly, we may receive additional
                information about you such as your name, email address, phone
                number, the contents of the message and/or attachments you may
                send us, and any other information you may choose to provide.
              </p>
              <p className="page-privacy-text">
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name,
                address, email address, and telephone number.
              </p>
            </div>
            <div className="page-privacy-item">
              <div className="page-privacy-title">
                HOW WE USE YOUR INFORMATION
              </div>
              <p className="page-privacy-text">
                We use the information we collect in various ways, including to:
              </p>
              <p className="page-privacy-text">
                If you contact us directly, we may receive additional
                information about you such as your name, email address, phone
                number, the contents of the message and/or attachments you may
                send us, and any other information you may choose to provide.
              </p>
              <ul className="page-privacy-list">
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>
                  Develop new products, services, features, and functionality
                </li>
                <li>
                  Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the website, and for
                  marketing and promotional purposes
                </li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
              </ul>
            </div>
            <div className="page-privacy-item">
              <div className="page-privacy-title">LOG FILES</div>
              <p className="page-privacy-text">
                MyConextion follows a standard procedure of using log files.
                These files log visitors when they visit websites. All hosting
                companies do this and a part of hosting services’ analytics. The
                information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users’
                movement on the website, and gathering demographic information.
              </p>
            </div>
            <div className="page-privacy-item">
              <div className="page-privacy-title">COOKIES AND WEB BEACONS</div>
              <p className="page-privacy-text">
                Like any other website, MyConextion uses ‘cookies’. These
                cookies are used to store information including visitors’
                preferences, and the pages on the website that the visitor
                accessed or visited. The information is used to optimize the
                users’ experience by customizing our web page content based on
                visitors’ browser type and/or other information.
              </p>
              <p className="page-privacy-text">
                For more general information on cookies, please read
                <a href="https://www.cookieconsent.com/">
                  more on the Cookie Consent website.
                </a>
              </p>
            </div>

            <div className="page-privacy-item">
              <div className="page-privacy-title">
                ADVERTISING PARTNERS PRIVACY POLICIES
              </div>
              <p className="page-privacy-text">
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of MyConextion.
              </p>
              <p className="page-privacy-text">
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on MyConextion,
                which are sent directly to users’ browser. They automatically
                receive your IP address when this occurs. These technologies are
                used to measure the effectiveness of their advertising campaigns
                and/or to personalize the advertising content that you see on
                websites that you visit.
              </p>
              <p className="page-privacy-text">
                Note that MyConextion has no access to or control over these
                cookies that are used by third-party advertisers.
              </p>
            </div>

            <div className="page-privacy-item">
              <div className="page-privacy-title">
                CCPA PRIVACY RIGHTS (DO NOT SELL MY PERSONAL INFORMATION)
              </div>
              <p className="page-privacy-text">
                Under the CCPA, among other rights, California consumers have
                the right to:
              </p>
              <p className="page-privacy-text">
                Request that a business that collects a consumer’s personal data
                disclose the categories and specific pieces of personal data
                that a business has collected about consumers.
              </p>
              <p className="page-privacy-text">
                Request that a business delete any personal data about the
                consumer that a business has collected.
              </p>
              <p className="page-privacy-text">
                Request that a business that sells a consumer’s personal data,
                not sell the consumer’s personal data.
              </p>
              <p className="page-privacy-text">
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
            </div>
            <div className="page-privacy-item">
              <div className="page-privacy-title">
                GDPR DATA PROTECTION RIGHTS
              </div>
              <p className="page-privacy-text">
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>
              <p className="page-privacy-text">
                The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </p>
              <p className="page-privacy-text">
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </p>
              <p className="page-privacy-text">
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </p>
              <p className="page-privacy-text">
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </p>
              <p className="page-privacy-text">
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
            </div>
            <div className="page-privacy-item">
              <div className="page-privacy-title">CHILDREN’S INFORMATION</div>
              <p className="page-privacy-text">
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </p>
              <p className="page-privacy-text">
                MyConextion does not knowingly collect any Personal Identifiable
                Information from children under the age of 13. If you think that
                your child provided this kind of information on our website, we
                strongly encourage you to contact us immediately and we will do
                our best efforts to promptly remove such information from our
                records.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
