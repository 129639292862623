/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { cameraController } from "../../../../three/components/Scene/ScriptThree";
import { gsap } from "gsap";
// data
import { goToServicesInfo } from "../../../../data/goToServicesInfo";
// redux
import { RootState } from "../../../../store/MainStore";
import { setContentInfo } from "../../../../store/slices/contentInfo";
import { setCharacterSlider } from "../../../../store/slices/sliderCharacter";
import { setCharacterPopup } from "../../../../store/slices/characterCardPopup";
import { useDispatch, useSelector } from "react-redux";
// translation
import labelsES from "../../../../data/labelsES";
import labelsEN from "../../../../data/labelsEN";
// style
import "./styles.css";
// images
import bgMenuServices from "./images/background-desktop.png";
import { SocialIcons } from "../../../global/socialIcons/general/SocialIcons";
// interfaces
interface SendInfoUI {
  scene: string;
  isOpen: boolean;
  getInfoTo: string;
  timing: number;
}
// export
export const NavbarService = () => {
  const [menuMobile, setMenuMobile] = useState(false);
  const tl = gsap.timeline();
  // get language from explorer

  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );

  // translation
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.navbarService;
      case "en":
        return labelsEN.navbarService;
      default:
        return labelsES.navbarService;
    }
  };

  // dispatch
  const timeOutSlider = () => {
    const time = setTimeout(() => {
      dispatch(setCharacterSlider(true));
    }, 8000);
    return time;
  };

  const dispatch = useDispatch();
  const cameraHandler = (goToAction: SendInfoUI) => {
    clearInterval(timeOutSlider());
    dispatch(setCharacterSlider(false));
    const sliderInfo = {
      getInfoTo: goToAction.getInfoTo,
      isOpen: goToAction.isOpen,
      timing: goToAction.timing,
    };
    dispatch(setCharacterPopup(false));
    dispatch(setContentInfo(sliderInfo));
    cameraController(goToAction.scene);
    menuState(false)
    timeOutSlider();
  };

  const openMenuService = () => {
    tl.to(".btnMenuBottomGSAP", {
      autoAlpha: 1,
      ease: "power1.out",
      duration: 0.35,
      y: 0,
    });
  };

  const closeMenuService = () => {
    tl.to(".btnMenuBottomGSAP", {
      autoAlpha: 0,
      duration: 0.35,
      ease: "power1.in",
      y: 200,
    });
  };

  const menuState = (menuState: boolean) => {
    if (!menuState) {
      closeMenuService();
      setMenuMobile(false);
    } else {
      openMenuService();
      setMenuMobile(true);
    }
  };

  useEffect(() => {
    gsap.set(".btnMenuBottomGSAP", {
      alpha: 0,
      autoAlpha: 0,
      y: 200,
    });
  }, []);

  // return
  return (
    <>
    <div className="bg-menu btnMenuBottomGSAP">
      <div className="menu-services-container">
        <div className="menu-services-bg">
          <img src={bgMenuServices} className="img-bg-services" alt="My Conextion Menu"/>
        </div>
        <div className="menu-service-content">
          <div className="socials-services-container">
            <SocialIcons />
            <p className="myConexctionLink">@myconextion</p>
          </div>
          <div className="menu-services-options">
            <div className="menu-service-row">
              <div className="menu-services-item">
                <a
                  id="nav-life"
                  className="menu__item"
                  onClick={() => cameraHandler(goToServicesInfo.life)}
                >
                  <span className="menu__item-name">{translate().life}</span>
                </a>
                <a
                  id="nav-car"
                  className="menu__item"
                  onClick={() => cameraHandler(goToServicesInfo.car)}
                >
                  <span className="menu__item-name">{translate().car}</span>
                </a>
                <a
                  id="nav-home"
                  className="menu__item"
                  onClick={() => cameraHandler(goToServicesInfo.home)}
                >
                  <span className="menu__item-name">{translate().home}</span>
                </a>
              </div>
              <div className="menu-services-item">
                <a
                  id="nav-business"
                  className="menu__item"
                  onClick={() => cameraHandler(goToServicesInfo.commercial)}
                >
                  <span className="menu__item-name">{translate().business}</span>
                </a>
                <a
                  id="nav-rent"
                  className="menu__item"
                  onClick={() => cameraHandler(goToServicesInfo.rent)}
                >
                  <span className="menu__item-name">{translate().rent}</span>
                </a>
                <a
                  id="nav-accidents"
                  className="menu__item"
                  onClick={() => cameraHandler(goToServicesInfo.accidents)}
                >
                  <span className="menu__item-name">{translate().accidents}</span>
                </a>
              </div>
              <div className="menu-services-item">
                <a
                  id="nav-cibersecurity"
                  className="menu__item"
                  onClick={() => cameraHandler(goToServicesInfo.cybersecurity)}
                >
                  <span className="menu__item-name">{translate().cybersecurity}</span>
                </a>
                <a
                  id="nav-motors"
                  className="menu__item"
                  onClick={() => cameraHandler(goToServicesInfo.motors)}
                >
                  <span className="menu__item-name">{translate().motors}</span>
                </a>
                <a
                  id="nav-pets"
                  className="menu__item"
                  onClick={() => cameraHandler(goToServicesInfo.pets)}
                >
                  <span className="menu__item-name">{translate().pets}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="btn-mobile-container">
        {!menuMobile ? (
          <div className="btn-menu-mobile-service" onClick={() => menuState(true)}>
            <span className="btn-mobile-service-txt">{translate().insurances}</span>
            <img className="btn-mobile-service-img" src="/images/icon.png" alt="My Conextion Menu" />
          </div>
        ) : (
          <div className="btn-menu-mobile-service" onClick={() => menuState(false)}>
            <span className="btn-mobile-service-txt">{translate().insurances}</span>
            <img className="btn-mobile-service-img" src="/images/icon-close.png" alt="My Conextion Menu" />
          </div>
        )}
      </div>
    </>
  );
};
