import { createSlice } from "@reduxjs/toolkit"

export interface CardCharacterPopup {
    characterCardOpen: boolean,
}

const initialState = {
    characterCardOpen: false
}


const cardCharacterPopup = createSlice({
    name: "goOpenCharacterPopup",
    initialState,
    reducers: {
        setCharacterPopup: (state: CardCharacterPopup, action: any) => {
            state.characterCardOpen = action.payload
        }
    }
})

export const { setCharacterPopup } = cardCharacterPopup.actions
export default cardCharacterPopup.reducer