// components
import { gsap } from "gsap";
import { useState } from "react";
// redux
import { RootState } from "../../store/MainStore";
import { useSelector } from "react-redux";
// style
import "./styles/styles.css";
// translation
import labelsES from "../../data/labelsES";
import labelsEN from "../../data/labelsEN";
// export
export const Cookies = () => {
  // translation
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.gpdr;
      case "en":
        return labelsEN.gpdr;
      default:
        return labelsES.gpdr;
    }
  };
  // data
  const [showCookies, setShowCookies] = useState(true);
  const loadLocalStorage = () => {
    // data
    const getLocalStorage = localStorage.getItem("acceptCookies");
    // check local storage
    if (getLocalStorage === "accept") {
      setShowCookies(true);
    } else {
      setShowCookies(false);
    }
  };
  const checkCookiesHandler = (cookiesStatus: boolean) => {
    if (cookiesStatus) {
      localStorage.setItem("acceptCookies", "accept");
      gsap.to(".cookiesGSAP", {
        duration: 2,
        y: -100,
        autoAlpha: 0,
        onComplete: () => setShowCookies(false),
      });
    } else {
      localStorage.setItem("acceptCookies", "decline");
      gsap.to(".cookiesGSAP", {
        duration: 2,
        y: -100,
        autoAlpha: 0,
        onComplete: () => setShowCookies(false),
      });
    }
  };
  useState(() => {
    loadLocalStorage();
  });
  // return
  return (
    <>
      {!showCookies && (
        <div className="cookiesGSAP">
          <div className="cookies">
            <div className="cookies-container">
              <div className="cookies-info">
                <p className="cookies-info-text">
                  <strong>{translate().title}</strong>
                </p>
                <p className="cookies-info-text">
                  {translate().msg}
                </p>
              </div>
              <div className="cookies-buttons">
                <button type="button" className="cookies-btn decline" onClick={() => checkCookiesHandler(false)}>
                  {translate().decline}
                </button>
                <button type="button" className="cookies-btn accept" onClick={() => checkCookiesHandler(true)}>
                  {translate().accept}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
