import './styles/styles.css';
// Images
import IconBack from './images/icon-back-white.png';
import { Link } from 'react-router-dom';
export const BackHome = () => {
  return (
    <div className='container-back-home'>
        <Link to={'/'}>
          <img className='img-back' src={IconBack} alt="icon gift" />
        </Link>
    </div>
  )
}
