import { useState } from "react";


const musicRef = '/sound/music.mp3'
const music = new Audio(musicRef)


export const useSound = () => {
    const [playMusic, setPlayMusic] = useState(false);
    const handlerPlay = () => {
        music.play()
        music.volume = 0.04;
        setPlayMusic(true)
    }
    const handlerStop = () => {
        setPlayMusic(false)
        music.pause()
    }

    return {
        handlerPlay,
        handlerStop,
        playMusic,

    }
}
