import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/MainStore";
import { setCurrentLanguage } from "../../../../store/slices/language";

import "./styles/styles.css";

export const LanguageButton = () => {
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );

  const dispatch = useDispatch();

  const handlerLanguageES = () => {
    dispatch(setCurrentLanguage("es"));
  };

  const handlerLanguageEN = () => {
    dispatch(setCurrentLanguage("en"));
  };

  return (
    <div className="language">
      <div className="language-btn">
        {currentLang === "en" && (
          <div className="language-btn-image" onClick={handlerLanguageES}>
            {/* <img
              src="/images/flags/en.jpg"
              className="language-icon"
              alt="my Conextion"
            /> */}
            <p className="lang-text">EN</p>
          </div>
        )}

        {currentLang === "es" && (
          <div className="language-btn-image" onClick={handlerLanguageEN}>
            {/* <img
              src="/images/flags/es.jpg"
              className="language-icon"
              alt="my Conextion"
            /> */}
            <p className="lang-text">ES</p>
          </div>
        )}
      </div>
    </div>
  );
};
