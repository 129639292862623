// imports
import { useEffect, useState } from "react";
import { RootState } from "../../../../store/MainStore";
// images
import IconContact from "./images/icon-contact.png";
import IconCalendar from "./images/icon-calendar.png";
import IconChat from "./images/icon-chat.png";
import IconEmail from "./images/icon-email.png";
import IconCall from "./images/icon-call.png";
import IconVideoCall from "./images/icon-videocall.png";
import IconClose from "./images/icon-close.png";
import { gsap } from "gsap";
import { mixPanel } from "../../../../helpers/track/mixPanel";
// style
import "./styles.css";
// translation
import labelsES from "../../../../data/labelsES";
import labelsEN from "../../../../data/labelsEN";
import { useSelector } from "react-redux";

const displayStyles = {
  btnShow: {
    display: "block",
  },

  btnHide: {
    display: "none",
  },
};

interface TrackSupportButtonsUI {
  getType: string;
  getUrl: string;
  getTrack: string;
}

// export
export const NavbarSupport = () => {


  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );


  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.navbarSupport;
      case "en":
        return labelsEN.navbarSupport;
      default:
        return labelsES.navbarSupport;
    }
  };

  // translate
  const contactTypeTranslate = (contactType: any) => {
    let cType = null;
    switch (currentLang) {
      case "es":
        switch (contactType) {
          case "schedule":
            cType = translate().schedule;
            break;
          case "talk":
            cType = translate().talk;
            break;
          case "email":
            cType = translate().email;
            break;
          case "call":
            cType = translate().call;
            break;
          case "videocall":
            cType = translate().videocall;
            break;
        }
        break;
      case "en":
        switch (contactType) {
          case "schedule":
            cType = translate().schedule;
            break;
          case "talk":
            cType = translate().talk;
            break;
          case "email":
            cType = translate().email;
            break;
          case "call":
            cType = translate().call;
            break;
          case "videocall":
            cType = translate().videocall;
            break;
        }
        break;
    }
    return cType;
  };

  // set contact buttons
  const contactBtns = [
    [IconCalendar, "schedule", "https://calendly.com/myconextion", ""],
    [IconChat, "talk", "https://m.me/myconextion?ref=w17484331", ""],
    [IconEmail, "email", "mailto:info@myconextion.com", ""],
    [IconCall, "call", "tel:+13213878446", ""],
    [IconVideoCall, "videocall", "", ""],
  ];

  useEffect(() => {
    gsap.set(".btnHelpGSAP", {
      duration: 2,
      y: 235,
    });
  }, []);

  // set variables
  const [isOpen, setIsOpen] = useState(false);
  const handleSetIsOpen = () => {
    if (!isOpen) {
      gsap.to(".btnHelpGSAP", {
        y: 77,
      });
      setIsOpen(true);
    } else {
      gsap.to(".btnHelpGSAP", {
        y: 235,
      });
      setIsOpen(false);
    }
  };

  const trackSupporBtn = (info: TrackSupportButtonsUI) => {
    switch (info.getType) {
      case "schedule":
        window.open(info.getUrl);
        break;
      case "talk":
        window.open(info.getUrl);
        break;
      case "email":
        window.open(info.getUrl);
        break;
      case "call":
        window.open(info.getUrl);
        break;
      case "videocall":
        break;
      default:
        break;
    }

    mixPanel(info);
  };

  // return
  return (
    <div className="circular-menu">
      <div className={isOpen ? "btn-menu-help btn-menu-help-open btnHelpGSAP" : "btn-menu-help btnHelpGSAP"} onClick={handleSetIsOpen}>
        <img
          className="icon-contact"
          src={isOpen ? IconClose : IconContact}
          alt="icon contact"
        />
      </div>
      <div className={isOpen ? "menu-button-circle" : "circle-hidden"}></div>
      <div
        className="menu-button"
        onClick={handleSetIsOpen}
        style={isOpen ? displayStyles.btnShow : displayStyles.btnHide}
      >
        <div className="menu-item">
          <div className="initial-btn"></div>
        </div>
        {contactBtns.map(
          ([contactBtn, contactType, getURL, getTrack], index) => (
            <div
              key={contactBtn}
              className={
                isOpen
                  ? "menu-item menu-open menu-item-" + (index + 1)
                  : "menu-item menu-close menu-item-" + (index + 1)
              }
            >
              <div
                className="menu-item-btn"
                onClick={() =>
                  trackSupporBtn({
                    getType: contactType,
                    getUrl: getURL,
                    getTrack: getTrack,
                  })
                }
              >
                <img src={contactBtn} alt="icon" />
                <div className="menu-item-description">
                  {contactTypeTranslate(contactType)}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
