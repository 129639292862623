// imports
import { Link } from 'react-router-dom';
// images
import IconGift from './images/icon-gift.png';
// style
import './styles.css';
// export
export const GiftNavbar = () => {
  return (
    <div className='container-gift'>
      <Link to={'/gifts'}>
        <img className='img-gift' src={IconGift} alt="icon gift" />
      </Link>
    </div>
  )
}

