
// Redux
import { RootState } from "../../../store/MainStore";
import { useSelector } from "react-redux";
// Formik and Yup
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// sweet alert
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// translation
import labelsES from "../../../data/labelsES";
import labelsEN from "../../../data/labelsEN";
// style
import "./styles/styles.css";
import axios from "axios";
// interfaces
interface ClientInfoUI {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  msg: string;
}

// export
export const SimpleForm = () => {
  // translation
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.contact;
      case "en":
        return labelsEN.contact;
      default:
        return labelsES.contact;
    }
  };
  // alert
  const MySwal = withReactContent(Swal)
  // data
 
  // form submit
  const handlerSubmit = (clientInfo: ClientInfoUI) => {
    // setOpenEmail(true);
    // set email data
    const emailData: any = {
      emailId: "64db94b505d3cde930809a12",
      userId: "6304616a7709322e0cbf5c47",
      emailDestination: clientInfo.email,
      subject: translate().subject,
      data: {
        title: translate().subject + ' ' + clientInfo.firstName,
        msg: translate().contactSoon,
      },
    };
    // send email
    axios
      .post("https://email.myconextion.com/email/send", emailData)
      .then(function (response) {
        // handle success
        if (response.status === 201) {
          setTimeout(() => {
            // setOpenEmail(false);
          }, 2000);
        }
      });
    // set owner message
    let msgOwner = "";
    msgOwner = msgOwner + "<p>" + translate().contactSoonOwner + "</p>";
    msgOwner = msgOwner + "<p>" + translate().contactSoonDataOwner + "</p>";
    msgOwner = msgOwner + "<br/>";
    msgOwner = msgOwner + "<ul>";
    msgOwner = msgOwner + "<li><strong>" + translate().form.email.label + "</strong>:" + clientInfo.email + "</li>";
    msgOwner = msgOwner + "<li><strong>" + translate().form.cellphone.label + "</strong>:" + clientInfo.phone + "</li>";
    msgOwner = msgOwner + "<li><strong>" + translate().form.names.label + "</strong>:" + clientInfo.firstName + "</li>";
    msgOwner = msgOwner + "<li><strong>" + translate().form.lastNames.label + "</strong>:" + clientInfo.lastName + "</li>";
    msgOwner = msgOwner + "</ul>";
    msgOwner = msgOwner + "<p>" + translate().contactSoonMsgOwner + "</p>";
    msgOwner = msgOwner + "<p>" + clientInfo.msg + "</p>";
    // set owner email data
    const emailOwnerData: any = {
      emailId: "64db962a05d3cde930809a15",
      userId: "6304616a7709322e0cbf5c47",
      emailDestination: "info@myconextion.com",
      subject: translate().subjectOwner,
      data: {
        title: translate().subjectOwner,
        msg: msgOwner,
      },
    };
    // send email
    axios
      .post("https://email.myconextion.com/email/send", emailOwnerData)
      .then(function (response) {
        // handle success
        if (response.status === 201) {
          setTimeout(() => {
            // setOpenEmail(false);
            MySwal.fire(
              translate().contactThankYouTitle,
              translate().contactThankYouMsg,
              'success',
            ).then(() => {
              return
            });
          }, 2000);
        }
      });
  };
  // return
  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          msg: "",
        }}
        onSubmit={(values, { resetForm }) => {
          const clientInfo = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            msg: values.msg,
          };
          handlerSubmit(clientInfo);
          resetForm();
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(translate().form.email.error)
            .required(translate().form.email.error),
          phone: Yup.number()
            .min(8, translate().form.cellphone.error)
            .required(translate().form.cellphone.error),
          firstName: Yup.string()
            .min(3, translate().form.names.error)
            .required(translate().form.names.help)
            .matches(/^[a-zA-Z\u00C0-\u017F]+$/, translate().form.names.error),
          lastName: Yup.string()
            .min(3, translate().form.lastNames.error)
            .required(translate().form.lastNames.help)
            .matches(
              /^[a-zA-Z\u00C0-\u017F]+$/,
              translate().form.lastNames.error
            ),
          msg: Yup.string().required(translate().form.msg.help),
        })}
      >
        <Form className="form-container" noValidate>
          {/* data */}
          <div className="row">
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
              <div className="form-item">
                <label className="form-input-label secondary-text">
                  {translate().form.email.label}
                </label>
                <Field
                  name="email"
                  type="email"
                  placeholder={translate().form.email.placeholder}
                  className="form-input"
                />
                <ErrorMessage
                  name="email"
                  component="span"
                  className="form-text text-danger"
                />
              </div>
            </div>
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
              <div className="form-item">
                <label className="form-input-label secondary-text">
                  {translate().form.cellphone.label}
                </label>
                <Field
                  name="phone"
                  type="tel"
                  placeholder={translate().form.cellphone.placeholder}
                  className="form-input"
                />
                <ErrorMessage
                  name="phone"
                  component="span"
                  className="form-text text-danger"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
              <div className="form-item">
                <label className="form-input-label secondary-text">
                  {translate().form.names.label}
                </label>
                <Field
                  name="firstName"
                  type="text"
                  placeholder={translate().form.names.placeholder}
                  className="form-input"
                />
                <ErrorMessage
                  name="firstName"
                  component="span"
                  className="form-text text-danger"
                />
              </div>
            </div>
            <div className="col-col-6 col-xxxl-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12">
              <div className="form-item">
                <label className="form-input-label secondary-text">
                  {translate().form.lastNames.label}
                </label>
                <Field
                  name="lastName"
                  type="text"
                  placeholder={translate().form.lastNames.placeholder}
                  className="form-input"
                />
                <ErrorMessage
                  name="lastName"
                  component="span"
                  className="form-text text-danger"
                />
              </div>
            </div>
          </div>
          {/* msg */}
          <div className="row">
            <div className="col-col-12 col-xxxl-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
              <div className="form-item form-item-txt">
                <label className="form-input-label secondary-text">
                  {translate().form.msg.label}
                </label>
                <Field
                  name="msg"
                  as="textarea"
                  placeholder={translate().form.msg.placeholder}
                  className="form-input"
                />
                <ErrorMessage
                  name="msg"
                  component="span"
                  className="form-text text-danger"
                />
              </div>
            </div>
          </div>
          {/* button */}
          <div className="row">
            <div className="col-col-12 col-xxxl-12 col-xxl-12 col-xl-12 col-lg-12 col-ipro-12 col-r-12 col-s-12 col-sm-12 col-sx-12 col-sxs-12">
              <div className="form-item-button">
                <button type="submit" className="btn btn-custom btn-main">
                  {translate().form.send}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};
