// style
import "./styles.css";
// interface
interface CharacterInfoUI {
  characterInfo: {
    id: string;
    characterName: string;
    characterBio: string;
    characterImg: string;
  };
}
// export
export const CharacterModal = (characterInfo: CharacterInfoUI) => {
  // data
  const selectedCharacter = characterInfo.characterInfo;
  // return
  return (
    <div className="row character-modal-row">
      <div className="col-col-8 col-xxxl-8 col-xxl-8 col-xl-8 col-lg-8 col-ipro-8 col-r-8 col-s-8 col-sm-8 col-sx-12 col-sxs-12">
        <p className="caracter-modal-info">{selectedCharacter.characterBio}</p>
      </div>
      <div className="col-col-4 col-xxxl-4 col-xxl-4 col-xl-4 col-lg-4 col-ipro-4 col-r-4 col-s-4 col-sm-4 col-sx-12 col-sxs-12">
        <div className="character-modal-picture-container">
          <img className="character-modal-picture" src={selectedCharacter.characterImg} alt={selectedCharacter.characterName}/>
        </div>
      </div>
    </div>
  );
};
