const charactersES = [
  {
    id: 'frank',
    characterName: 'Frank',
    characterAnimation: '',
    characterBio: 'Frank nació en Idaho Falls y es un abuelo de 67 años piloto de helicóptero jubilado, veterano de la guerra de Vietnam y padre de Kevin. Frank quiere dejar un buen legado a su hijo y a su nueva familia.',
    characterImg: '/images/characters/williams-frank-hi.png',
    characterImgBio: '/images/characters/williams-frank-bio.png',
  },
  {
    id: 'abigail',
    characterName: 'Abigail',
    characterAnimation: '',
    characterBio: 'Nacida en Nueva York y con decendencia Italiana, Abigail es una abuela de 64 años encantadora y a quien le encanta cocinar para toda la familia las recetas que le enseñó su madre. Ella quiere asegurar el futuro de sus nietos .',
    characterImg: '/images/characters/williams-abigail-hi.png',
    characterImgBio: '/images/characters/williams-abigail-bio.png',
  },
  {
    id: 'kevin',
    characterName: 'Kevin',
    characterAnimation: '',
    characterBio: 'Kevin nació en Baltimore y creció en Nueva York. Se convirtió en un empresario dueño de un concesionario y en unas vacaciones a Colombia se enamoró de Sarah. A Kevin con 42 años le fascinan los autos, pasar tiempo con su esposa y sus hijos.',
    characterImg: '/images/characters/williams-kevin-hi.png',
    characterImgBio: '/images/characters/williams-kevin-bio.png',
  },
  {
    id: 'sarah',
    characterName: 'Sarah',
    characterAnimation: '',
    characterBio: 'Sarah nació en Cali Colombia, conoció a Kevin en una de sus vacaciones y se enamoraron al instante. Migro a Estados Unidos, ahora dirige una agencia de bienes raíces, comparte su tiempo entre su familia y la comunidad de mujeres empresarias en Nueva York.',
    characterImg: '/images/characters/williams-sarah-hi.png',
    characterImgBio: '/images/characters/williams-sarah-bio.png',
  },
  {
    id: 'gabriel',
    characterName: 'Gabriel',
    characterAnimation: '',
    characterBio: 'Gabriel es un gamer nato de 20 años, divide su tiempo entre estudiar desarrollo de software y hacer streaming de sus batallas en Call of Duty. Gabriel tiene una comunidad amplia de seguidores, promociona artículos gamer y NFT a través de su página web.',
    characterImg: '/images/characters/williams-gabriel-hi.png',
    characterImgBio: '/images/characters/williams-gabriel-bio.png',
  },
  {
    id: 'sofia',
    characterName: 'Sofia',
    characterAnimation: '',
    characterBio: 'Sofia tiene 25 años, y actualmente estudia artes plásticas en School of Visual Arts de Nueva York. Se considera una persona no binaria, busca como cuidar del planeta y conservar el medio ambiente a como de lugar.',
    characterImg: '/images/characters/williams-sofia-hi.png',
    characterImgBio: '/images/characters/williams-sofia-bio.png',
  },
  {
    id: 'chris',
    characterName: 'Chris',
    characterAnimation: '',
    characterBio: 'Chris es un pequeño travieso de 10 años y capitán del equipo de baloncesto de su colegio. Chris se mueve en bicicleta para ir a la escuela, las prácticas, a los partidos y a cualquier lugar con sus amigos y su perro Max.',
    characterImg: '/images/characters/williams-chris-hi.png',
    characterImgBio: '/images/characters/williams-chris-bio.png',
  },
  {
    id: 'max',
    characterName: 'Max',
    characterAnimation: '',
    characterBio: 'Este peludito lleva con la familia 3 años y es el consentido de todos en la familia. Siempre quiere que le tiren la pelota y arranca con ansias a buscarla.',
    characterImg: '/images/characters/williams-max-hi.png',
    characterImgBio: '/images/characters/williams-max-bio.png',
  },
  {
    id: 'sebastian',
    characterName: 'Sebastian',
    characterAnimation: '',
    characterBio: 'Sebastian nació en Nueva Jersey, tiene 29 años de edad y descendencia de padres Latinos que migraron a los Estados Unidos en la década de los 80, Sebastian es un conductor de taxi y un apasionado por el ejercicio',
    characterImg: '/images/characters/garcias-sebastian-hi.png',
    characterImgBio: '/images/characters/garcias-sebastian-bio.png',
  },
  {
    id: 'andrea',
    characterName: 'Andrea',
    characterAnimation: '',
    characterBio: 'Andrea con 25 años nació en Phoenix y es de descendencia mejicana. Andrea es la encargada de las finanzas del hogar y quien paga el arrendamiento de la casa a Sarah Williams',
    characterImg: '/images/characters/garcias-andrea-hi.png',
    characterImgBio: '/images/characters/garcias-andrea-bio.png',
  },
  {
    id: 'bella',
    characterName: 'Bella',
    characterAnimation: '',
    characterBio: 'Este peludito lleva con la familia 3 años y es el consentido de todos en la familia. Siempre quiere que le tiren la pelota y arranca con ansias a buscarla.',
    characterImg: '/images/characters/garcias-bella-hi.png',
    characterImgBio: '/images/characters/garcias-bella-bio.png',
  },
]
export default charactersES;