const socialLinks = [
  {
    name: "Facebok",
    link: "https://www.facebook.com/myconextion/",
    icon: "/images/socialicons/icon-fb.png",
    size: 40,
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/myconextion",
    icon: "/images/socialicons/icon-ig.png",
    size: 40,
  },

  {
    name: "Linkedin",
    link: "https://www.linkedin.com/company/my-conextion/",
    icon: "/images/socialicons/icon-in.png",
    size: 40,
  },
];

export const SocialIcons = () => {
  return (
    <div className="img-socials">
      { socialLinks.map(( socialRed, index) => (
        <a href={socialRed.link} key={index} target="blank">
          <img className="icons-img" src={socialRed.icon} alt="icon" />
        </a>
      ))}
    </div>
  );
};
