import { useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/MainStore";
import { setContentInfo } from "../../../../../store/slices/contentInfo";
import { setCharacterSlider } from "../../../../../store/slices/sliderCharacter";
import { setCharacterPopup } from "../../../../../store/slices/characterCardPopup";

/* eslint-disable jsx-a11y/anchor-is-valid */
import { cameraController } from "../../../../../three/components/Scene/ScriptThree";
import { gsap } from "gsap";
// data
import { goToServicesInfo } from "../../../../../data/goToServicesInfo";

// translation
import labelsES from "../../../../../data/labelsES";
import labelsEN from "../../../../../data/labelsEN";

import { SocialIcons } from "../../../../global/socialIcons/general/SocialIcons";
import "./styles.css";
import { Version } from "../../../../global/version/Version";
// interfaces
interface SendInfoUI {
  scene: string;
  isOpen: boolean;
  getInfoTo: string;
  timing: number;
}

// export
export const NavbarServicesMobile = () => {
  const [menuMobile, setMenuMobile] = useState(false);
  // get language from explorer
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );
  // translation
  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.navbarService;
      case "en":
        return labelsEN.navbarService;
      default:
        return labelsES.navbarService;
    }
  };

  const tl = gsap.timeline();

  useEffect(() => {
    gsap.set(".btnMenuMobileGSAP", {
      alpha: 0,
      autoAlpha: 0,
      y: -500,
    });
  }, []);

  // dispatch

  const timeOutSlider = () => {
    const time = setTimeout(() => {
      dispatch(setCharacterSlider(true));
    }, 8000);
    return time;
  };

  const dispatch = useDispatch();
  const cameraHandler = (goToAction: SendInfoUI) => {
    menuState(false);
    clearInterval(timeOutSlider());
    dispatch(setCharacterSlider(false));
    const sliderInfo = {
      getInfoTo: goToAction.getInfoTo,
      isOpen: goToAction.isOpen,
      timing: goToAction.timing,
    };
    dispatch(setCharacterPopup(false));
    dispatch(setContentInfo(sliderInfo));
    cameraController(goToAction.scene);
    timeOutSlider();
  };

  const openMenuService = () => {
    tl.to(".btnMenuMobileGSAP", {
      autoAlpha: 1,
      ease: "power1.out",
      duration: 0.35,
      y: 0,
    });
  };

  const closeMenuService = () => {
    tl.to(".btnMenuMobileGSAP", {
      autoAlpha: 0,
      duration: 0.35,
      ease: "power1.in",
      y: -300,
    });
  };

  const menuState = (menuState: boolean) => {
    if (!menuState) {
      closeMenuService();
      setMenuMobile(false);
    } else {
      openMenuService();
      setMenuMobile(true);
    }
  };

  // return
  return (
    <>
      <div className="btnMenuMobileGSAP menu-mob-container">
        <div className="menu-mob-content">
          {/* menu */}
          <div className="menu-mob-options">
            <div className="menu-mob-row">
              <div className="menu-mob-item">
                <a
                  className="mobile-link"
                  id="nav-life"
                  onClick={() => cameraHandler(goToServicesInfo.life)}
                >
                  <span className="btn-mobile-menu secondary-text">
                    {translate().life}
                  </span>
                </a>
                <a
                  className="mobile-link"
                  id="nav-car"
                  onClick={() => cameraHandler(goToServicesInfo.car)}
                >
                  <span className="btn-mobile-menu secondary-text">
                    {translate().car}
                  </span>
                </a>
                <a
                  className="mobile-link"
                  id="nav-home"
                  onClick={() => cameraHandler(goToServicesInfo.home)}
                >
                  <span className="btn-mobile-menu secondary-text">
                    {translate().home}
                  </span>
                </a>
              </div>

              <div className="menu-mob-item">
                <a
                  className="mobile-link"
                  id="nav-business"
                  onClick={() => cameraHandler(goToServicesInfo.commercial)}
                >
                  <span className="btn-mobile-menu secondary-text">
                    {translate().business}
                  </span>
                </a>
                <a
                  className="mobile-link"
                  id="nav-rent"
                  onClick={() => cameraHandler(goToServicesInfo.rent)}
                >
                  <span className="btn-mobile-menu secondary-text">
                    {translate().rent}
                  </span>
                </a>
                <a
                  className="mobile-link"
                  id="nav-accidents"
                  onClick={() => cameraHandler(goToServicesInfo.accidents)}
                >
                  <span className="btn-mobile-menu secondary-text">
                    {translate().accidents}
                  </span>
                </a>
              </div>

              <div className="menu-mob-item">
                <a
                  className="mobile-link"
                  id="nav-cibersecurity"
                  onClick={() => cameraHandler(goToServicesInfo.cybersecurity)}
                >
                  <span className="btn-mobile-menu secondary-text">
                    {translate().cybersecurity}
                  </span>
                </a>
                <a
                  className="mobile-link"
                  id="nav-motors"
                  onClick={() => cameraHandler(goToServicesInfo.motors)}
                >
                  <span className="btn-mobile-menu secondary-text">
                    {translate().motors}
                  </span>
                </a>
                <a
                  className="mobile-link"
                  id="nav-pets"
                  onClick={() => cameraHandler(goToServicesInfo.pets)}
                >
                  <span className="btn-mobile-menu secondary-text">
                    {translate().pets}
                  </span>
                </a>
              </div>
            </div>
          </div>
          {/* socials */}
          <div className="socials-mobile-services-container">
            <div className="img-mobile-socials">
              <SocialIcons />
              <p>@myconextion</p>
            </div>
          </div>
          {/* developed by */}
          <div className="developed-by">
            <p>
              © Copyright 2022. All Rights Reserved. <br />
              Designed with love by{" "}
              <a
                className="think-link"
                href="https://think-in.co/"
                target="_blank"
                rel="norel nofollow noopener noreferrer"
              >
                Think In
              </a>
            </p>
          </div>
          {/* version */}
          <div className="version-menu">
            <Version />
          </div>
        </div>
      </div>
      <div className="btn-mobile-container">
        {!menuMobile ? (
          <div
            className="btn-menu-mob-service"
            onClick={() => menuState(true)}
          >
            <div className="btn-mobile-service-txt">
              {translate().insurances}
            </div>
            <img
            width={35}
            height={35}
              className="btn-mobile-service-img"
              src="/images/icon.png"
              alt="My Conextion Menu"
            />
          </div>
        ) : (
          <div
            className="btn-menu-mob-service"
            onClick={() => menuState(false)}
          >
            <span className="btn-mobile-service-txt">
              {translate().insurances}
            </span>
            <img
                  width={35}
                  height={35}
              className="btn-mobile-service-img"
              src="/images/icon-close.png"
              alt="My Conextion Menu"
            />
          </div>
        )}
      </div>
    </>
  );
};
