// Redux
import { RootState } from "../../store/MainStore";
import { useSelector } from "react-redux";
//components
import { PageList } from "../../components/pageList/PageList";
//data
import labelsES from "../../data/labelsES";
import labelsEN from "../../data/labelsEN";

export const HowItWork = () => {
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );

  const translate = () => {
    switch (currentLang) {
      case "es":
        return labelsES.howItWork;
      case "en":
        return labelsEN.howItWork;

      default:
        return labelsES.howItWork;
    }
  };
  const info = {
    title: translate().title,
    info: translate().info,
    slogan: translate().slogan,
    image: translate().image,
    btnTitle: translate().btnTitle,
    callToAction: translate().callToAction,
  };

  return <PageList {...info} />;
};
