// imports
import { Link } from "react-router-dom";
// style
import "./styles/styles.css";
// translation
import labelsES from "../../data/labelsES";
import labelsEN from "../../data/labelsEN";
// export
export const Politics = () => {
  // get language from explorer
  const lang: string = "es";
  // translation
  const translate = () => {
    switch (lang) {
      case "es":
        return labelsES.form;
      case "en":
        return labelsEN.form;
      default:
        return labelsES.form;
    }
  };
  // return
  return (
    <>
      <p>
        <small>{translate().formPrivacy1} <Link to={'/terms-and-conditions'}>{translate().terms}</Link> {translate().formPrivacy2} <Link to={'/privacy'}>{translate().privacy}</Link></small>
      </p>
    </>
  );
};
