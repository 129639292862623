import "./styles/styles.css";

interface LoaderEmailUI {
  title: string;
  msg: string;
}

export const Email= ({
  title = "Title",
  msg = "Message",
}: LoaderEmailUI) => {
  return (
    <div className="pop-loader">
      <div className="pop-loader-container">
        <div className="pop-loader-title">{title}</div>

        <hr />

        <div className="pop-loader-message">{msg}</div>
      </div>
    </div>
  );
};
