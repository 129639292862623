const labelsES = {
  navbarMenu: {
    start: "inicio",
    services: "Seguros",
    life: "Vida",
    car: "Autos",
    home: "Casa",
    business: "Comercial",
    rent: "Arrendamiento",
    accidents: "Accidentes Personales",
    cybersecurity: "Ciberseguridad",
    motors: "Motoras",
    pets: "Mascotas",
    gifts: "Obsequios",
    realState: "Real Estate",
    financing: "Financiamiento",
    crypto: "Criptomonedas",
    about: "Acerca de",
    aboutUs: "Nosotros",
    aboutWilliams: "Los Williams",
    aboutGarcia: "Los García",
    help: "Ayuda",
    howItWorks: "Como Funciona",
    faqs: "FAQ'S",
    contact: "Contacto",
    game: "Gana Jugando",
  },
  navbarService: {
    title: "Encuentra el seguro que necesitas",
    titleMobile1: "Encuentra",
    titleMobile2: "el seguro que necesitas",
    life: "Vida",
    car: "Autos",
    home: "Casa",
    business: "Comercial",
    rent: "Arrendamiento",
    accidents: "Accidentes Personales",
    cybersecurity: "Ciberseguridad",
    motors: "Motoras",
    pets: "Mascotas",
    insurances: "Seguros",
  },
  navbarSupport: {
    schedule: "Agenda una cita",
    talk: "Habla con nosotros",
    email: "Envíanos un correo",
    call: "Llámanos",
    videocall: "Haz una videollamada",
  },
  serviceInfo: {
    hire: "Contratar Seguro",
    moreInfo: "Más Información",
    whoIs: "¿Quién es ",
    about: "Acerca ",
    off: "de ",
    backHome: "Volver al Inicio",
    whatsApp1: "Hola",
    whatsApp2: "quiero más información acerca de ",
  },
  form: {
    formPrivacy1: "Al ingresar la información acepta los",
    formPrivacy2: "como la",
    terms: "Términos y Condiciones",
    privacy: "Política de Privacidad",
  },
  crypto: {
    cryptoTitle: "Criptomonedas",
    cryptoSubtitle: "Descubre los movimientos y valores en las principales criptomonedas",
    loader:{
      title:'Cargando Información',
      info: "Estamos obteniendo la información de las principales criptomonedas..."
    },
  },
  realState: {
    realStateTitle: "Real Estate",
    realStateSchedule: "Agenda ahora una cita con nuestros asesores",
    realStateSelect: "Selecciona una Opción",
    realStateSelectMsg: "Selecciona primero que acción deseas realizar",
    realStateSellingTitle: "Venta",
    realStateSellingIntro: "Quiero vender una propiedad",
    realStateBuyingTitle: "Compra",
    realStateBuyingIntro: "Quiero comprar una propiedad",
    realStateSellingSubtitle: "Completa algunas preguntas y te ayudaremos a vender tu propiedad al mejor precio",
    realStateBuyingSubtitle: "Completa algunas preguntas y te conectaremos con la propiedad que estás buscando",
    realStateImageRealtor: "/images/real-state/realtor.webp",
    realStateImageBg: "/images/real-state/realtor-bg.webp",
    realStateRealtorMsg1: "Comprar o vender una propiedad puede ser un proceso estresante si no se cuenta con el agente inmobiliario adecuado.",
    realStateRealtorMsg2: "Con experiencia y soporte, puede confiar en nosotros para obtener el mejor resultado posible.",
    loader:{
      title:'Cargando Información',
      info: "Estamos obteniendo la información para ayudarte a vender o comprar tu propiedad"
    },
    form: {
      email: {
        label: "Correo Electrónico",
        placeholder: "correo@midominio.com",
        error: "Debes ingresar un correo electrónico",
        help: "Coloca un correo electrónico para poder redimir tu beneficio",
      },
      cellphone: {
        label: "Celular",
        placeholder: "1234567890",
        error: "Debes ingresar un número de celular válido",
        help: "Coloca un número de celular para poder contactarte en caso de ser necesario",
      },
      firstName: {
        label: "Nombre",
        placeholder: "Juan",
        error: "Debes ingresar tu nombre",
        help: "Agrega tu nombre para poder contactarte",
      },
      lastName: {
        label: "Apellido",
        placeholder: "López",
        error: "Debes ingresar tu apellido",
        help: "Agrega tu apellido para poder contactarte",
      },
      sellingTimePeriod: {
        label: "¿Qué tan pronto planeas vender?",
        labelASAP: "Lo más pronto posible",
        label12Months: "Entre 1 y 2 meses",
        label23Months: "Entre 2 y 3 meses",
        label3PlusMonths: "Más de 3 meses",
        placeholder: "Selecciona una Opción",
        error: "Debes seleccionar una opción",
        help: "Al conocer esta información podremos ayudarte con la propuesta de venta perfecta",
      },
      sellingReason: {
        label: "¿Cuál es la razón por la que vendes?",
        labelUpsizing: "Agrandar el Inmueble",
        labelDownsizing: "Reducir el Inmueble",
        labelLocation: "Ubicación",
        labelOther: "Otro",
        placeholder: "Selecciona una Razón",
        error: "Debes seleccionar la razón de la venta",
        help: "Esto nos permitirá crear una estrategia de venta apropiada para tu inmueble",
      },
      propertyLocation: "Ubicación de la Propiedad",
      propertyLocationState: {
        label: "Estado",
        placeholder: "Selecciona un Estado",
        error: "Debes seleccionar un estado",
        help: "Busca en la lista el estado donde se encuentra ubicada tu propiedad",
      },
      propertyLocationCity: {
        label: "Ciudad",
        placeholder: "Selecciona una Ciudad",
        selectStateFirst: "Selecciona un Estado primero",
        error: "Debes seleccionar una ciudad",
        help: "Busca en la lista la ciudad donde se encuentra ubicada tu propiedad. Si no te aparece nada en la lista, debes seleccionar primero el estado.",
      },
      propertyLocationAddress: {
        label: "Dirección",
        placeholder: "1234 Street",
        error: "Debes ingresar la dirección de la propiedad",
        help: "Ingresa la dirección donde se encuentra ubicada tu propiedad",
      },
      propertyLocationZIP: {
        label: "Código ZIP",
        placeholder: "000000",
        error: "Debes ingresar el código ZIP de tu propiedad",
        help: "Indica el código ZIP de donde se encuentra tu propiedad",
      },
      homeOwnerPackage: {
        label: "¿Deseas recibir un paquete para listar propiedades?",
        labelYes: "Sí",
        labelNo: "No",
        homeOwnerPackage: "Este usuario desea recibir el paquete de propietarios",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "El paquete para listar propiedades te permitirá conocer los tips para vender tu propiedad al mejor precio y lo antes posible",
      },
      typeOfProperty: {
        label: "Tipo de Compra",
        labelPrimary: "Principal",
        labelVacational: "Vacacional",
        labelInvestment: "Inversión",
        placeholder: "Selecciona un tipo",
        error: "Debes seleccionar un tipo",
        help: "Esto nos permitirá conocer el tipo de propiedad que estás buscando",
      },
      typeOfBuyer: {
        label: "Tipo de Comprador",
        labelLocal: "Local",
        labelInternational: "International",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "De esta forma podrémos preparar la documentación para la compra de la propiedad",
      },
      firstTime: {
        label: "¿Es la primera vez que compras?",
        labelAffirmation: "Es la primera vez que compra",
        labelYes: "Sí",
        labelNo: "No",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "Esto nos ayudará a brindarte información más concisa para primeros compradores",
      },
      valueExpectation: {
        label: "¿De cuánto es tu presupuesto?",
        labelBudget: "Presupuesto",
        label200Less: "$200K o menos",
        label200300: "Entre $200K y $300K",
        label300350: "Entre $300K y $350K",
        label350400: "Entre $350K y $400K",
        label400450: "Entre $400K y $450K",
        label450More: "Más de $450K",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "Así podremos encontrar la propiedad ideal en base a tu presupuesto",
      },
      homeStyle: {
        label: "¿Qué tipo de propiedad quieres?",
        labelType: "Tipo de Propiedad",
        labelSingleHome: "Casa Individual",
        labelTownhouse: "Casa Adosada",
        labelCondo: "Condo",
        labelApartment: "Apartamento",
        labelMultifamily: "Multifamiliar",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "Con ello encontraremos la propiedad perfecta para ti",
      },
      income: {
        label: "¿Cómo recibes tus ingresos?",
        labelReception: "Origen de Ingresos",
        labelW2: "W2",
        label1099: "N1099o",
        labelOther: "Otro",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "Esta información nos permitirá preparar la documentación adecuada",
      },
      creditRange: {
        label: "Rango de crédito estimado",
        labelRange: "Rango de Crédito",
        label559Less: "$559K o menos",
        label560599: "Entre $560K y $559K",
        label600639: "Entre $600K y $639K",
        label640679: "Entre $640K y $679K",
        label680More: "$680K o más",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "Con estos datos podremos conectarte con la entidad financiera que mejor se adapte",
      },
      downPayment: {
        label: "¿Cuánto tienes para el pago inicial?",
        labelInitialPayment: "Pago Inicial",
        label3500Less: "Menos de $3.5K",
        label35004000: "Entre $3.5K y $4K",
        label400010000: "Entre $4K y $10K",
        label1000015000: "Entre $10K y $15K",
        label1500020000: "Entre $15K y $20K",
        label2000025000: "Entre $20K y $25K",
        label25000More: "$25K o más",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "Esto nos permitirá negociar un buen trato con los dueños de la propiedad lo más pronto posible",
      },
      taxesPreparation: {
        label: "¿Tienes dos años de impuestos preparados?",
        labelPrepared: "Dos años de impuestos preparados",
        labelMsg: "Impuestos preparados por dos años",
        labelYes: "Sí",
        labelNo: "No",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "Es indispensable esta información para el proceso",
      },
      foundHome: {
        label: "¿Ya encontraste una propiedad que quieras comprar?",
        labelFound: "Ya tiene una propiedad para comprar",
        labelFoundMsg: "Propiedad para comprar",
        labelYes: "Sí",
        labelNo: "No",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "De esta forma podremos ayudarte con la negociación",
      },
      realStateAdvisory: {
        label: "¿Actualmente recibes asesoría de parte de algún agente inmobiliario?",
        labelAgent: "Tiene un agente inmobiliario",
        labelAgentMsg: "Agente inmobiliario",
        labelYes: "Sí",
        labelNo: "No",
        placeholder: "Selecciona una opción",
        error: "Debes seleccionar una opción",
        help: "Solo es curiosidad",
      },
      send: "Enviar",
      infoReceivedTitle: "Estupendo",
      infoReceived: "Uno de los agentes inmobiliarios estará contigo muy pronto para comenzar a afinar detalles.",
    },
    msgs: {
      realStateTitle: "MyConextion Real Estate",
      msgRealtorTitleSale: "Propiedad para la Venta",
      msgRealtorTitlePurchase: "Compra de Propiedad",
      msgRealtor1Sale: "Un visitante de My Conextion está interesado en vender una propiedad.",
      msgRealtor1Purchase: "Un visitante de My Conextion está interesado en comprar una propiedad.",
      msgRealtor2: "Estos son sus datos:",
      msgRealtorProperty: "Información de la Propiedad",
      msgClientTitle: "MyConextion Real Estate",
      msgClient: "Gracias por dejar tu información, muy pronto uno de nuestros asesores estará en contacto contigo.",
    }
  },
  financing: {
    financingTitle: "Financiamiento",
    financingSubtitle: "Conéctate con las oportunidades de préstamo e inversión que tenemos para ti.",
    loader:{
      title:'Cargando Información',
      info: "Estamos obteniendo la información de las entidades financieras..."
    },
  },
  gifts: {
    giftsTitle: "Obsequios",
    giftsSubtitle: "Conoce la lista de obsequios que tenemos para ti",
    loader:{
      title:'Enviando Mensaje',
      info: "Estamos enviando tu mensaje..."
    },
    msgTitleClient: 'Reclama tu obsequio',
    msgClient: 'Estás son las instrucciones para reclamar tu obsequio de MyConextion',
    msgCodeClient: 'Puedes redimir tu obsequio con el código',
    msgPromoClient: 'Este es tu obsequio',
    msgContactClient: 'Estos son los datos de contacto de nuestro aliado:',
    msgTitleAlly: 'Solicitud de Cupón My Conextion',
    msgAlly: 'Un cliente de My Conextion ha solicitado uno de tus cupones',
    msgCodeAlly: 'El código del obsequio es',
    msgPromoAlly: 'Este es su obsequio',
    msgContactAlly: 'Estos son los datos de contacto del cliente:',
    msgTitleOwner: 'Solicitud de Cupón',
    msgOwner: 'Un cliente ha solicitado un cupón del aliado ',
    msgCodeOwner: 'El cliente recibió el código',
    msgPromoOwner: 'Este es su obsequio',
    msgContactOwnerClient: 'Estos son los datos de contacto del cliente:',
    msgContactOwnerAlly: 'Estos son los datos de contacto del aliado:',
    contact: {
      company: 'Aliado',
      website: 'Página Web',
      email: 'Correo Electrónico',
      phone: 'Teléfono',
      contact: 'Contacto',
      name: 'Nombre(s)',
      lastname: 'Apellido(s)',
    },
    form: {
      email: {
        label: "Correo Electrónico",
        placeholder: "correo@midominio.com",
        error: "Debes ingresar un correo electrónico",
        help: "Coloca un correo electrónico para poder redimir tu beneficio",
      },
      cellphone: {
        label: "Celular",
        placeholder: "1234567890",
        error: "Debes ingresar un número de celular válido",
        help: "Coloca un número de celular para poder contactarte en caso de ser necesario",
      },
      names: {
        label: "Nombre(s)",
        placeholder: "Juan",
        error: "Debes ingresar tu nombre",
        help: "Agrega tu nombre para que puedas reclamar tu premio",
      },
      lastNames: {
        label: "Apellido(s)",
        placeholder: "López",
        error: "Debes ingresar tu apellido",
        help: "Agrega tu apellido para que puedas reclamar tu premio",
      },
      terms: "Debes aceptar para poder continuar",
      note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      getGift: "Reclamar Obsequio",
      youMustSelectPromo: "Parece que olvidaste seleccionar tu obsequio",
      checkYourEmailTitle: "Estupendo",
      checkYourEmail: "Revisa tu correo electrónico para ver los detalles de tu obsequio.",
    },
    moreCoins: "¿Quieres obtener más obsequios?",
    moreCoinsBtn: "Ir al Mini Juego"
  },
  about: {
    us: {
      title: 'Acerca de nosotros',
      content: 'Somos la primera plataforma de seguros Interactiva en 3D con una inmersión 360 para el cliente moderno; creando una experiencia de usuario, mientras aseguramos sus pertenencias.',
      images: '/images/characters/characters.png'
    },
    williams: {
      surname: 'Williams',
      meet: 'Conociendo<br/>a los Williams',
      members: 'La familia Williams está compuesta por Kevin (el padre), Sarah (la madre), Gabriel, Sofia y Chris (los hijos de mayor a menor), y Max (el perro).',
      story1: 'Frank y Abigail se conocieron durante la guerra de Vietnam, en la que Abigail era una enfermera quien atendía las heridas en la cadera de Frank por unas esquirlas de una explosión en la cabina de su helicóptero.',
      story2: 'Allí se enamoraron, y luego volvieron a Baltimore donde se casaron en el 75, están juntos desde entonces y adoran disfrutar tiempo con su familia y contar sus aventuras juntos.',
      about: 'La familia Williams es muy unida, y trabajadora. Les gusta hacer actividades al aire libre y disfrutar de noches de juego y tardes de peliculas los fines de semana.',
    },
    garcias: {
      surname: 'García',
      meet: 'Conociendo<br/>a los García',
      members: 'La familia García está compuesta por Sebastián (el esposo), Andrea (la esposa), y Bella (la gata).',
      story: 'Sebastian y Andrea se conocieron en una clase de Spinning en el gimnasio al que asiste Peter. Andrea es la maestra de la clase, y en cuanto cruzaron miradas fue amor a primera vista.',
      about: 'Los García son una pareja de recién casados muy agradable, que le gusta entablecer buenas amistades con todo mundo y que siempre se les ve sonriendo.',
    },
  },
  howItWork: {
    title: 'Cómo funciona',
    info: [
      "Estamos recopilando data, haciendo un “survey” y queremos llegar al menos a los 5,000 o más que deseen OBTENER en nuestra lista para cuando lancemos la póliza sean las primeras personas en ahorrar en su seguro de auto.",
      'Cuando tengamos la cantidad de usuarios suficiente, nos acercaremos a las Compañías de Seguro para negociar los términos y condiciones de la póliza.  Este proceso podrá tomar varios meses, pero estará en camino y lograremos los objetivos establecidos…',
      'Cuando obtengamos la autorización y comercialización de las pólizas, actualizaremos nuestra plataforma con los requerimientos solicitados, e inmediatamente comunicaremos a todos nuestros usuarios registrados, para que sean los primeros en beneficiarse con las soluciones obtenidas en cada seguro y pago mensual.'
    ],
    slogan: '¿Te interesaría UNIRTE y obtener ahorros en tu pago mensual de tu seguro?',
    callToAction: 'click aquí',
    btnTitle: 'Has Click AQUÍ',
    image: '/images/characters/williams-kevin-hi.png'
  },
  faqs: {
    title: 'Preguntas Frecuentes',
    image: '/images/faqs/faqs.png',
    info: [
      {
        title: 'Seguro de Vida',
        subtitle: '¿Para que es un Seguro de Vida?',
        info: 'Un Plan para lo inesperado',
      },
      {
        title: 'Pólizas de Accidentes',
        subtitle: '¿Es necesario una póliza de Accidentes?',
        info: 'El seguro de accidentes lo ayuda a superar una lesión repentina al ofrecer un pago monetario con usos flexibles. Es una póliza de seguro para una lesión corporal inesperada.',
      },
      {
        title: 'Seguros Comerciales',
        subtitle: '¿Qué es un Seguro Comercial?',
        info: 'El seguro comercial brinda protección a las empresas contra pérdidas financieras causadas por desastres, delitos, accidentes y responsabilidad. La cobertura puede incluir la pérdida de ingresos debido a la interrupción del negocio de un cliente, las lesiones de los empleados o los actos ilícitos cometidos por el asegurado o por terceros en las instalaciones. Esto se puede escribir en una póliza general o como un contrato único con cobertura adicional agregada según sea necesario',
      },
      {
        title: 'Ciberseguridad',
        subtitle: '¿Cómo me ayuda este seguro?',
        info: 'El delito cibernético está creciendo más rápido que cualquier otro tipo de ataque cibernético. El seguro cibernético puede ayudarlo a protegerse contra las consecuencias devastadoras de los ataques cibernéticos, así como a solucionarlos si ocurren. Es un producto de seguro que ayuda a su empresa a protegerse de los posibles efectos desastrosos del delito cibernético. Están diseñados para ayudar a una empresa a mitigar riesgos específicos, de modo que pueda concentrarse en impulsar su negocio.',
      },
      {
        title: 'Seguro de autos',
        subtitle: 'Mi seguro de auto',
        info: 'El seguro de automóvil es un tipo de seguro que paga las pérdidas causadas por su automóvil, que incluyen: un accidente, incendio del automóvil y daños a la propiedad. Las pólizas de seguro de automóvil también pueden cubrir robo o vandalismo. La mayoría de los estados requieren cobertura de seguro de automóvil para protegerlo completamente de las consecuencias financieras de un accidente automovilístico.',
      },
      {
        title: 'Motoras',
        subtitle: 'Protege tu moto, a ti y a los tuyos con un seguro de moto ',
        info: 'Un motociclista corre un riesgo mucho mayor de lesionarse debido a la velocidad, la altura y la naturaleza fuerte del vehículo de dos ruedas. Los conductores y pasajeros de una motocicleta necesitan una protección adecuada contra daños en caso de accidente. El seguro de motocicleta brinda protección financiera en caso de accidente, pérdida, robo o daño de la motocicleta. El seguro de motocicleta incluye cobertura de responsabilidad civil en caso de que seas responsable de las lesiones o daños a la propiedad de otra persona. Esto es necesario para ayudar a proteger a los pasajeros de pérdidas financieras debido a accidentes o gastos legales si alguien los está demandando.',
      },
      {
        title: 'Arrendamiento',
        subtitle: 'Mayor seguridad para arrendatarios',
        info: 'El seguro para inquilinos es una parte importante de vivir en un espacio alquilado. Puede ayudar a protegerte a ti y a tus pertenencias en caso de incendio, robo y más. El seguro para inquilinos cubrirá los daños a sus pertenencias y pagará los costos de reparación si alguien resulta herido en su propiedad.',
      },
      {
        title: 'Mascotas ',
        subtitle: '¡Mi Mascota!',
        info: 'El seguro para mascotas puede ayudar a reducir la carga financiera asociada con las costosas facturas veterinarias causadas por enfermedades o lesiones sufridas por su mascota. Esta cobertura es similar a las pólizas de seguro de salud para humanos y funciona de manera similar, proporcionando fondos para cubrir las facturas médicas hasta los límites de la póliza. También le brinda la tranquilidad de saber que si su mascota necesita un procedimiento médico de emergencia, estará preparado para cubrir estos costos. ',
      }
    ]
  },
  contact: {
    title: 'Contáctanos',
    info: 'Escribenos y atenderemos tu solicitud en el menor tiempo posible',
    image: 'images/contact/contact.png',
    subject: "Gracias por escribirnos",
    contactSoon: "Tu mensaje es muy importante para nosotros. Pronto estaremos en contacto contigo.",
    subjectOwner: "Nos han escrito",
    contactSoonOwner: "Un usuario nos ha enviado un mensaje, ponte en contacto lo más pronto.",
    contactSoonDataOwner: "Estos son sus datos:",
    contactSoonMsgOwner: "Este es su mensaje:",
    contactThankYouTitle: "Mensaje Recibido",
    contactThankYouMsg: "Muchas gracias por tu mensaje, muy pronto estaremos en contacto",
    loader:{
      title:'Enviando Mensaje',
      info: "Estamos enviando tu mensaje..."
    },
    form: {
      email: {
        label: "Correo Electrónico",
        placeholder: "correo@midominio.com",
        error: "Debes ingresar un correo electrónico",
        help: "Coloca un correo electrónico para enviar tu mensaje",
      },
      cellphone: {
        label: "Celular",
        placeholder: "1234567890",
        error: "Debes ingresar un número de celular válido",
        help: "Coloca un número de celular para poder contactarte en caso de ser necesario",
      },
      names: {
        label: "Nombre(s)",
        placeholder: "Juan Camilo",
        error: "Debes ingresar tu nombre",
        help: "Agrega tu nombre para que puedas reclamar tu premio",
      },
      lastNames: {
        label: "Apellido(s)",
        placeholder: "Pérez López",
        error: "Debes ingresar tu apellido",
        help: "Agrega tu apellido para que puedas reclamar tu premio",
      },
      msg: {
        label: "Tu Mensaje",
        placeholder: "Mensaje",
        error: "Debes agregar tu mensaje",
        help: "Escríbenos tu consulta y pronto estaremos en contacto contigo",
      },
      note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      send: "Enviar",
    }
  },
  gpdr: {
    title: 'Utilizamos cookies',
    msg: 'Utilizamos cookies y otras tecnologías de seguimiento para mejorar tu experiencia de navegación en nuestro sitio web, mostrarte contenido personalizado y anuncios dirigidos, analizar el tráfico de nuestro sitio web y comprender de dónde provienen nuestros visitantes.',
    accept: 'Aceptar',
    decline: 'Rechazar',
  }
}
export default labelsES;