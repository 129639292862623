import "./styles/styles.css";
// Images
import IconBack from "./images/icon-back-white.png";
import { useNavigate } from "react-router-dom";

export const BackTo = () => {
  const navigate = useNavigate();
  return (
    <div className="container-back-home" onClick={() => navigate(-1)}>
      <img className="img-back" src={IconBack} alt="icon gift" />
    </div>
  );
};
