// Redux

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/MainStore";
import { promosUI, setPromosInfo } from "../../../../store/slices/promos";
// data
import dataPromoEN from "../../../../data/dataPromoEN";
import dataPromoES from "../../../../data/dataPromoES";
// style
import "./styles.css";
import { useEffect, useState } from "react";
// export
export const PromoCards = () => {
  // vars
  const currentLang = useSelector(
    (state: RootState) => state.language.currentLanguage
  );

  const [selectPromo, setSelectPromo] = useState("not selected");

  const dispatch = useDispatch();
  const promoCode = useSelector(
    (state: RootState) => state.promo.promoInfo.code
  );

  // translation
  const translate = () => {
    switch (currentLang) {
      case "es":
        return dataPromoES;
      case "en":
        return dataPromoEN;
      default:
        return dataPromoES;
    }
  };

  useEffect(() => {
    if (!promoCode) {
      setSelectPromo("not selected");
    }
  }, [promoCode]);

  // promo selection
  const handlerSelectPromo = ({ ...promo }: promosUI) => {
    const { promoInfo } = promo;

    console.log("promoInfo", promoInfo);

    setSelectPromo(promoInfo.code);
    dispatch(setPromosInfo(promoInfo));
  };
  // return
  return (
    <>
      <div className="row promo-row">
        {translate().map((promo, index) => (
          <div
            className="col-col-6 col-xxl-6 col-xl-6 col-lg-6 col-ipro-6 col-r-6 col-s-6 col-sm-6 col-sx-12 col-sxs-12"
            key={index}
            onClick={() => handlerSelectPromo({ promoInfo: promo })}
          >
            <div
              className={
                selectPromo === promo.code
                  ? "promo-card promo-selected"
                  : "promo-card"
              }
              data-company={promo.company}
            >
              <div className="card-promo-img">
                <img
                  className="promo-img"
                  src={promo.img}
                  alt={promo.company}
                />
              </div>
              <div className="card-promo-info">
                <h4 className="card-title">{promo.company}</h4>
                <p className="card-text">
                  <span dangerouslySetInnerHTML={{ __html: promo.text }}></span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
